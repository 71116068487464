<template>
  <StallionDialog
    v-model="dialogDisplay"
    :width="600"
    :disabled="dialogDisabled"
    :fullscreen="$vuetify.breakpoint.mobile"
    title="Add Credits"
  >
    <template #content>
      <div class="pa-5">
        <v-layout column>
          <v-flex xs12>
            <p>
              Your credit card information is securely stored by our payment
              provider. Stallion Express does not have access to this
              information.
            </p>
          </v-flex>

          <v-flex
            xs12
            mt-4
            mb-4
            text-center
          >
            <h3 v-if="card">
              <strong>
                {{ card.card_brand }} **** {{ card.card_last_four }}</strong>
            </h3>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              v-model="amount"
              v-validate="'required|max_value:10000|min_value:10'"
              mt-4
              prepend-inner-icon="fa-dollar-sign"
              type="number"
              :error-messages="errs.collect('amount')"
              label="Amount"
              data-vv-name="amount"
              required
            />
          </v-flex>
        </v-layout>
      </div>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="processing"
        :disabled="processing"
        @click="addCredits"
      >
        Pay {{ amount | currency }}
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  data() {
    return {
      dialogDisplay: false,
      dialogDisabled: this.disabled,
      amount: null,
      card: null,
      processing: false
    };
  },

  mounted() {
    Event.listen('add-credits', (payment_data) => {
      this.dialogDisabled = payment_data.disabled
      this.amount = payment_data.amount
      this.card = payment_data.card
      this.openDialog()
    });
  },
  methods: {
    openDialog() {
      this.dialogDisplay = true;
    },
    addCredits() {
      this.$validator.validate().then((result) => {
        if (result) {
          var self = this;
          window
            .swal({
              title: 'Are you sure?',
              text:
                'Your card that ends in ' +
                self.card.card_last_four +
                ' will be charged for $' +
                self.amount +
                '.\n Do you wish to continue?',
              icon: 'warning',
              buttons: ['Cancel', 'Purchase']
            })
            .then(function (isConfirm) {
              if (isConfirm) {
                self.processing = true;
                self.$http
                  .post('/credit-card/process-payment', {
                    amount: self.amount
                  })
                  .then((res) => {
                    self.processing = false;
                    if (res.data.success) {
                      Event.fire('payment-completed');
                      Event.fire('get-credits');
                      self.dialogDisplay = false;
                      window.swal(
                        'Success!',
                        '$' + self.amount + ' has been added to your account.',
                        'success'
                      );
                    } else {
                      window.swal({
                        title: 'Error!',
                        text: res.data.error,
                        icon: 'error'
                      });
                    }

                    self.amount = null;
                  })
                  .catch((err) => {
                    // console.log(err);
                    if (err.response.status == 422) {
                      window.swal({
                        title: 'Error',
                        text: err.response.data.error,
                        icon: 'error'
                      });
                    } else {
                      window.swal({
                        title: 'Error!',
                        text: 'The transaction could not be processed',
                        icon: 'error'
                      });
                    }
                  })
                  .finally(() => {
                    self.processing = false;
                  });
              }
              window.swal.close();
            });
        }
      });
    }
  }
};
</script>
