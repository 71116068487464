import _find from 'lodash/find';
import CONSTANTS from '@/constants/index'

function initialState() {
  return {
    notifications: [],
    announcements: {
      unread: [],
      announcements: []
    },
    unread: 0,
    initials: 'SE',
    selectedShipments: [],
    selectedBatches: [],
    selectedCloseouts: [],

    fba_request_dialog: false,
    ups_request_dialog: false,
    cart: [],
    loading_dialog: false,
    loading_message: '',
    default_address: null,
    package_types: [],
    postage_types: [],
    third_party_postage_types: [],
    return_postage_types: [],
    tax_identifiers: [],
    sub_users: [],
    preferences: {},
    countries: [
      {
        name: 'Canada(CA)',
        code: 'CA'
      },
      {
        name: 'United States(US)',
        code: 'US'
      }
    ],
    authLogo: 'images/StallionExpress-Black-Logo.png',
    quickerShipTab: CONSTANTS.QUICKER_SHIP_TABS.edit,
  };
}
const main = {
  namespaced: true,
  state: initialState(),
  actions: {
    setQuickerShipTab({ commit }, tab = CONSTANTS.QUICKER_SHIP_TABS.edit) {
      commit('setQuickerShipTab', tab)
    },
    openDialog({ commit }, message) {
      console.log(message);

      commit('updateMessage', message);
      commit('setDialog', true);
    },
    closeDialog({ commit }) {
      console.log('message');
      commit('setDialog', false);
      // commit('close');
    },
    setNotifications(context, payload) {
      var formattedNotification = [];
      (payload.notifications || []).forEach((notification) => {
        var data = notification.data; // already parsed as json_decode from backend
        formattedNotification.push({
          id: notification.id,
          title: data.title,
          message: data.message,
          created_at: notification.created_at,
          read_at: notification.read_at
        });
      });
      // initial/main notifications and will be displayed in the dropdown notification header
      context.commit('setNotifications', formattedNotification);
      context.commit('setUnread', payload.unread);
    },
    getDefaultAddress({ commit }) {
      axios
        .request('/default-address', {
          method: 'get'
        })
        .then((response) => {
          commit('updateDefaultAddress', response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPostageTypes({ commit }) {
      var params = {
        available: true
      };

      axios
        .request('/postage-types', {
          method: 'get',
          params: params
        })
        .then((response) => {
          commit('updatePostageTypes', response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getThirdPartyPostageTypes({ commit }) {
      var params = {
        available: true,
        needs_postage: false
      };

      axios
        .request('/postage-types', {
          method: 'get',
          params: params
        })
        .then((response) => {
          commit('updateThirdPartyPostageTypes', response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getReturnPostageTypes({ commit }) {
      var params = {
        available: true,
        needs_postage: true,
        return_label: true
      };

      axios
        .request('/postage-types', {
          method: 'get',
          params: params
        })
        .then((response) => {
          commit('updateReturnPostageTypes', response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getPackageTypes({ commit }) {
      axios
        .get('/package-types')
        .then((response) => {
          commit('updatePackageTypes', response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTaxIdentifiers({ commit }) {
      axios
        .get('/tax-identifiers')
        .then((response) => {
          commit('updateTaxIdentifiers', response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSubUsers({ commit }) {
      axios
        .get('/sub-user-resource')
        .then((response) => {
          commit('updateSubUsers', response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAnnouncements({ commit }) {
      return axios
        .get('/announcements')
        .then((response) => {
          commit('updateAnnouncements', response.data);
          return true;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
    },
    getNotifications({ commit }) {
      axios
        .get('/notifications')
        .then((response) => {
          const object = {
            notifications: response.latestSummary || [],
            unread: response.unread
          };
          commit('setNotifications', object);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setPreferences(context, payload) {
      axios
        .post('/user/preferences', { preferences: payload })
        .then((res) => {
          const data = _find(res.data, { key: 'preferences' });
          context.commit('updatePreferences', data?.value ? data.value : payload);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mutations: {
    setQuickerShipTab(state, tab) {
      state.quickerShipTab = tab
    },
    setUser(state, user) {
      state.user = user;
    },
    updateMessage(state, message) {
      state.loading_message = message;
    },
    setDialog(state, bool) {
      state.loading_dialog = bool;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setSelectedShipments(state, shipments) {
      state.selectedShipments = shipments;
    },
    setSelectedBatches(state, batches) {
      state.selectedBatches = batches;
    },

    addNotification(state, notification) {
      state.notifications.unshift(notification);
    },

    setInitials(state, value) {
      state.initials = value;
    },
    setUnread(state, value) {
      state.unread = value;
    },
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    updateDefaultAddress(state, default_address) {
      state.default_address = default_address;
    },
    updatePostageTypes(state, postage_types) {
      state.postage_types = postage_types;
    },
    updateThirdPartyPostageTypes(state, postage_types) {
      state.third_party_postage_types = postage_types;
    },
    updateReturnPostageTypes(state, postage_types) {
      state.return_postage_types = postage_types;
    },
    updatePackageTypes(state, package_types) {
      state.package_types = package_types;
    },
    updateTaxIdentifiers(state, tax_identifiers) {
      state.tax_identifiers = tax_identifiers;
    },
    updateSubUsers(state, sub_users) {
      state.sub_users = sub_users;
    },
    updateAnnouncements(state, announcements) {
      state.announcements = announcements;
    },
    updatePreferences(state, preference) {
      state.preferences = preference;
    }
  },
  getters: {
    quickerShipTab: (state) => state.quickerShipTab,
    notifications: (state) => state.notifications,
    announcements: (state) => state.announcements,
    initials: (state) => state.initials,
    unread: (state) => state.unread,
    package_types: (state) => {
      return state.package_types;
    },
    tax_identifiers: (state) => {
      return state.tax_identifiers;
    },
    default_address: (state) => {
      return state.default_address;
    },
    postage_types: (state) => (needs_postage = false, return_label = false) => {
      if(return_label){
        return state.return_postage_types;
      }else  if (needs_postage) {
        return state.postage_types;
      }
      
      else{
        return state.third_party_postage_types;
      }
    },
    third_party_postage_types: (state) => {
      return state.third_party_postage_types;
    },
    return_postage_types: (state) => {
      return state.return_postage_types;
    },
    table_rows: (state) => {
      return state.preferences.table_rows ?? [];
    },
    sub_users: (state) => {
      return state.sub_users.map((user) => {
        return {
          ...user,
          id: user.id.toString()
        };
      });
    }
  }
};

export default main;
