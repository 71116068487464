<template>
  <v-card flat>
    <v-container class="px-0 py-0">
      <v-row>
        <v-col cols="12">
          <h3>Default Columns</h3>
          <p class="mb-2">
            Manage the default columns for the packing slip. These columns will be used as the default columns for the default template.
          </p>


          
          <DefaultPackingSlipColumns />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="6">
          <h3>Custom Templates</h3>
          <p class="mb-0">
            Create and manage your own custom packing slip templates.
          </p>
          <!-- <p class="mb-0">
            Add/Edit Packing Slip Template |
            <a
              download
              class="import-list"
              href="/templates/packing_slip/StallionDefaultPackingSlip.html"
            >Stallion Default Template</a>
          </p> -->
        </v-col>
        
        <v-col md="6">
          <v-row class="float-right">
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  color="primary"
                  depressed
                  outlined
                  :disabled="loading"
                  v-on="on"
                  @click="$refs.templateDialog.startNew()"
                >
                  <v-icon small>
                    mdi-plus
                  </v-icon>Add Template
                </v-btn>
              </template>
              <span>Add Template</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-form @submit.prevent>
            <v-container>
              <v-row
                class="pb-2"
                style="justify-content: space-between"
              >
                <v-text-field
                  v-model="search"
                  class="mr-2"
                  label="Search"
                  prepend-inner-icon="search"
                  outlined
                  clearable
                  dense
                  hide-details
                  data-lpignore="true"
                  @input="submitSearch()"
                />
              </v-row>
            </v-container>
          </v-form>
        </v-col>

        <v-col md="12">
          <v-card outlined>
            <v-data-table
              item-key="id"
              class="table-striped"
              :headers="headers"
              :server-items-length="totalItems"
              :items="templates"
              :loading="loading"
              :options.sync="options"
              :footer-props="{
                'items-per-page-options': [25, 50, 100, 200]
              }"
            >
              <template #item.is_default="{ item }">
                <v-icon v-if="item.is_default">
                  mdi-check-circle-outline
                </v-icon>
              </template>

              <template #item.actions="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :disabled="loading"
                      small
                      icon
                      text
                      v-bind="attrs"
                      @click="previewTemplate(item)"
                      v-on="on"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Preview</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :disabled="loading"
                      small
                      icon
                      text
                      v-bind="attrs"
                      @click="$refs.templateDialog.duplicate(item)"
                      v-on="on"
                    >
                      <v-icon>mdi-content-duplicate</v-icon>
                    </v-btn>
                  </template>
                  <span>Duplicate</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :disabled="loading"
                      small
                      text
                      v-bind="attrs"
                      icon
                      @click="deleteTemplate(item)"
                      v-on="on"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :disabled="loading"
                      small
                      text
                      v-bind="attrs"
                      icon
                      @click="$refs.templateDialog.startEdit(item)"
                      v-on="on"
                    >
                      <v-icon> mdi-square-edit-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <template
          v-for="(list, index) in newSettings.packing_slip_settings"
        >
          <v-col
            v-if="list.hidden !== true"
            :key="index"
            cols="12"
          >
            <user-settings-option
              :show-description="false"
              :list="list"
              :dense="false"
              chips
            />
          </v-col>
        </template>

        <v-col cols="12">
          <v-btn
            color="primary"
            :loading="processing"
            @click="submit"
          >
            Update
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <packing-slip-template-form-dialog
      ref="templateDialog"
      :loading-preview="loading"
      @updateTable="updateTable"
      @previewTemplate="previewTemplate"
    />
  </v-card>
</template>

<script>
import DefaultPackingSlipColumns from './DefaultPackingSlipColumns.vue';
import PackingSlipTemplateFormDialog from './PackingSlipTemplateFormDialog.vue';
import {handleLaravelValidationError} from "@/helpers/helper";
export default {
  components: {
    PackingSlipTemplateFormDialog,
    DefaultPackingSlipColumns
  },
  data() {
    return {
      search: null,
      headers: [
        {
          text: 'Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Size',
          align: 'left',
          value: 'size'
        },
        {
          text: 'Default',
          align: 'left',
          value: 'is_default'
        },
        {
          text: '',
          sortable: false,
          align: 'right',
          value: 'actions'
        }
      ],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      totalItems: 0,
      loading: false,
      templates: [],
      newSettings: {
        packing_slip_settings: _.cloneDeep(
            this.$auth.user.user_config_settings.packing_slip_settings
        )
      },
      processing: false
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    this.updateTable();
  },
  methods: {
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.loading = true;
      this.getDataFromApi()
        .then((data) => {
          this.templates = data.items;
          this.totalItems = data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDataFromApi() {
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        console.log(this.options);
        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/packing-slip-templates`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            resolve({
              items,
              total,
              pages
            });
          });
      });
    },
    async deleteTemplate(template) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this template?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.loading = true;
        try {
          await this.$store.dispatch('packingSlipTemplates/deleteTemplate', template.id);
          swal({
            title: 'Success',
            text: 'Template successfully deleted',
            icon: 'success'
          });
          this.updateTable();
        } catch (err) {
          swal({
            title: 'Error!',
            text: 'An error occurred',
            icon: 'error'
          });
        }

        this.loading = false;
      }
    },
    previewTemplate(template) {
      this.loading = true;
      this.$http
        .post('/packing-slip-templates/preview', template, { responseType: 'blob' })
        .then((response) => response.data)
        .then((response) => {
          const file = new Blob([response], { type: 'application/pdf' });
          const url = URL.createObjectURL(file);
          window.open(url, '_blank');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.processing = true;
          this.$http
              .post('/users/updateUserConfigSettings', this.newSettings)
              .then((res) => {
                // console.log(res);
                if (Object.prototype.hasOwnProperty.call(res.data, 'message')) {
                  if (res.data.success) {
                    this.successMessage('Your account changes have been saved');
                    this.$auth.user.user_config_settings.packing_slip_settings = _.cloneDeep(this.newSettings.packing_slip_settings)
                  } else {
                    this.errorMessage('Your account changes have not been saved');
                  }
                }
              })
              .catch((err) => {
                handleLaravelValidationError(err);
              })
              .finally(() => {
                this.processing = false;
              });
        }
      });
    }
  }
};
</script>
