<template>
  <v-row dense>
    <v-col cols="12">
      <h4>Return Settings</h4>
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-text-field
        v-model="user.importer_number"
        v-validate="'integer|min:9|max:9'"
        v-mask="'#########'"
        outlined
        dense
        :error-messages="errs.collect('importer_number')"
        label="Importer Number"
        data-vv-name="importer_number"
        suffix="RM0001"
      />
    </v-col>
    <v-col
      cols="12"
      md="3"
    >
      <v-text-field
        v-model="user.wlr_imp_code"
        dense
        outlined
        :error-messages="errs.collect('wlr_imp_code')"
        label="WLR Code"
        data-vv-name="wlr_imp_code"
      />
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-checkbox
        v-model="user.return_agree"
        outlined
        dense
        label="Has POA"
      />
    </v-col>

    <!-- <v-col cols="12" md="3">
      <v-checkbox outlined dense v-model ="user.bill_returns_monthly" label="Returns Billed Monthly"></v-checkbox>
    </v-col> -->
    <v-col
      cols="12"
      md="3"
    >
      <v-checkbox
        v-model="auto_request"
        outlined
        dense
        label="Returns Auto Request"
      />
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-checkbox
        v-model="user.return_autocomplete"
        outlined
        dense
        label="Returns Autocomplete"
      />
    </v-col>

    <v-col
      cols="12"
      md="3"
    >
      <v-checkbox
        v-model="user.auto_dispose_returns"
        outlined
        dense
        label="Returns Auto-Dispose"
      />
    </v-col>

    <v-col cols="12">
      <h4>Return Description</h4>
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-model="user.returns_default_contents"
        placeholder="Ex: Phone Cases"
        label="Contents"
        data-vv-name="contents"
        dense
        outlined
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-model="user.returns_default_value"
        v-validate="'decimal|min_value:1'"
        placeholder="Ex: 10.00"
        label="Value"
        :error-messages="errs.collect('value')"
        data-vv-name="value"
        dense
        outlined
      />
    </v-col>

    <v-col cols="12">
      <v-btn
        :disabled="loading"
        :loading="loading"
        color="primary"
        @click="updateReturnSettings"
      >
        Update
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      auto_request: 0
    };
  },
  mounted(){
    const setting = this.user.settings.find(
      (s) => s.key == 'return_auto_request'
    );
    this.auto_request = setting ? setting.value : 0;
  },
  methods: {
    async updateReturnSettings() {
      const valid = await this.$validator.validate();
      if (valid) {
        this.loading = true;
        const params = {
          return_contents: this.user.returns_default_contents,
          return_value: this.user.returns_default_value,
          importer_number: this.user.importer_number,
          wlr_imp_code: this.user.wlr_imp_code,
          return_agree: this.user.return_agree,
          auto_request: this.auto_request,
          return_autocomplete: this.user.return_autocomplete,
          auto_dispose_returns: this.user.auto_dispose_returns
        }
        this.$http
          .post(`/admin/users/${this.user.id}/update-return-settings`, params)
          .then((response) => response.data)
          .then((response) => {
            this.$emit('user-updated', response);
            this.$notify({
              group: 'main',
              title: 'Settings Updated',
              text: "The user's return settings have been updated successfully",
              type: 'success',
              duration: 3000
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
