<template>
  <div>
    <!--Empty Parent Component-->
    <!-- Will be extended by child components. ex: QuickShipFormDialog, edit-shipment-details.vue -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CONSTANTS from '@/constants';
import ADDRESS_CONSTANTS from '@/constants/address';
import _cloneDeep from 'lodash/cloneDeep';

export default {
  components: {
    //
  },
  props: {
    shipment: {
      default: null,
      type: Object
    },
    taxIdentifiers: {
      default: () => [],
      type: Array
    },
    updateShipmentHandler: {
      default: () => {},
      type: Function
    }
  },
  data() {
    return {
      // start batch processing return label
      show_batch_create_labels: false,
      isReturnLabel: false,
      isBatchProcess: false,
      shipments: [],
      selectedShipmentIndex: null,
      // end batch processing return label
      quickshipHotkeyEvent: null,
      showPresetsDialog: false,
      presets: [],
      presetId: null,
      countries: CONSTANTS.COUNTRIES,
      provinces: CONSTANTS.PROVINCES,
      states: CONSTANTS.STATES,
      searchInput2: null,
      searchInput: null,
      postageRates: [],
      shipmentLocal: null,
      postageArray: [],
      packageArray: [],
      rateLoading: false,
      purchaseLoading: false,
      updateLoading: false,
      showQuickShipCompleteDialog: false,
      tab: 2,
      showPrint: false
    };
  },

  computed: {
    ...mapState(['userStores']),
    dimensionsRequired: function () {
      return (
        parseInt(this.shipmentLocal.package_type_id) == 20 ||
        (parseInt(this.shipmentLocal.package_type_id) == 19 &&
          this.isShipmentOver1lb())
      );
    },
    postageTypes() {
      return this.$store.getters['main/postage_types'](this.shipmentLocal.needs_postage)
    },
    packageTypes() {
      return this.$store.getters['main/package_types']
    },
  },
  watch: {
    '$store.state.qz.currentScaleWeight': function() {
      if(this.$store.state.qz.isQuickShip && this.$store.state.qz.currentScaleWeight.status && this.$store.state.qz.currentScaleWeight.data.weight > 0){
        let weightData = this.$store.state.qz.currentScaleWeight;
        if (weightData?.status && weightData?.data?.weight > 0) {
          if(this.shipmentLocal !== null){
            this.shipmentLocal.weight = weightData?.data?.weight;
            this.shipmentLocal.weight_unit = weightData?.data?.weight_unit;
          }
        }
      }
    },
    tab: function (val) {
      // tab 0 = address
      if (val == 0 && this.showQuickShipForm) {
        // NOTE: nextTIck will not work - will need to use setTimeout to wait for the element to be rendered
        setTimeout(() => {
          this.initGoogleMap('from')
          this.initGoogleMap('to')
        }, 100)
      }
    },
  },
  created() {},

  methods: {
    getAddressToSave(type) {
      const columns = type == 'from' ? ADDRESS_CONSTANTS.ADDRESS_BOOK_FROM : ADDRESS_CONSTANTS.ADDRESS_BOOK_TO

      let address = {}
      Object.keys(columns).forEach((key) => {
        address[columns[key]] = this.shipmentLocal[key]
      })

      return address
    },
    selectAddressBookFrom(address) {
      this.setAddressBook(ADDRESS_CONSTANTS.ADDRESS_BOOK_FROM, address)
    },
    selectAddressBookTo(address) {
      this.setAddressBook(ADDRESS_CONSTANTS.ADDRESS_BOOK_TO, address)
    },
    setAddressBook(columns, address) {
      Object.keys(columns).forEach((key) => {
        this.shipmentLocal[key] = address[columns[key]]
      })

      this.$forceUpdate();
    },
    initGoogleMap(addressType) {
      if (typeof google === 'undefined') {
        return
      }

      let inputAddress1 = ''
      let inputRefAddress2 = ''
      let addressObj = {}
      switch (addressType) {
        case 'from':
          inputAddress1 = 'QuickShipFromReturnAddress1'
          inputRefAddress2 = 'refReturnAddress2'
          addressObj = {
            address1: 'return_address1',
            address2: 'return_address2',
            city: 'return_city',
            province_code: 'return_province_code',
            postal_code: 'return_postal_code',
            country_code: 'return_country_code'
          }
          break;
        case 'to':
          inputAddress1 = 'QuickShipToAddress1'
          inputRefAddress2 = 'refAddress2'
          addressObj = {
            address1: 'address1',
            address2: 'address2',
            city: 'city',
            province_code: 'province_code',
            postal_code: 'postal_code',
            country_code: 'country_code'
          }
          break;
        default:
      }

      if (!inputAddress1) {
        return
      }

      try {
        var element = document.getElementById(inputAddress1);

        const options = {
          // bounds: defaultBounds,
          // componentRestrictions: { country: 'us' },
          fields: ['address_components'],
          strictBounds: false,
          types: ['address']
        };

        var autocomplete = new google.maps.places.Autocomplete(
          element,
          options
        );

        autocomplete.addListener('place_changed', () => {
          // Get the place details from the autocomplete object.
          const place = autocomplete.getPlace();
          const placeAddressObj = {}
          for (const key in addressObj) {
            // placeAddressObj.address1 = ''
            placeAddressObj[key] = ''
          }

          // Get each component of the address from the place details,
          // and then fill-in the corresponding field on the form.
          // place.address_components are google.maps.GeocoderAddressComponent objects
          // which are documented at http://goo.gle/3l5i5Mr
          // console.log(place.address_components);
          if (!place.address_components || !Array.isArray(place.address_components)){
            return;
          }

          for (const component of place.address_components) {
            // @ts-ignore remove once typings fixed
            const componentType = component.types[0];

            switch (componentType) {
              case 'street_number': {
                placeAddressObj.address1 = `${component.long_name} ${placeAddressObj.address1 || ''}`;
                break;
              }

              case 'subpremise': {
                placeAddressObj.address2 = component.short_name;
                break;
              }

              case 'route': {
                placeAddressObj.address1 += component.short_name;
                break;
              }

              case 'postal_code': {
                placeAddressObj.postal_code = `${component.long_name}${placeAddressObj.postal_code || ''}`;
                break;
              }

              case 'postal_code_suffix': {
                placeAddressObj.postal_code = `${placeAddressObj.postal_code || ''}-${component.long_name}`;
                break;
              }
              case 'locality':
                placeAddressObj.city = component.long_name;
                break;
              case 'administrative_area_level_1': {
                placeAddressObj.province_code = component.short_name;
                break;
              }
              case 'country':
                placeAddressObj.country_code = component.short_name;
                break;
            }
          }

          for (const key in addressObj) {
            // this.ship.address1 = ''
            // this.ship.return_address1 = ''
            this.shipmentLocal[addressObj[key]] = placeAddressObj[key]
          }

          // After filling the form with address components from the Autocomplete
          // prediction, set cursor focus on the second address line to encourage
          // entry of subpremise information such as apartment, unit, or floor number.
          // address2Field.focus();

          this.$refs[inputRefAddress2].focus();
        });
      } catch (ex) {
        console.log(ex);
      }
    },
    activateQuickShipForm() {
      this.showQuickShipForm = true
      this.initializeShipmentLocal()
    },

    initializeShipmentLocal() {
      this.presetId = null
      this.getPresets();

      this.$nextTick(() => {
        if (Boolean(this.shipmentLocal?.return_label) !== true) {
          this.tab = 2; // set Shipping Tab
        } else {
          this.tab = 0; // set Address Tab
        }
      })

      this.shipmentLocal = Object.assign(
        {},
        this.shipmentLocal,
        this.shipment
      );
      this.postageRates = [];
      this.postageArray = this.postageTypes.map(
        (p) => {
          return { id: parseInt(p.id), description: p.description };
        }
      );
      this.packageArray = this.packageTypes.map(
        (p) => {
          return {
            id: parseInt(p.id),
            description: p.description,
            classification: p.classification,
            package_provider: p.package_provider
          };
        }
      );

      this.$validator.reset();
    },

    getPresets() {
      this.$http
        .get(`/import/presets`)
        .then((response) => response.data)
        .then((response) => {
          this.initializeHotkeys(response.presets);
        });
    },

    async applyPresetOnDropdown() {
      const preset = this.presets.find(
        (preset) => preset.id == this.presetId
      );

      if (!preset) {
        return;
      }

      this.updateLoading = true
      await this.linkOrderShipment();
      this.$nextTick(function() {
        if (this.$refs.presetDialog) {
          this.$refs.presetDialog.applyPresetOnDropdown([this.shipmentLocal], preset, () => {
            this.updateLoading = false
          });
        }
      })
    },

    initializeHotkeys(presets) {
      if (!this.showQuickShipForm) {
        return;
      }

      this.presets = presets;

      // remove existing hotkey event listener
      if (this.quickshipHotkeyEvent) {
        window.removeEventListener('keydown', this.quickshipHotkeyEvent);
      }

      // initialize the hotkey event listener
      this.quickshipHotkeyEvent = (e) => {
        const isPressingHotkey = e.ctrlKey || e.metaKey;

        // loop presets and declare hotkey event
        presets.forEach(async (preset) => {
          const isWithHotkey = preset.hotkey !== null && preset.hotkey !== '';
          const hotkey = isNaN(preset.hotkey)
            ? preset.hotkey.toLowerCase()
            : preset.hotkey;

          if (isPressingHotkey && isWithHotkey && e.key == hotkey) {
            e.preventDefault();
            if(this.$refs.presetDialog) {
              await this.linkOrderShipment();
              this.$refs.presetDialog.applyPresetOnHotkey(preset);
            }
          }
        });

        if (this.showQuickShipForm && e.key == 'p' && isPressingHotkey) {
          e.preventDefault();
          this.submitForm();
        } else if (this.showQuickShipForm && e.key == 'r' && isPressingHotkey) {
          e.preventDefault();
          this.getPostageRates();
        } else if (this.showQuickShipForm && e.key == 's' && isPressingHotkey) {
          e.preventDefault();
          this.save();
        }

        // reserve hotkeys
        if (e.key == 'Escape') {
          e.preventDefault();
          this.close();
        } else if (e.keyCode == '37' && isPressingHotkey) {
          e.preventDefault();
          this.tab--;
        } else if (e.keyCode == '39' && isPressingHotkey) {
          e.preventDefault();
          this.tab++;
        } else if (e.keyCode == '77' && isPressingHotkey) {
          e.preventDefault();
          if(this.isScaleInitialised()){
            this.setWeight();
          } else {
            Vue.notify({
              group: 'main',
              title: 'Error getting scale weight',
              text: 'Enable Scale in settings first',
              duration: 3000,
              type: 'error'
            });
          }
        }
      };

      // attach the hotkey event listener
      window.addEventListener('keydown', this.quickshipHotkeyEvent);
    },

    async openPresetsDialog() {
      await this.linkOrderShipment();
      this.showPresetsDialog = true;
    },

    async onApplyPresets(payload) {
      const presetName = payload.preset
        ? `(${payload.preset.preset_name})`
        : '';
      this.$notify({
        group: 'main',
        title: 'Shipment Saved',
        text: `Selected preset ${presetName} has been applied to shipment ${this.shipmentLocal.ship_code}.`,
        duration: 5000,
        type: 'success'
      });
      await this.getShipment(this.shipmentLocal.id);
      this.updateShipmentHandler(this.shipmentLocal);
    },

    isShipmentOver1lb() {
      const unit = this.shipmentLocal.weight_unit;
      switch (unit) {
        case 'lbs':
          return this.shipmentLocal.weight >= 1;
        case 'kg':
          return this.shipmentLocal.weight >= 0.45;
        case 'g':
          return this.shipmentLocal.weight >= 453;
        case 'oz':
          return this.shipmentLocal.weight >= 16;
        default:
          return false;
      }
    },

    reset() {
      this.$validator.reset();
      this.postageRates = [];
    },

    close() {
      this.reset();
      this.$store.dispatch('qz/putIsQuickShipState', false);
      Event.fire('close-auto-weights');
      this.showQuickShipForm = false

      // start batch processing return label
      this.isReturnLabel = false;
      this.isBatchProcess = false;
      this.shipments = [];
      this.selectedShipmentIndex = null;
      // end batch processing return label
    },

    async validateForm() {
      return await this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },

    async getPostageRates() {
      if (!(await this.validateForm())) {
        this.$notify({
          group: 'main',
          title: 'Missing Fields',
          text: this.errs.all().join('. '),
          duration: 5000,
          type: 'error'
        });
        return;
      }

      this.rateLoading = true;
      this.postageRates = [];

      this.$http
        .post('/shipments/rates', this.shipmentLocal)
        .then((res) => {
          if (res.data.errors) {
            this.errorMessage(res.data.errors);
          } else {
            this.postageRates = res.data.rates;
          }
        }).finally(() => {
          this.rateLoading = false;
        });
    },

    // start batch processing return label
    resetShipmentsIndex() {
      this.shipments.forEach((shipment, index) => {
        // reset index for tracking
        this.$set(shipment, '_index', index);
      });
    },
    async purchaseForBatchProcessing() {
      try {
        if (!(await this.validateForm())) {
          this.$notify({
            group: 'main',
            title: 'Missing Fields',
            text: this.errs.all().join('. '),
            duration: 5000,
            type: 'error'
          });
          return;
        }

        this.purchaseLoading = true;
        // create shipments
        const toSave = this.shipments.filter((shipment) => !shipment.id);
        if (toSave.length > 0) {
          const response = await this.$http
            .post(`/shipments/bulk-create`, {
              shipments: toSave
            })

          // remove unsaved shipments
          this.shipments = this.shipments.filter((shipment) => shipment.id)
          // add newly saved shipments
          response.data.forEach((shipment) => {
            this.shipments.push(shipment)
          });

          this.resetShipmentsIndex();
        }

        this.$refs.refCreateLabels.openFromShipments(this.shipments);

        this.purchaseLoading = false;
        this.close();
      } catch (e) {
        console.log(e)
        this.errorMessage();
      }
    },
    async saveForBatchProcessing() {
      try {
        await this.save(false); // updates the shipmentLocal

        const index = this.shipments.findIndex((shipment) => shipment._index === this.selectedShipmentIndex);
        this.$set(this.shipments, index, _cloneDeep(this.shipmentLocal));
        this.resetShipmentsIndex();
        this.$forceUpdate();
      } catch (e) {
        console.log(e)
        this.errorMessage();
      }
    },
    // end batch processing return label

    async submitForm() {
      if (!(await this.validateForm())) {
        this.$notify({
          group: 'main',
          title: 'Missing Fields',
          text: this.errs.all().join('. '),
          duration: 5000,
          type: 'error'
        });
        return;
      }

      if (!this.shipmentLocal.postage_type_id) {
        this.errorMessage('Please select a postage type.');
        return;
      }

      if(this.purchaseLoading === true){
        return;
      }

      this.purchaseLoading = true;

      this.buildService()
        .then((res) => {
          this.purchaseLabel(res.data);
        })
        .catch(() => {
          this.purchaseLoading = false

          this.errorMessage();
        })
        .finally(() => {
          // IMPORTANT: do not set purchaseLoading = false here
          // set it to true @purchaseLabel() after purchasing is complete
        });
    },

    async save(closeDialog = true) {
      if (!(await this.validateForm())) {
        this.$notify({
          group: 'main',
          title: 'Missing Fields',
          text: this.errs.all().join('. '),
          duration: 5000,
          type: 'error'
        });
        return;
      }

      if(this.updateLoading === true){
        return;
      }

      this.updateLoading = true;

      await this.buildService()
        .then(async (res) => {
          this.shipmentLocal = res.data;
          this.updateShipmentHandler(this.shipmentLocal);

          if (closeDialog) {
            this.close();
          }

          this.$notify({
            group: 'main',
            title: 'Shipment Saved',
            text: 'Changes to the current shipment have been saved successfully.',
            duration: 5000,
            type: 'success'
          });
        })
        .catch(() => {
        })
        .finally(() => {
          this.updateLoading = false;
        });
    },

    /**
     * Link an order to shipment
     */
    async linkOrderShipment() {
      // exit if already linked to shipment
      if (this.shipmentLocal.id !== null) return;

      this.updateLoading = true;
      await this.buildService()
        .then((res) => {
          this.shipmentLocal = res.data;
          this.updateShipmentHandler(this.shipmentLocal)
        })
        .finally(() => {
          this.updateLoading = false
        });
    },

    /**
     * Build the HTTP service that can create or update the shipment
     */
    async buildService() {
      let service = null;
      // build service
      if (this.shipmentLocal.id === null) {
        // create new shipment
        service = await this.$http.post('/shipments', this.shipmentLocal);
      } else {
        // update shipment
        service = await this.$http.put(
          `/shipments/${this.shipmentLocal.id}`,
          this.shipmentLocal
        );
      }

      return service;
    },

    purchaseLabel(shipment) {
      this.$http
        .put('/shipments/' + shipment.id + '/complete', shipment)
        .then(async (res) => {
          await this.getShipment(shipment.id);
          this.updateShipmentHandler(this.shipmentLocal)

          if (!res.data.success) {
            this.$notify({
              group: 'main',
              title: 'Error',
              text: res.data.errors,
              duration: 5000,
              type: 'error'
            });
          } else {
            Event.fire('get-credits');
            this.close();
          }
        })
        .catch(async () => {
          await this.getShipment(shipment.id);
          this.updateShipmentHandler(this.shipmentLocal)
        })
        .finally(async () => {
          this.purchaseLoading = false
        });
    },

    async getShipment(id) {
      await this.$http.get('/shipments/' + id).then((res) => {
        this.shipmentLocal = res.data;
      });
    },

    async setWeight(){
      let weightData = {};
      try {
        if (this.$store.state.qz.currentScaleStream) {
          weightData = this.$store.state.qz.currentScaleWeight;
        } else {
          weightData = await this.getWeightFromScale();
        }
        if(weightData.status){
          this.shipmentLocal.weight = weightData.data.weight;
          this.shipmentLocal.weight_unit = weightData.data.weight_unit;
        }
      } catch (err) {
        if(err.message === "HID device could not be found"){
          this.errorMessage('Usb device not connected');
        } else {
          console.log(err);
        }
      }
    },
    clearPostageRates() {
      this.postageRates = [];
    },
  }
};
</script>
