<template>
  <div>
    <div>
      <!-- <v-breadcrumbs
        large
        :items="[
        {
          text: 'Home',
          exact: true,
          to: '/dashboard',
        },
        {
          text: 'Track',
          exact: true,
          to: '/track',
        }
      ]"
      ></v-breadcrumbs> -->
    </div>
    <v-container class="pa-5">
      <v-container class="px-0 py-0 mt-4">
        <h2 class="font-weight-bold">
          Track a Shipment
        </h2>
      </v-container>
      <v-flex
        xs12
        md6
        mt-4
      >
        <v-form @submit.prevent>
          <v-container
            row
            class="pa-0"
          >
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                v-model="this_code"
                label="Tracking Number"
                prepend-inner-icon="search"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <!-- <v-btn large text color="secondary" @click="track">Reset</v-btn> -->
              <v-btn
                color="primary"
                :disabled="loading"
                :loading="loading"
                type="submit"
                class="mt-2"
                @click="track"
              >
                Track
              </v-btn>
            </v-col>
          </v-container>
        </v-form>
      </v-flex>

      <transition
        name="component-fade"
        mode="out-in"
      >
        <v-flex
          v-if="!loading"
          md12
          class="my-7"
        >
          <div v-if="error">
            <v-alert
              :value="true"
              dismissible
              type="error"
              transition="fade-transition"
            >
              Tracking number not found
            </v-alert>
          </div>
          <div v-else-if="events.length > 0">
            <v-card
              outlined
              class="pa-5"
            >
              <v-container class="pa-0">
                <h3>Shipment Tracking Results</h3>
                <v-layout class="pt-3">
                  <p>Shipment Status:</p>
                  <p
                    class="font-weight-bold ml-2"
                    v-html="$options.filters.status(status)"
                  />
                </v-layout>
                <v-layout>
                  <p>Carrier:</p>
                  <p class="font-weight-bold ml-2">
                    {{ events[0].carrier }}
                  </p>
                </v-layout>
              </v-container>
              <v-list three-line>
                <template v-for="(item, index) in events">
                  <v-list-item
                    :key="item.id"
                    class="px-0"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-html="item.status" />
                      <v-list-item-subtitle v-html="item.location" />
                      <v-list-item-subtitle>
                        {{ item.datetime | datetime_pretty }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider
                    v-if="index + 1 < events.length"
                    :key="index"
                    class="ma-0"
                  />
                </template>
              </v-list>
            </v-card>
          </div>
        </v-flex>
      </transition>
    </v-container>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Track Shipment | Stallion Express"
  },
  data() {
    return {
      events: [],
      this_code: this.$route.params.code,
      error: false,
      loading: false,
      status: null
    };
  },
  mounted() {
    console.log(this.code);
    if (typeof this.$route.params.code !== 'undefined') {
      this.track();
    }
  },
  methods: {
    track() {
      this.loading = true;
      this.events = [];

      this.error = false;
      this.$http
        .get(`shipments/${this.this_code}/track`)
        .then(response => {
          if (response.data.success) {
            this.events = response.data.events;
            this.status = response.data.status;
          } else {
            this.error = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px;
}
</style>
