<template>
  <StallionDialog
    v-model="show"
    :width="800"
    :disabled="disabled"
    :title="'Split Order'"
    fullscreen
  >
    <template #content>
      <v-layout column>
        <v-flex xs12>
          <v-container fluid>
            <v-card outlined>
              <v-card-title>
                Split Order #{{ order.display_order_id }}
              </v-card-title>
              <v-card-subtitle>
                Not enough inventory? Any items out of stock?
                You can use this feature to split this order into multiple shipments to fulfill them separately.
              </v-card-subtitle>
              <v-card-text>
                <v-form
                  :ref="form"
                  @submit.prevent="split"
                >
                  <v-data-table
                    :headers="headers"
                    :items="shipments"
                    item-value="index"
                    class="elevation-0"
                  >
                    <template #top>
                      <v-toolbar flat>
                        <v-toolbar-title>Order Items</v-toolbar-title>
                        <v-divider
                          class="mx-4"
                          inset
                          vertical
                        />
                        <v-spacer />
                        <v-btn
                          color="primary"
                          @click="addShipment"
                        >
                          <v-icon small>
                            mdi-plus
                          </v-icon>Add Shipment
                        </v-btn>
                      </v-toolbar>
                    </template>

                    <template #item="{ item, index }">
                      <tr>
                        <td>{{ index + 1 }}</td>
                        <td
                          v-for="(orderItem) in item.items"
                          :key="orderItem.id"
                          class="pt-3"
                        >
                          <v-select
                       
                            v-model="orderItem.newQuantity"
                            outlined
                            dense
                            :items="getAvailableQuantities(orderItem.id, orderItem.newQuantity)"
                            :label="orderItem.title"
                            :rules="[value => validateQuantity(orderItem, value)]"
                            @change="updateQuantities"
                          />
                        </td>
                        <td>
                          <v-btn
                            color="red"
                            text
                            @click="removeShipment(index)"
                          >
                            <v-icon small>
                              mdi-minus-circle-outline
                            </v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>

                  <br>
                  <v-divider />

                  <v-row>
                    <v-col
                      cols="12"
                      class="text-right"
                    >
                      <p class="text-subtitle-1 mt-5">
                        <strong>Order Total</strong>:  {{ order.value | currency }}
                      </p>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-container>
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="split_loading"
        :disabled="split_loading || splitOrder.original_order_hash != null"
        @click="split"
      >
        <v-icon
          small
          dark
          left
        >
          alt_route
        </v-icon>Split
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    disabled: {
      default: false,
      type: Boolean
    },
    value: {
      type: Boolean,
      default: false
    },
    showSplit: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      split_loading: false,
      splitOrder: {
        items: []
      },
      form: 'form',
      shipments: [
        {
          items: [
            {
              newQuantity: 0
            }
          ]
        }
      ],
      headers: [
        { text: 'Shipment', value: 'index' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    };
  },
  
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    value: {
      immediate: false,
      handler() {
        this.splitOrder = this.order;
        this.generateHeaders();
      }
    },
    'splitOrder.items': {
      handler(newItems) {
        this.generateHeaders();
        this.shipments.forEach(shipment => {
          shipment.items = newItems.map(item => ({
            ...item,
            newQuantity: 0
          }));
        });
      },
      deep: true
    }
  },

  created() {
    this.initializeShipments();
  },
  
  methods: {
    split() {
      if(this.splitOrder.merged_order == true) {
        this.errorMessage('This order is a merged order and cannot be split');
        return;
      }
      
      if(!this.$refs.form.validate()) {
        this.errorMessage('Invalid quantity for an item');
        return;
      }

      const originalTotalQuantity = this.splitOrder.items.reduce((sum, item) => sum + item.quantity, 0);
      const currentTotalQuantity = this.shipments.reduce((sum, shipment) => {
        return sum + shipment.items.reduce((sum, item) => sum + item.newQuantity, 0);
      }, 0);

      if (currentTotalQuantity !== originalTotalQuantity) {
        this.errorMessage('Total quantity does not match original order quantity');
        return;
      }

      this.shipments.forEach(shipment => {
        shipment.items = shipment.items.reduce((filteredItems, item) => {
          if (item.newQuantity > 0) {
            filteredItems.push(item);
          }
          return filteredItems;
        }, []);
      });

      this.split_loading = true;

      this.$http.post('/orders/split', {
        order_id: this.splitOrder.id,
        shipments: this.shipments
      })
      .then(response => {
        console.log(response.data);
        this.successMessage('Order split successfully');
      })
      .catch(() => {
        this.errorMessage('Could not split the order');
      })
      .finally(() => {
        this.split_loading = false;
        this.show = null;

        this.$emit('update');
      });
    },

    validateQuantity(item) {
      const totalQuantity = this.shipments.reduce((sum, shipment) => {
        const shipmentItem = shipment.items.find(i => i.id === item.id);
        return sum + (shipmentItem ? shipmentItem.newQuantity : 0);
      }, 0);
      return totalQuantity <= item.quantity || 'Exceeds total available quantity';
    },

    addShipment() {
      const newShipment = { 
        items: this.splitOrder.items.map(item => ({ ...item, newQuantity: 0 })) 
      };
      this.shipments.push(newShipment);
    },
    
    removeShipment(index) {
      this.shipments.splice(index, 1);
    },

    initializeShipments() {
      this.shipments = [
        {
          items: this.splitOrder.items.map(item => ({ ...item, newQuantity: 0 }))
        }
      ];
    },

    generateHeaders() {
      this.headers = [
        { text: 'Shipment', value: 'index' },
        ...this.splitOrder.items.map(item => ({
          text: item.title + ' (' + item.quantity + ')',
          value: item.id
        })),
        { text: 'Actions', value: 'actions', sortable: false }
      ];
    },

    getAvailableQuantities(itemId, selectedQuantity) {
      const item = this.splitOrder.items.find(item => item.id === itemId);
      const usedQuantities = this.shipments.reduce((sum, shipment) => {
        const shipmentItem = shipment.items.find(i => i.id === itemId);
        return sum + (shipmentItem ? shipmentItem.newQuantity : 0);
      }, 0);
      const maxQuantity = item.quantity - usedQuantities + selectedQuantity; // Include selected quantity in available options
      return Array.from({ length: maxQuantity + 1 }, (_, i) => i);
    },

    updateQuantities() {
      this.shipments = this.shipments.map(shipment => ({
        ...shipment,
        items: shipment.items.map(item => ({
          ...item,
          availableQuantities: this.getAvailableQuantities(item.id, item.newQuantity)
        }))
      }));
    }
  }
};
</script>

<style>
.v-data-table {
  margin-top: 20px;
}
</style>
