import axios from 'axios';

function initialState() {
  return {
    filters: [],
    loading: false
  };
}

export default {
  namespaced: true,
  state: initialState(),
  getters: {},
  actions: {
    getFilters({ commit, state }) {
      state.loading = true;
      axios
        .get('/filters')
        .then((response) => {
          commit('updateFilters', response.data);
        })
        .finally(() => {
          state.loading = false;
        });
    },
    async deleteFilter({ dispatch }, id) {
      await axios.request(`/filters/${id}`, {
        method: 'delete'
      });
      dispatch('getFilters');
    }
  },
  mutations: {
    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    updateFilters(state, filters) {
      state.filters = filters;
    }
  }
};
