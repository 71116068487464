<template>
  <v-container>
    <v-layout wrap>
      <v-flex xs12>
        <h1>
          <i
            aria-hidden="true"
            class="mdi mdi-amazon"
          /> Sort FBA
        </h1>
      </v-flex>

      <v-flex
        xs12
        md6
        offset-md3
      >
        <!-- loading dialog -->
        <v-dialog
          v-model="dialogLoading"
          persistent
          width="300"
        >
          <v-card dark>
            <v-card-text>
              Getting Users
              <v-progress-linear
                indeterminate
                color="white"
              />
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- List -->
        <v-card
          v-if="items.length > 0"
          outlined
        >
          <v-list
            three-line
            class="pa-0"
          >
            <template v-for="(item, index) in items">
              <v-list-item
                v-if="item.user"
                :key="'a_' + index"
                ripple
                :class="{
                  warning: item.user.check_fba,
                  dark: item.user.check_fba
                }"
                @click="select(item)"
              >
                <v-list-item-avatar>
                  <v-icon size="50">
                    account_circle
                  </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.shipment.ship_code }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.user.business_name || item.user.name }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ item.shipment.postage_type }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    icon
                    ripple
                    @click.stop="
                      selected = item;
                      dialogDetails = true;
                    "
                  >
                    <v-icon color="grey lighten-1">
                      info
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index != items.length - 1"
                :key="index"
                class="ma-0"
              />
            </template>
          </v-list>
        </v-card>
        <v-alert
          v-else-if="!dialogLoading && items.length == 0"
          class="mt-5"
          outlined
          type="success"
        >
          Nothing to sort
        </v-alert>
      </v-flex>
      <!-- sort dialog -->
      <v-dialog
        v-if="selected"
        v-model="dialogSort"
        max-width="400"
        persistent
      >
        <v-card v-if="selected.shipment">
          <v-card-title class="text-h3">
            Assign {{ selected.shipment.ship_code }}
          </v-card-title>
          <v-card-text>
            <v-form v-if="$auth.user.employee_location.sort_method == 'Manual'">
              <v-container>
                <v-layout wrap>
                  <v-flex
                    xs6
                    offset-xs3
                  >
                    <v-select
                      v-model="box"
                      :items="boxes"
                      label="Box"
                      outlined
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
            <v-form v-if="$auth.user.employee_location.sort_method == 'NPI'">
              <v-select
                v-model="container_id"
                v-validate="'required'"
                :items="containers"
                item-text="name"
                item-value="id"
                label="Container"
                outlined
                data-vv-name="container"
                :error-messages="errs.collect('container')"
              />
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn
              color="primary"
              text="text"
              @click="dialogSort = false"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="$auth.user.employee_location.sort_method == 'Manual'"
              color="primary"
              @click="setBox(selected)"
            >
              Assign
            </v-btn>
            <v-btn
              v-if="$auth.user.employee_location.sort_method == 'NPI'"
              color="primary"
              @click="manualTransfer(selected)"
            >
              Assign
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- fullscreen -->
      <v-dialog
        v-model="dialogDetails"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark>
            <v-btn
              icon
              @click="dialogDetails = false"
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>items</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="dialogSort = true"
              >
                Sort
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <!-- <v-layout row wrap> -->

          <v-flex
            xs12
            md6
            offset-md3
          >
            <admin-view-shipment-details :shipments="[selected.shipment]" />
          </v-flex>
          <!-- </v-layout> -->
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      selected: {
        shipment: null
      },
      location: 'Markham',

      dialogSort: false,
      dialogLoading: false,
      box: 'UPS 1',

      boxes: [],
      dialogDetails: false,
      containers: [],
      container_id: null,
    };
  },
  mounted() {
    this.getUsers();
    this.getContainers();
  },
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    this.$barcodeScanner.init(this.onBarcodeScanned);
    console.log('this.$barcodeScanner init');
  },
  destroyed() {
    // Remove listener when component is destroyed
    this.$barcodeScanner.destroy();
    console.log('this.$barcodeScanner destroy');
  },
  methods: {
    async onBarcodeScanned(barcode) {
      console.log('onBarcodeScanned', barcode);
      // select container_id by barcode
      this.container_id = this.containers.find((x) => x.barcode == barcode)
        ?.id;

    },

    getContainers() {
      const params = {
        types: ['Bag', 'Gaylord'],
        statuses: ['Open'],
        per_page: 1000,
        carriers: ['UPS', 'FedEx', 'USPS']
      };

      this.$http
        .request(`/admin/containers`, {
          method: 'get',
          params: params,
        })
        .then((response) => response.data)
        .then((response) => {
          this.containers = response.data.map((container) => {
            return {
              ...container,
              name: `${container.name} ${container.type} - ${container.day_code}`
            };
          });
        });
    },
    manualTransfer(item) {
      this.$validator.validate().then((result) => {
        if (result) {
          var ids = [item.shipment.id]

          this.$http
            .request(`/admin/containers/manual-transfer`, {
              method: 'post',
              data: {
                ids,
                container_id: this.container_id,
              },
            })
            .then((response) => response.data)
            .then(() => {
              this.$notify({
                group: 'main',
                title: 'Shipment Updated',
                text: `${item.user.business_name || item.user.name
                  } has been assigned to a container`,
                duration: 4000
              });

              var index = this.items.indexOf(item);
              if (index !== -1) this.items.splice(index, 1);
              this.selected = { shipments: [] };


            }).finally(() => {
              this.dialogSort = false;
            });
        }
      })
    },
    getUsers() {
      this.dialogLoading = true;
      this.$http
        .get(`/admin/sort/fba`)
        .then((response) => response.data)
        .then((response) => {
          this.items = response;
          this.setOptions();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dialogLoading = false;
        });
    },
    select(item) {
      this.dialogSort = true;
      this.selected = item;
    },
    setBox(selected) {
      this.$http
        .post(`/admin/sort/fba`, { id: selected.shipment.id, box: this.box })
        .then(() => {
          const msg = `${selected.shipment.ship_code} has been assigned to a box`;
          this.$notify({
            group: 'main',
            title: 'FBA Updated',
            text: msg,
            duration: 4000,
            type: 'info'
          });
          console.log(msg);
          var index = this.items.indexOf(selected);
          console.log(index);
          if (index !== -1) {
            console.log('index');
            this.items.splice(index, 1);
          }

          console.log('done');
        })
        .catch(() => {
          console.log('catch');
        })
        .finally(() => {
          console.log('finally');
          this.dialogSort = false;
          this.dialogDetails = false;
        });
    },
    setOptions() {
      this.boxes = [];
      var s = [];

      s = [
        {
          name: 'FedEx',
          count: 6
        },
        {
          name: 'UPS',
          count: 40
        },
        {
          name: 'USPS',
          count: 40
        }
      ];

      s.forEach((option) => {
        console.log(option);
        for (var i = 1; i <= option.count; i++) {
          this.boxes.push(`${option.name} ${i}`);
        }
      });
    }
  }
};
</script>
