<template>
  <v-col>
    <v-row>
      <v-col>
        <p class="mb-2">
          Create and manage your own email templates that can be used to send emails to your customers.
        </p>
      </v-col>
      
      <v-col cols="auto">
        <v-row class="float-right">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                class="mx-2"
                color="primary"
                depressed
                outlined
                :disabled="loading"
                v-on="on"
                @click="$refs.templateDialog.startNew()"
              >
                <v-icon small>
                  mdi-plus
                </v-icon>Add Template
              </v-btn>
            </template>
            <span>Add Template</span>
          </v-tooltip>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12">
        <v-card outlined>
          <v-data-table
            item-key="id"
            class="table-striped"
            :headers="headers"
            :server-items-length="totalItems"
            :items="templates"
            :loading="loading"
            :options.sync="options"
            :footer-props="{
              'items-per-page-options': [25, 50, 100, 200]
            }"
          >
            <template #item.is_active="{ item }">
              <v-icon v-if="item.is_active">
                mdi-check-circle-outline
              </v-icon>
              <v-icon v-else>
                mdi-close
              </v-icon>
            </template>

            <template #item.type="{ item }">
              {{ item.type }}
            </template>

            <template #item.created_at="{ item }">
              <div v-if="item.created_at">
                {{ item.created_at | datetime_pretty }}
                <br>
                ({{ item.created_at | datetime_from }})
              </div>
            </template>

            <template #item.updated_at="{ item }">
              <div v-if="item.updated_at">
                {{ item.updated_at | datetime_pretty }}
                <br>
                ({{ item.updated_at | datetime_from }})
              </div>
            </template>

            <template #item.actions="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="loading"
                    small
                    icon
                    text
                    v-bind="attrs"
                    @click="previewTemplate(item)"
                    v-on="on"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Preview</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="loading"
                    small
                    icon
                    text
                    v-bind="attrs"
                    @click="$refs.templateDialog.duplicate(item)"
                    v-on="on"
                  >
                    <v-icon>mdi-content-duplicate</v-icon>
                  </v-btn>
                </template>
                <span>Duplicate</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="loading"
                    small
                    text
                    v-bind="attrs"
                    icon
                    @click="deleteTemplate(item)"
                    v-on="on"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="loading"
                    small
                    text
                    v-bind="attrs"
                    icon
                    @click="$refs.templateDialog.startEdit(item)"
                    v-on="on"
                  >
                    <v-icon> mdi-square-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <email-template-form-dialog
      ref="templateDialog"
      :loading-preview="loading"
      :template-types="templateTypes"
      :field-replacements="fieldReplacements"
      @updateTable="updateTable"
      @previewTemplate="previewTemplate"
    />
  </v-col>
</template>

<script>
import EmailTemplateFormDialog from './TemplateFormDialog.vue';
export default {
  components: {
    EmailTemplateFormDialog
  },
  data() {
    return {
      search: null,
      headers: [
        {
          text: 'Subject',
          align: 'left',
          value: 'subject'
        },
        {
          text: 'Type',
          align: 'left',
          value: 'type'
        },
        {
          text: 'Active',
          align: 'left',
          value: 'is_active'
        },
        {
          text: '',
          sortable: false,
          align: 'right',
          value: 'actions'
        }
      ],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      templateTypes: [],
      fieldReplacements: [],
      totalItems: 0,
      loading: false,
      templates: [],
    };
  },
  watch: {
    tab() {
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  created() {
    this.getTemplateTypes();
    this.getFieldReplacements();
  },
  mounted() {
    this.updateTable();
  },
  methods: {
    getTemplateTypes() {
      this.$http
        .get('email-templates/types')
        .then((response) => response.data)
        .then((response) => {
          this.templateTypes = response;
        })
    },
    getFieldReplacements() {
      this.$http
        .get('email-templates/field-replacements')
        .then((response) => response.data)
        .then((response) => {
          this.fieldReplacements = response;
        })
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.loading = true;
      this.getDataFromApi()
        .then((data) => {
          this.templates = data.items;
          this.totalItems = data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDataFromApi() {
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        // console.log(this.options);
        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/email-templates`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            resolve({
              items,
              total,
              pages
            });
          });
      });
    },
    async deleteTemplate(template) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this template?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.loading = true;
        try {
          await this.$http.delete(`/email-templates/${template.id}`)
          this.getUser(); // refresh user data to update CustomerEmails.vue
          swal({
            title: 'Success',
            text: 'Template successfully deleted',
            icon: 'success'
          });
          this.updateTable();
        } catch (err) {
          swal({
            title: 'Error!',
            text: 'An error occurred',
            icon: 'error'
          });
        }

        this.loading = false;
      }
    },
    previewTemplate(template) {
      this.loading = true;
      this.$http
        .post('/email-templates/preview', template)
        .then((response) => response.data)
        .then((response) => {
          const wnd = window.open('about:blank', 'Email Preview');
          wnd.document.write(response);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
};
</script>
