<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-form
          v-if="!condensed"
          @submit.prevent
        >
          <v-row
            class="pb-2"
            style="justify-content: space-between"
          >
            <v-col cols="4">
              <v-text-field
                v-model="search"
                class="mr-2"
                label="Search batch number"
                prepend-inner-icon="search"
                outlined
                clearable
                dense
                hide-details
                data-lpignore="true"
                @input="submitSearch()"
              />
            </v-col>

            <v-col
              cols="4"
            >
              <date-picker
                v-model="start_date"
                label="Start Date"
                @change="submitSearch"
              />
            </v-col>

            <v-col
              cols="4"
            >
              <date-picker
                v-model="end_date"
                label="End Date"
                @change="submitSearch"
              />
            </v-col>
          </v-row>
        </v-form>

        <v-card
          class="elevation-0"
          style="border: 1px solid #e7e7e7"
        >
          <div v-if="condensed">
            <v-card-title>
              <span class="text-h3">Batches</span>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                @input="submitSearch()"
              />
            </v-card-title>
          </div>
          <v-data-table
            v-model="$store.state.main.selectedBatches"
            :headers="headers"
            :loading="loading"
            show-select
            item-key="id"
            :items="items"
            :options.sync="options"
            class="table-striped"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': [5, 50, 100, 200, 500]
            }"
            :items-per-page="25"
            @click:row="rowClick"
          >
            <template #item.shipments="{ item }">
              {{ item.shipments.length }}
            </template>
            <template #item.open="{ item }">
              <span v-if="item.open">Open</span>
              <span v-else>Closed</span>
            </template>

            <template #item.created_at="{ item }">
              {{ item.created_at | datetime_pretty }}
              <br>
              ({{ item.created_at | datetime_from }})
            </template>

            <template #item.id="{ item }">
              <router-link
                v-if="item.id"
                class="font-weight-bold"
                :to="{ name: 'batch', params: { id: item.id } }"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">{{ item.id }}</span>
                  </template>
                  <span>View Batch</span>
                </v-tooltip>
              </router-link>
            </template>

            <template #item.actions="{ item }">
              <v-btn
                small
                color="primary"
                text
                :disabled="!can('CLIENT.SHIPPING')"
                @click="showBatchEditDialog(item)"
              >
                Edit
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <batch-edit
      v-model="show_batch_edit"
      :batch="selectedBatch"
      @update-batch="updateTable()"
    />
  </div>
</template>

<script>
import BatchEdit from './batch-edit.vue';

export default {
  components: {
    BatchEdit
  },
  props: {
    closedOut: {
      default: null,
      type: Boolean
    },
    condensed: {
      default: false,
      type: Boolean
    },

    pending: {
      default: null,
      type: Boolean
    }
  },
  data() {
    return {
      search: null,
      start_date: null,
      end_date: null,

      headers: [
        {
          text: 'Batch',
          align: 'left',
          value: 'id'
        },

        {
          text: 'Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Status',
          value: 'open',
          sortable: true
        },
        {
          text: 'Shipments',
          value: 'shipments_count',
          sortable: true
        },
        {
          text: 'Created',
          value: 'created_at'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      loading: false,
      totalItems: 0,
      items: [],

      start_date_menu: null,
      end_date_menu: null,
      timer: null,
      cancelSource: null,

      selectedBatch: null,
      show_batch_edit: false
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            batches: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },
  mounted() {
    this.$store.state.main.selectedBatches = [];
    Event.listen('update-batches', () => {
      this.updateTable();
    });

    if (this.pending === true) {
      this.headers[2].text = 'Pending Shipments';
    }

    this.$root.$on('batch-table-show-select', (select) => {
      if (!select) this.$store.state.main.selectedBatches = this.items;
      else this.$store.state.main.selectedBatches = [];
    });

    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.batches ?? 5;
  },
  methods: {
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.$store.state.main.selectedBatches = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date,
          pending: this.pending
        };

        this.$http
          .request(`/batches`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },
    rowClick(item) {
      this.$store.state.main.selectedBatches = [item];
    },
    showBatchEditDialog(item) {
      this.show_batch_edit = true;
      this.selectedBatch = { ...item };
    }
  }
};
</script>
