 
<template #default>
  <v-row dense>
    <v-col cols="12">
      <v-card outlined>
        <v-card-text class="pb-2">
          <v-form>
            <v-container>
              <v-row dense>
                <v-col>
                  <v-select
                    v-model="type"
                    :items="types"
                    label="File Type"
                    outlined
                    dense
                  />
                </v-col>
         
                <v-col>
                  <v-file-input
                    v-model="file"
                    dense
                    outlined
                    label="File input"
                    accept="text/csv"
                    prepend-icon=""
                  />
                </v-col>
             
                <v-col cols="auto">
                  <v-btn
                    dense
                    color="primary"
                    :loading="processing"
                    :disabled="buttonIsDisable"
                    @click="onFormSubmit"
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>  
</template>
 
  
  <script>
  export default {
    name: 'PostageAdjustments',
  
    data() {
      return {
        postage_adjustment_request_id: null,
        charge_data: [],
        allow_save_charges: false,
        subtotal: 0,
        tax: 0,
        total: 0,
        loading: false,
        countDown: 10,
        autoRefresh: false,
        headers: [
          { text: 'Ship Code', value: 'ship_code' },
          { text: 'User Id', value: 'user_id' },
          { text: 'Description', value: 'description' },
          { text: 'Amount', value: 'amount' },
          { text: 'Tax', value: 'tax' },
          { text: 'Total', value: 'total' },
          { text: 'Already Charged', value: 'already_charged' }
        ],
        file: null,
        processing: false,
        type: null,
        test: true,
        totalItems: 0,
        options: {
          sortBy: ['created_at'],
          sortDesc: [true]
        },
        types: [
          {
            text: 'Stamps',
            value: 'Stamps'
          },
          {
            text: 'Freightcom',
            value: 'Freightcom'
          },
          {
            text: 'OSM',
            value: 'OSM'
          },
          {
            text: 'APC Duty',
            value: 'APC Duty'
          },
          {
            text: 'APC Duty Non EU',
            value: 'APC Duty Non EU'
          },
          {
            text: 'FedEx',
            value: 'FedEx'
          },
          {
            text: 'PostNL',
            value: 'PostNL'
          },
          {
            text: 'APC',
            value: 'APC'
          }
        ]
      };
    },
    computed: {
      buttonIsDisable() {
        return !this.file || this.processing;
      },
  
      canSaveCharge() {
        return !this.allow_save_charges;
      }
    },
    mounted() {
      console.log('postage adjustment here mounted');
    },
  
    methods: {
      onFormSubmit() {
        this.processing = true;
        this.charge_data = [];
        this.total = 0;
        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('type', this.type);
        formData.append('test', this.test);
  
        this.$http
          .post('admin/postage-adjustments', formData)
          .then((response) => response.data)
          .then((response) => {
              this.successNotify('Postage Adjustment Request Submitted');
              this.file = null;
   
            this.postage_adjustment_request_id = response.postage_adjustment_request_id;

            // emit event to parent component
            this.$emit('submitted', response);
          })
          .catch((err) => {
            this.resetForm();
            if (err.response.status == 422) {
              this.errorNotify(err.response.data.error);
            } else {
              this.errorNotify('There was an error submitting the request');
            }
          })
          .finally(() => {
            this.processing = false;
          });
      },
  
      saveCharges() {
  
        var params = {
            postage_adjustment_request_id: this.postage_adjustment_request_id,
          };
  
          this.$http
          .post('admin/postage-adjustments/charge-users', params)
          .then((response) => response.data)
          .then(() => {
            this.successNotify('Charges Saved');
          })
          .catch((err) => {
            if (err.response.status == 422) {
              this.errorNotify(err.response.data.error);
            } else {
              this.errorNotify('There was an error submitting the request');
            }
          })
          .finally(() => {
  
          });
      },
    }
  };
  </script>
  