<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <v-flex
          xs12
          class="d-flex flex-row justify-space-between mb-3"
        >
          <div>
            <!-- <h2 class="font-weight-bold">My Labels</h2> -->
          </div>

          <div v-if="auto_refresh === true">
            <v-icon left>
              mdi-loading mdi-spin
            </v-icon>

            Auto Refresh in {{ countDown }}
          </div>
        </v-flex>

        <v-card
          class="elevation-0"
          style="border: 1px solid #e7e7e7"
        >
          <div v-if="condensed">
            <v-card-title>
              <span class="text-h3">Batches</span>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                @input="submitSearch()"
              />
            </v-card-title>
          </div>
          <v-data-table
            v-model="$store.state.main.selectedBatches"
            :headers="headers"
            :loading="loading"
            item-key="id"
            :items="items"
            :options.sync="options"
            class="table-striped"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': [5, 50, 100, 200, 500]
            }"
            :items-per-page="5"
          >
            <template #item.shipments="{ item }">
              {{ item.shipments.length }}
            </template>

            <template #item.filename="{ item }">
              <div v-if="item.name">
                {{ item.name }}
              </div>
              <div v-else>
                {{ item.filename }}
              </div>
              <!--  -->
            </template>

            <template #item.complete="{ item }">
              <div v-if="item.complete == 0">
                <v-icon
                  small
                  left
                >
                  mdi-loading mdi-spin
                </v-icon>Processing
              </div>
              <div v-else-if="item.error == 1">
                <v-icon
                  small
                  left
                >
                  mdi-alert-circle-outline
                </v-icon>Error
              </div>
              <div v-else-if="item.complete == 1">
                <v-icon
                  small
                  left
                >
                  mdi-check
                </v-icon>Ready
              </div>
            </template>

            <template #item.expiry_date="{ item }">
              {{ item.expiry_date | date_pretty }}
              <br>
              ({{ item.expiry_date | datetime_from }})
            </template>

            <template #item.expiry_date="{ item }">
              {{ item.expiry_date | date_pretty }}
              <br>
              ({{ item.expiry_date | datetime_from }})
            </template>

            <template #item.created_at="{ item }">
              {{ item.created_at | date_pretty }}
              <br>
              ({{ item.created_at | datetime_from }})
            </template>

            <template #item.actions="{ item }">
              <v-btn
                v-if="item.complete == 1 && item.error == 0"
                color="primary"
                class="ma-2"
                small
                :loading="loading"
                @click.prevent="getLabel('download', item)"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
              <!-- 
              <v-btn
                color="primary"
                @click="print()"
                :loading="print_loading"
                :disabled="print_loading"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>-->
            </template>

            <template #item.id="{ item }">
              <router-link
                v-if="item.id"
                class="font-weight-bold"
                :to="{ name: 'batch', params: { id: item.id } }"
              >
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">{{ item.id }}</span>
                  </template>
                  <span>View Batch</span>
                </v-tooltip>
              </router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    closedOut: {
      default: null,
      type: Boolean
    },
    condensed: {
      default: false,
      type: Boolean
    },

    pending: {
      default: null,
      type: Boolean
    },

    printType: {
      default: 'label_only',
      type: String
    }
  },
  data() {
    return {
      auto_refresh: false,
      search: null,
      start_date: null,
      end_date: null,

      headers: [
        {
          text: 'Filename',

          value: 'filename'
        },
        {
          text: 'Type',

          value: 'type'
        },
        {
          text: 'Status',

          value: 'complete'
        },
        {
          text: '# Shipments',

          value: 'shipment_count'
        },
        {
          text: 'Order',

          value: 'ordering'
        },
        {
          text: 'Expiry Date',

          value: 'expiry_date'
        },
        {
          text: 'Created At',

          value: 'created_at'
        },
        {
          text: '',

          value: 'actions',
          sortable: false
        }
      ],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      loading: false,
      totalItems: 0,
      items: [],

      start_date_menu: null,
      end_date_menu: null,
      timer: null,
      cancelSource: null,
      countDown: 0
    };
  },
  computed: {
    getLabelUrl: function() {
      let url = '/shipments/getPrintJob';
      return url;
    },
    getDownloadsUrl: function() {
      let url = '/downloads';
      return url;
    }
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    this.$store.state.main.selectedBatches = [];
    Event.listen('update-batches', () => {
      this.updateTable();
    });

    if (this.pending === true) {
      this.headers[2].text = 'Pending Shipments';
    }

    this.$root.$on('batch-table-show-select', select => {
      if (!select) this.$store.state.main.selectedBatches = this.items;
      else this.$store.state.main.selectedBatches = [];
    });
  },
  methods: {
    async getLabel(action, item) {
      this.loading = true;
      if (action == 'print') {
        await this.printFileUrl(item.url);
      } else {
        await this.downloadFileUrl(item.url, item.filename);
      }
      this.loading = false;
    },
    countDownTimer() {
      if (
        (self.batch && self.batch.canceled) ||
        ['/print-jobs'].indexOf(
          this.$router.currentRoute.path
        ) == -1
      ) {
        return true;
      }

      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.updateTable();
      }
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;

      this.options.page = 1;
      this.timer = setTimeout(function() {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      // this.totalItems = 0;
      // this.items = [];

      this.getDataFromApi().then(data => {
        this.items = data.items;
        this.totalItems = data.total;

        for (var i = 0; i < this.items.length; i++) {
          this.auto_refresh = false;
          if (this.items[i].complete == 0) {
            this.auto_refresh = true;
            this.countDown = 10;
            this.countDownTimer();
            break;
          }
        }
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise(resolve => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date
        };

        this.$http
          .request(this.getDownloadsUrl, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then(response => response.data)
          .then(response => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },
    rowClick(item) {
      this.$router.push({
        name: 'batch',
        params: {
          id: item.id
        }
      });
    }
  }
};
</script>
