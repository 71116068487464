<template>
  <v-row>
    <v-col cols="12">
      <v-form>
        <v-container>
          <v-row>
            <v-text-field
              ref="barcodeInput"
              v-model="barcode"
              label="Scan UPC or enter an SKU to continue"
              class="mr-2"
              outlined
              clearable
              dense
              hide-details
              :disabled="!canEdit || isShipmentItemsVerified"
              :loading="loading"
              @keydown.enter.prevent="manualScan"
            />
  
            <v-btn
              color="primary"
              :disabled="!canEdit || isShipmentItemsVerified"
              :loading="loading"
              @click="manualScan"
            >
              Verify Item
            </v-btn>


            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  color="primary"
                  @click="$emit('close')"
                  v-on="on"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Close and find another shipment </span>
            </v-tooltip>
          </v-row>
        </v-container>
      </v-form>
    </v-col>

    <v-col cols="12">
      <v-simple-table dense>
        <template #default>
          <thead>
            <tr>
              <th class="text-left">
                Image
              </th>
              <th class="text-left">
                Description
              </th>
              <th class="text-left pb-3">
                <v-btn
                  small
                  
                  rounded
                  color="primary"
                  :disabled="!canEdit || !canVerifyAll"
                  :loading="loading"
                  @click="verifyAll()"
                >
                  Verify All
                </v-btn>
              </th>
              <th class="text-left pb-3">
                <v-btn
                  
                
                  color="red"
                  small
                  rounded
                  :disabled="!canEdit || !canCancelVerifyAll"
                  :loading="loading"
                  style="color: white"
                  @click="cancelVerifyAll()"
                >
                  Clear All
                </v-btn>
              </th>
              <th class="text-left pb-3">
                Ordered
              </th>
              <th class="text-left pb-3">
                Scanned
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in (shipment.items || [])"
              :key="index"
            >
              <td class="py-2">
                <v-img
                  :src="item.img_url || 'https://cannamazoo.com/assets/defaults/img/default-product-img.jpg'"
                  :lazy-src="'https://cannamazoo.com/assets/defaults/img/default-product-img.jpg'"
                  min-height="50"
                  min-width="50"
                  max-height="50"
                  max-width="50"
                  aspect-ratio="1"
                />
              </td>
              <td width="50%">
                <h4>{{ item.description }}</h4>
                <div>
                  <strong>SKU: </strong> {{ item.sku }}
                </div>
               
                <div>
                  <strong>UPC: </strong> <span v-if="item.upc">{{ item.upc }}</span> <span v-else>N/A</span>
                </div>
              </td>
              <td>
                <v-btn
                  class="mt-n1"
                  small
                  rounded
                  color="primary"
                  :disabled="!canEdit || !canVerifyItem(item)"
                  :loading="loading"
                  @click="verifyItem(index)"
                >
                  Verify
                </v-btn>
              </td>
              <td>
                <v-btn
                  class="mt-n1"
                  rounded
                  color="red"
                  style="color: white"
                  small
                  :disabled="!canEdit || !canCancelVerifyItem(item)"
                  :loading="loading"
                  @click="cancelVerifyItem(index)"
                >
                  Clear
                </v-btn>
              </td>
              <td>
                <div style="line-height: 50px; font-size: 24px;">
                  {{ item.quantity }}
                </div>
              </td>
              <td>
                <div
                  class="text-center"
                  style="background: #f9ac40; color: #fff; height: 50px; width: 50px; border-radius: 50%; line-height: 50px; font-size: 24px;"
                >
                  {{ item.quantity_verified }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';

export default {
  props: {
    shipment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      barcode: null,
      loading: false,
      originalItems: _cloneDeep(this.shipment?.items || []),
      lock: false
    };
  },
  computed: {
    isShipmentItemsVerified() {
      return Boolean(this.shipment.items_verified);
    },
    canEdit() {
      // 3 = INCOMPLETE
      return this.shipment && this.shipment.status_id == 3 && this.can('CLIENT.SHIPPING');
    },
    canVerifyAll() {
      return this.shipment && this.shipment.items && this.shipment.items.length > 0 && this.shipment.items.some(item => this.canVerifyItem(item));
    },
    canCancelVerifyAll() {
      return this.shipment && this.shipment.items && this.shipment.items.length > 0 && this.shipment.items.some(item => this.canCancelVerifyItem(item));
    },
  },
  watch: {
    'shipment.items': function (newVal, oldVal) {
      if (oldVal && newVal) {
        this.originalItems = _cloneDeep(newVal);
      }
    }
  },
  created() {
    document.activeElement.blur();
    // Add barcode scan listener and pass the callback function
    if(!this.$barcodeScanner.hasListener()){
      this.$barcodeScanner.init(this.onBarcodeScanned);
    }
  },
  destroyed() {
    // Remove listener when component is destroyed
    if(this.$barcodeScanner.hasListener()){
      this.$barcodeScanner.destroy();
    }
  },
  mounted() {
    if(!this.$barcodeScanner.hasListener()){
      this.$barcodeScanner.init(this.onBarcodeScanned);
    }
  },
  methods: {
    verifyAll() {
      this.shipment.items.forEach((item) => {
        item.quantity_verified = item.quantity
      });

      this.updateItems();
    },
    canVerifyItem(item) {
      return !this.isShipmentItemsVerified && (isNaN(item.quantity_verified) || item.quantity_verified < item.quantity)
    },
    verifyItem(index) {
      const item = this.shipment.items[index];
      if (item.quantity_verified < item.quantity) {
        item.quantity_verified += 1;
        this.updateItems();
      }

    },
    cancelVerifyAll() {
      this.shipment.items.forEach((item) => {
        item.quantity_verified = 0;
      });

      this.updateItems();
    },
    canCancelVerifyItem(item) {
      return !this.isShipmentItemsVerified && item.quantity_verified > 0
    },
    cancelVerifyItem(index) {
      const item = this.shipment.items[index];
      if (item.quantity_verified > 0) {
        item.quantity_verified -= 1;
        this.updateItems();
      }
    },
    manualScan() {
      this.onBarcodeScanned(this.barcode);
    },
    // Create callback function to receive barcode when the scanner is already done
    async onBarcodeScanned(barcode) {
      console.log('barcode', barcode);
      if (!barcode || this.lock) {
      return;
    }

    this.lock = true;


      let itemIndex = -1;
      try {
        itemIndex = this.scan(barcode);
        if (!this.canVerifyItem(this.shipment.items[itemIndex])) {
          return;
        }

        this.verifyItem(itemIndex);
      } catch (err) {
        this.onError(err);
      
    } finally {
      this.lock = false;
      this.barcode = null;
    }
    },
    async updateItems() {
      try {
        const response = await this.$http.post('/shipment-verification/' + this.shipment.id + '/items', {
          items: this.shipment.items
        })

        this.$set(this.shipment, 'items', response.data)
      } catch (err) {
        this.onError(err);

        // reset to original items
        this.$set(this.shipment, 'items', this.originalItems)
      }
    },
    scan(barcode) {
      barcode = barcode.toLowerCase();
      const settings = Object.values(this.$auth.user?.user_config_settings?.workflow_settings) || [];
      const scanKeys = settings.find(setting => setting.key === 'scan_to_verify_items_by')?.value || [];
      if (scanKeys.length == 0) {
        throw Error('Workflow settings not configured');
      }

      const itemIndex = (this.shipment?.items || []).findIndex((item) => {
        if (scanKeys.includes('SKU') && (item.sku || '').toLowerCase() == barcode) {
          this.playSuccessSound();
          return true;
        } else if (scanKeys.includes('UPC') && (item.upc || '').toLowerCase() == barcode) {
          this.playSuccessSound();
          return true;
        }
      });

      if (itemIndex != -1) {
        return itemIndex;
      }

      this.playErrorSound();

      throw Error('Item not found');
    },
    onError(err) {
      this.playErrorSound();
      this.$notify({
        group: 'main',
        text: err,
        duration: 3000,
        type: 'error'
      });
    },
  } 
};
</script>
