<template>
  <div>
    <v-row dense>
      <v-col />
      <v-col cols="auto">
        <!-- buildManageColumnHeaders is from mixin.js -->
        <stallion-table-settings
          :table="manageColumnTableKey"
          :columns="buildManageColumnHeaders(headers, manageColumnSettings, manageColumnTableKey)"
          :update-column-handler="updateColumns"
        />
      </v-col>
      <v-col cols="auto">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              outlined
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              Export
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :disabled="selected.length === 0"
              @click="exportSelected"
            >
              <v-list-item-title>
                Export Currently Selected ({{ selected.length }})
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="totalItems === 0"
              @click="exportOrders"
            >
              <v-list-item-title>
                Export Current View ({{ totalItems }})
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <!-- v-bind.sync="filters" -->
        <order-search
          ref="searchComponent"
          :search-order-filters.sync="filters"
          :loading.sync="loading"
          :selected="selected"
          @reset-selected="resetSelected()"
          @apply-search="submitSearch()"
        />
      </v-col>
      <v-col cols="12">
        <filter-buttons
          v-model="filter"
          :filters="statuses"
        />
      </v-col>
      <v-col class="d-flex flex-wrap">
        <v-checkbox
          v-model="filters.show_archived"
          class="mt-1 mr-3"
          dense
          label="Show Archived"
          hide-details
          :value="true"
          @change="submitSearch()"
        />
        <v-checkbox
          v-model="address_issue"
          :value="true"
          dense
          label="Address Issue"
          class="mt-1 mr-3"
          @change="submitSearch"
        />
      </v-col>
    

      <v-col cols="3">
        <v-autocomplete
          v-model="selected_tags"
          prepend-inner-icon="mdi-tag"
          clearable
          data-vv-name="order_tag"
          :items="tags.tags"
          label="Add Tags"
          multiple
          item-text="name"
          item-value="id"
          dense
          outlined
          :disabled="!can(PERMISSIONS.SHIPPING)"
        >
          <template #item="{ item, on, attrs }">
            <v-list-item
              :style="attrs.inputValue ? { backgroundColor: '#D3D3D3' } : ''"
              v-on="on"
            >
              <v-list-item-action>
                <v-simple-checkbox
                  :value="attrs.inputValue"
                  color="primary"
                  :ripple="false"
                  v-on="on"
                />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title
                  :style="{
                    backgroundColor: item.color_code,
                    padding: '10px',
                    borderRadius: '7px'
                  }"
                >
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-card
          class="elevation-0"
          style="border: 1px solid #e7e7e7"
        >
          <div v-if="condensed">
            <v-card-title>
              <span class="text-h3">Orders</span>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                @input="submitSearch()"
              />

              <!-- buildManageColumnHeaders is from mixin.js -->
              <stallion-table-settings
                :table="manageColumnTableKey"
                :columns="buildManageColumnHeaders(headers, manageColumnSettings, manageColumnTableKey)"
                :update-column-handler="updateColumns"
              />
            </v-card-title>
          </div>

          <v-data-table
            v-model="selected"
            class="table-striped"
            :elevation="0"
            :headers="filteredHeaders"
            :items="items"
            item-key="id"
            show-select
            :loading="loading"
            :server-items-length="totalItems"
            :options.sync="options"
            :footer-props="{
              'items-per-page-options': [50, 100, 200, 500]
            }"
            :items-per-page="25"
            @click:row="rowClick"
            @item-selected="bulkSelect"
            @current-items="current = $event"
          >
            <template #item.address="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    v-if="item.address_verification_status == 'Verified'"
                    small
                    color="success"
                    v-on="on"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    v-else-if="item.address_verification_status == 'Unverified'"
                    small
                    color="warning"
                    v-on="on"
                  >
                    mdi-alert
                  </v-icon>
                  <v-icon
                    v-else-if="item.address_verification_status == 'Verification Failed'"
                    small
                    color="error"
                    v-on="on"
                  >
                    mdi-alert-circle
                  </v-icon>
                  {{ item.name }}
                </template>
                <span v-if="item.address_verification_status == 'Verified'">
                  Verified
                </span>
                <span v-else-if="item.address_verification_status == 'Unverified'">
                  Unverified
                </span>
                <span v-else-if="item.address_verification_status == 'Verification Failed'">
                  Verification Failed
                </span>
              </v-tooltip>
              <br>
              <span v-if="item.company">
                {{ item.company }}
                <br>
              </span>
              {{ item.address1 }}
              <br>
              <span v-if="item.address2">
                {{ item.address2 }}
                <br>
              </span>
              {{
                item.city + ' ' + item.province_code + ', ' + item.postal_code
              }}
              <br>
              {{ item.country_code }}
            </template>
            <template #item.order_id="{ item }">
              <!-- <div v-html="$options.filters.orderLink(item)" /> -->
              <a
                href="javascript:void(0)"
                @click.stop="openQuickerTab(item, quickerShipTabs.view)"
              >
                <span v-if="item.display_order_id">
                  {{ item.display_order_id }}
                </span>
                <span v-else>
                  {{ item.order_id }}
                </span>
              </a>
            </template>

            <template #item.shipment="{ item }">
              <div v-if="item.shipment">
                <!-- <div>{{ item.shipment.ship_code }}</div> -->
                <a
                  href="javascript:void(0)"
                  @click.stop="openQuickerTab(item, quickerShipTabs.edit)"
                >
                  {{ item.shipment.ship_code }}
                </a>
                <div>
                  (<span v-html="$options.filters.status(item.shipment.status_id)" />)
                </div>
              </div>
            </template>

            <template #item.item_name="{ item }">
              <div v-if="item.items_count > 1">
                Multiple Items
              </div>
              <div v-else>
                {{ item.item_name }}
              </div>
            </template>

            <template #item.order_at="{ item }">
              {{ item.order_at | datetime_pretty }}
              <br>
              ({{ item.order_at | datetime_from }})
            </template>

            <template #item.value="{ item }">
              <div>{{ item.value | currency }}</div>
            </template>

            <template #item.tags="{ item }">
              <div style="display: flex">
                <div
                  v-for="tag in item.tags"
                  :key="tag.id"
                  :style="{
                    backgroundColor: tag.color_code,
                    width: '10px',
                    height: '20px',
                    borderRadius: '10%',
                    marginRight: '2px'
                  }"
                />
              </div>
            </template>

            <template #item.note="{ item }">
              <i
                class="mdi mdi-comment-text text-h4" 
                :class="item.note ? 'text-primary' : 'text-light-gray'"
                :title="item.note"
              />
            </template>


            <template #item.name="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-icon
                    v-if="item.address_verification_status == 'Verified'"
                    small
                    color="success"
                    v-on="on"
                  >
                    mdi-check-circle
                  </v-icon>
                  <v-icon
                    v-else-if="item.address_verification_status == 'Unverified'"
                    small
                    color="warning"
                    v-on="on"
                  >
                    mdi-alert
                  </v-icon>
                  <v-icon
                    v-else-if="item.address_verification_status == 'Verification Failed'"
                    small
                    color="error"
                    v-on="on"
                  >
                    mdi-alert-circle
                  </v-icon>
                  {{ item.name }}
                </template>
                <span v-if="item.address_verification_status == 'Verified'">
                  Verified
                </span>
                <span v-else-if="item.address_verification_status == 'Unverified'">
                  Unverified
                </span>
                <span v-else-if="item.address_verification_status == 'Verification Failed'">
                  Verification Failed
                </span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import OrderSearch from './OrderSearch.vue';
import PERMISSIONS from '@/constants/client-permissions';
import FilterButtons from '@/components/shared/FilterButtons.vue';
import CONSTANTS from '@/constants/index'

export default {
  components: {
    OrderSearch,
    FilterButtons
  },
  props: {
    condensed: {
      default: false,
      type: Boolean
    },
    value: {
      default: null,
      type: Array
    }
  },
  data() {
    return {
      manageColumnTableKey: 'table_columns_client_orders',
      manageColumnSettings: [],
      filter: 'Awaiting Shipment',
      PERMISSIONS: PERMISSIONS,
      loading: false,
      filters: {
        filter_id: null,
        user_store_id: [],
        search: null,
        search_type: 'Order ID',
        country_code_type: 'Custom',
        order_date_min: null,
        order_date_max: null,
        value_min: null,
        value_max: null,
        weight_min: null,
        weight_max: null,
        quantity_min: null,
        quantity_max: null,
        country_code: [],
        country_code_inclusive: true,
        order_tag: null,
        show_archived: false,
      },
      statuses: [
        {
          text: 'All',
          icon: 'mdi-package-variant-closed',
          value: null
        },
        {
          text: 'Awaiting Shipment',
          icon: 'mdi-circle-edit-outline',
          value: 'Awaiting Shipment'
        },
        {
          text: 'Shipped',
          icon: 'mdi-circle-edit-outline',
          value: 'Shipped'
        },
     
        {
          text: 'Voided',
          icon: 'mdi-circle-edit-outline',
          value: 'Voided'
        },
      ],
      isExporting: false,

      show: null,
      show2: null,

      options: {
        sortBy: ['order_at'],
        sortDesc: [true],
        itemsPerPage: 25
      },
      totalItems: 0,
      items: [],

      headers: [
        {
          text: 'Full Address',
          align: 'left',
          value: 'address',
          sortable: false
        },
        {
          text: 'Name',
          align: 'left',
          value: 'name'
        },
        {
          text: 'Address1',
          align: 'left',
          value: 'address1'
        },
        {
          text: 'City',
          align: 'left',
          value: 'city'
        },
        {
          text: 'Province',
          align: 'left',
          value: 'province_code'
        },
        {
          text: 'Postal Code',
          align: 'left',
          value: 'postal_code'
        },
    
        {
          text: 'Country',
          align: 'left',
          value: 'country_code'
        },
        {
          text: 'Order ID',
          align: 'left',
          value: 'order_id'
        },
        {
          text: 'Store',
          align: 'left',
          value: 'user_store.identifier',
          sortable: false
        },
        {
          text: 'Tags',
          align: 'left',
          value: 'tags',
          sortable: false
        },
        {
          text: 'SKU',
          align: 'left',
          value: 'sku',
        },
        {
          text: 'Item Name',
          align: 'left',
          value: 'item_name',
        },
        {
          text: '# Items',
          align: 'left',
          value: 'items_count',
          sortable: false
        },
        {
          text: 'Total',
          align: 'left',
          value: 'value'
        },
        {
          text: 'Shipping Cost',
          align: 'left',
          value: 'shipping_cost'
        },
        // {
        //   text: 'Shipment',
        //   align: 'left',
        //   value: 'shipment',
        //   sortable: false
        // },
        {
          text: 'Ordered At',
          value: 'order_at'
        },
        {
          text: 'Notes',
          align: 'left',
          value: 'note',
          sortable: false
        },
        {
          text: 'Status',
          align: 'left',
          value: 'status',
        }
      ],

      search: null,

      start_date: null,
      end_date: null,
      start_date_menu: null,
      end_date_menu: null,
      needs_postage: null,
      country_code: null,
      timer: null,

      cancelSource: null,
      showAdvanceSearch: false,
      postageType: null,
      packageType: null,

      shiftKeyOn: false,
      current: [],
      last: null,
      selected_tags: [],
      settingTags: false,
      address_issue: null,
    };
  },
  computed: {
    ...mapState(['main', 'tags']),
    ...mapGetters({ append_tags: 'tags/append_tags' }),
    filteredHeaders() {
      // filteredColumnHeaders is from mixin.js
      return this.manageColumnTableKey
        ? this.filteredColumnHeaders(this.headers, this.manageColumnSettings, this.manageColumnTableKey)
        : this.headers
    },
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    quickerShipTabs() {
      return CONSTANTS.QUICKER_SHIP_TABS
    }
  },
  watch: {
    filter: function () {
      this.options.page = 1;
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    selected: function (val) {
      if (val) {
        const tags = [];
        for (let item of val) {
          if (item.tags) {
            item.tags.map((i) => {
              if (!tags.includes(i.id)) {
                tags.push(i.id);
              }
            });
          }
        }
        this.selected_tags = tags;
        this.settingTags = true;
      }
    },
    selected_tags: function (newVal, oldVal) {
      if (!this.settingTags) {
        this.modifyOrderTags(newVal, oldVal);
      } else {
        this.settingTags = false;
      }
    },
    date_type: function (val) {
      this.start_date = null;
      this.end_date = null;

      if (val == 'Today') {
        this.start_date = moment().format('YYYY-MM-DD');
        this.end_date = moment().format('YYYY-MM-DD');
      } else if (val == 'Yesterday') {
        this.start_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
        this.end_date = moment().subtract(1, 'days').format('YYYY-MM-DD');
      } else if (val > 0) {
        this.start_date = moment().format('YYYY-MM-DD');
        this.end_date = moment().subtract(val, 'days').format('YYYY-MM-DD');
      }
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            orders: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },

  async mounted() {
    this.main.selectedShipments = [];
    this.updateTable();
    Event.listen('update-orders', () => {
      this.updateTable();
    });

    Event.listen('update-single', (order) => {
      this.updateSingle(order);
    });

    Event.listen('update-multiple', (orders) => {
      orders.forEach((order) => {
        this.updateSingle(order);
      });
    });

    this.$root.$on('order-table-show-select', (select) => {
      if (!select) {
        this.main.selectedShipments = this.items;
      } else {
        this.main.selectedShipments = [];
      }
    });

    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.orders ?? 25;

    // getManageColumnSettings is from mixin.js
    this.manageColumnSettings = await this.getManageColumnSettings(this.manageColumnTableKey)
  },
  created() {
    const self = this;
    self.keyDownHandler = function ({ key }) {
      if (key == 'Shift') self.shiftKeyOn = true;
    };
    self.keyUpHandler = function ({ key }) {
      if (key == 'Shift') self.shiftKeyOn = false;
    };
    window.addEventListener('keydown', this.keyDownHandler);
    window.addEventListener('keyup', this.keyUpHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('keyup', this.keyUpHandler);
  },
  methods: {
    updateColumns(columns) {
      this.manageColumnSettings = columns
    },
    setQuickerTab(tab) {
      this.$store.dispatch('main/setQuickerShipTab', tab)
    },
    openQuickerTab(item, tab) {
      this.setQuickerTab(tab)
      this.setSelectedRow(item)
    },
    setSelectedRow(item) {
      this.selected = [item];
      this.main.selectedShipments = this.selected;
    },
    rowClick(item) {
      this.setQuickerTab()
      this.setSelectedRow(item)
    },
    bulkSelect({ item: b, value }) {
      const { current, shiftKeyOn } = this;

      if (value == true && shiftKeyOn) {
        let [a] = this.main.selectedShipments;

        if (this.last !== null) {
          a = this.last;
        }

        let start = current.findIndex((item) => item == a);
        let end = current.findIndex((item) => item == b);

        if (start - end > 0) {
          let temp = start;
          start = end;
          end = temp;
        }
        for (let i = start; i <= end; i++) {
          this.main.selectedShipments.push(current[i]);
        }
      }

      this.last = b;
    },
    selectAll() {
      if (this.main.selectedShipments.length > 0) {
        this.main.selectedShipments = [];
      } else {
        this.main.selectedShipments = this.items.map((x) => x['id']);
      }
    },
    submitSearch() {
      // clearTimeout(this.timer);
      // var ms = 500;
      // var self = this;
      this.options.page = 1;
      // this.timer = setTimeout(function () {
        this.updateTable();
      // }, ms);
    },
    updateSingle(order) {
      const index = this.items.findIndex((x) => x.id === order.id);
      this.$set(this.items, index, order);
    },
    updateTable() {
      this.loading = true;
      // this.main.selectedShipments = [];
      this.totalItems = 0;
      // this.items = [];
      // this.resetSelected();

      this.getDataFromApi()
        .then((data) => {
          this.items = data.items;
          this.totalItems = data.total;

          const old = this.selected.map((x) => x['id']);

          this.selected = this.items.filter((value) => old.includes(value.id));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetSelected() {
      this.selected = [];
    },
    getParams() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      var paginate_params = {
        page: page,
        per_page: itemsPerPage,
        sort_by: sortBy[0],
        desc: sortDesc[0],
        status: this.filter,
        address_verification_status: this.address_issue ? 'Verification Failed' : null
      };

      const filters = this.filters;

      const params = { ...paginate_params, ...filters };

      return params;
    },
    getDataFromApi() {
      // this.loading = true;
      return new Promise((resolve) => {
        let items = [];
        let total = 0;
        let pages = 0;

        var params = this.getParams()

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/orders`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            response.data = this.append_tags(response.data);
            return response;
          })
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            resolve({
              items,
              total,
              pages
            });
          })
          .finally(() => {
            // this.loading = false;
          });
      });
    },
    async modifyOrderTags(newTag, oldValues) {
      if (newTag.length !== oldValues.length) {
        const order_ids = this.selected.map((r) => r.id);

        if (newTag.length > oldValues.length) {
          const tag_ids = newTag[0]?.id ? newTag.map((i) => i.id) : newTag;
          const tag_id = tag_ids.filter((x) => !oldValues.includes(x));
          await this.appendOrderTag(tag_id, order_ids);
        } else if (newTag.length < oldValues.length) {
          const tag_ids = oldValues[0]?.id
            ? oldValues.map((i) => i.id)
            : oldValues;
          const tag_id = tag_ids.filter((x) => !newTag.includes(x));
          await this.removeOrderTag(tag_id, order_ids);
        }
      }
    },
    appendOrderTag(newTag, ids) {
      this.$http
        .post('/orders/append-tag', {
          tag_id: newTag,
          orders: ids
        })
        .then(() => {
          const tag = this.tags.tags.find((i) => i.id == newTag);
          for (let id of ids) {
            const order = this.selected.find((i) => i.id == id);
            order.tags = order.tags.length ? order.tags.concat([tag]) : [tag];
          }
        });
    },
    removeOrderTag(tag_id, ids) {
      this.$http
        .post('/orders/remove-tag', {
          tag_id: tag_id,
          orders: ids
        })
        .then(() => {
          for (let id of ids) {
            const order = this.selected.find((i) => i.id == id);
            if (order.tags) {
              order.tags.find((i) => {
                if (i.id == tag_id[0]) {
                  const index = order.tags.indexOf(i);
                  order.tags.splice(index, 1);
                }
              });
            }
          }
        });
    },
    exportSelected() {
      this.isExporting = true;
      var order_ids = this.selected.map((x) => x.id);

      var params = {
        order_ids: order_ids,
      };

      params.export = true

      this.$http
        .request(`/orders`, {
          method: 'get',
          params: params,
        })
        .then((response) => {
          this.downloadFileUrl(response.data,`Stallion_Export.csv`, 'text/csv');
        })
        .catch(() => {
          this.errorMessage('Nothing to export');
        })
        .finally(() => {
          this.isExporting = false;
        });
    },
    exportOrders() {
      this.isExporting = true;
      var params = this.getParams()

      params.export = true

      this.$http
        .request(`/orders`, {
          method: 'get',
          params: params,
        })
        .then((response) => {
          this.downloadFileUrl(response.data,`Stallion_Export.csv`, 'text/csv');
        })
        .catch(() => {
          this.errorMessage('Nothing to export');
        })
        .finally(() => {
          this.isExporting = false;
        });
    }
  }
};
</script>

<style>
.v-application p {
  margin-bottom: 0px;
}

@media only screen and (min-width: 1440px) {
  .order-buttons {
    max-width: 200px;
  }
}

.input-group--selection-controls__ripple {
  border-radius: 0 !important;
}
</style>

<style scoped>
.v-data-table td {
  display: flex;
}
.text-light-gray {
  color: #DEE3E7;
}
</style>
