<template>
  <div
    :style="fillHeight ? 'height: 100%' : null"
    class="user-setting"
  >
    <v-card
      outlined
      class="pa-5 my-3"
      :height="fillHeight ? '100%' : null"
    >
      <div v-if="list.optionType == 'toggle'">
        <h3>{{ list.name }}</h3>
        <p
          v-if="showDescription"
          class="mt-3 mb-3 text-caption"
        >
          {{ list.description }}
        </p>
        <v-switch
          v-model="list_value"
          class="card-actions"
          :label="`${list_value ? 'Enabled' : 'Disabled'}`"
          :disabled="disabled"
        />
      </div>

      <div v-if="list.optionType == 'select'">
        <h3>{{ list.name }}</h3>
        <p
          v-if="showDescription"
          class="mt-3 mb-3 text-caption"
        >
          {{ list.description }}
        </p>
        <v-select
          v-model="list.value"
          class="card-actions"
          data-vv-name="postage_type"
          :items="list.options"
          item-text="name"
          item-value="value"
          :chips="chips"
          :disabled="disabled"
          outlined
          hide-details
          :dense="dense"
          :multiple="list.multi == true"
        />
      </div>

      <div v-if="list.optionType == 'input'">
        <h3>{{ list.name }}</h3>
        <p
          v-if="showDescription"
          class="mt-3 mb-3 text-caption"
        >
          {{ list.description }}
        </p>
        <v-text-field
          v-model="list.value"
          v-validate="list.validation || ''"
          class="card-actions"
          :data-vv-name="list.key"
          :error-messages="errs.collect(list.key)"
          data-vv-validate-on="blur"
          :type="getType(list.type)"
          :label="list.name"
          :disabled="disabled"
          outlined
          :dense="dense"
        />
      </div>

      <div v-if="list.optionType == 'secret'">
        <h3>{{ list.name }}</h3>
        <p
          v-if="showDescription"
          class="mt-3 mb-3 text-caption"
        >
          {{ list.description }}
        </p>
        <v-text-field
          v-model="list.value"
          v-validate="list.validation || ''"
          class="card-actions"
          clearable
          :data-vv-name="list.key"
          :error-messages="errs.collect(list.key)"
          data-vv-validate-on="blur"
          type="password"
          :label="list.name"
          :disabled="disabled"
          outlined
          :dense="dense"
        />
      </div>

      <div v-if="list.optionType == 'combo'">
        <h3>{{ list.name }}</h3>
        <p
          v-if="showDescription"
          class="mt-3 mb-3 text-caption"
        >
          {{ list.description }}
        </p>
        <v-combobox
          v-model="list.value"
          class="card-actions"
          :label="list.name"
          :disabled="disabled"
          multiple
          chips
          clearable
          outlined
          :dense="dense"
        />
      </div>

      <!-- address -->
      <div v-if="list.optionType == 'address'">
        <h3>{{ list.name }}</h3>
        <p
          v-if="showDescription"
          class="mt-3 mb-3 text-caption"
        >
          {{ list.description }}
        </p>

        <v-text-field
          v-model="list.value.name"
          label="Name"
          outlined
          :dense="dense"
          :disabled="disabled"
          class="card-actions"
        />

        <v-text-field
          v-model="list.value.address1"
          label="Address1"
          outlined
          :dense="dense"
          :disabled="disabled"
          class="card-actions"
        />

        <v-text-field
          v-model="list.value.address2"
          label="Address2"
          outlined
          :dense="dense"
          :disabled="disabled"
          class="card-actions"
        />

        <v-text-field
          v-model="list.value.city"
          label="City"
          outlined
          :dense="dense"
          :disabled="disabled"
          class="card-actions"
        />

        <v-text-field
          v-model="list.value.province_code"
          label="State Code"
          outlined
          :dense="dense"
          :disabled="disabled"
          class="card-actions"
        />

        <v-text-field
          v-model="list.value.postal_code"
          label="Zip"
          outlined
          :dense="dense"
          :disabled="disabled"
          class="card-actions"
        />

        <v-text-field
          v-model="list.value.country_code"
          label="Country Code"
          outlined
          :dense="dense"
          :disabled="disabled"
          class="card-actions"
        />

        <v-text-field
          v-model="list.value.phone"
          label="Phone"
          outlined
          :dense="dense"
          :disabled="disabled"
          class="card-actions"
        />

        <v-text-field
          v-model="list.value.email"
          label="Email"
          outlined
          :dense="dense"
          :disabled="disabled"
          class="card-actions"
        />

        <v-switch
          v-model="list.value.is_residential"
          label="Is Residential"
          outlined
          :dense="dense"
          :disabled="disabled"
          class="card-actions"
        />
      </div>  
        

      <slot name="option-footer" />
    </v-card>

    <div
      v-if="
        list.value &&
          list.key == 'return_auto_request' &&
          (!$auth.user.reload_credit_setting ||
            !$auth.user.reload_credit_setting.status)
      "
      cols="12"
      md="12"
    >
      <v-alert
        outlined
        type="warning"
      >
        You currently do not have the "Auto-Fund Account" feature enabled.
        Without this feature enabled, the daily auto request will not work when
        your credits run out. Go to the
        <router-link to="credits">
          credits
        </router-link> page to enable this
        feature.
      </v-alert>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],
  props: {
    list: {
      required: true,
      type: Object
    },
    dense: {
      default: true,
      type: Boolean
    },
    fillHeight: {
      default: false,
      type: Boolean
    },
    showDescription: {
      default: true,
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    showOverrideToggle: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    chips: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  computed: {
    list_value: {
      get() {
        if (this.list.type == 'boolean') {
          return this.list.value == '1';
        }
        return this.list.value;
      },
      set(val) {
        if (this.list.type == 'boolean') {
          this.list.value = val ? '1' : '0';
          return;
        }
        this.list.value = val;
      }
    }
  },
  methods: {
    getType(type) {
      if (['int', 'double', 'float'].includes(type)) {
        return 'number';
      } else if (['email'].includes(type)) {
        ('email');
      } else {
        return 'text';
      }
    }
  }
};
</script>
<style>
.user-setting h3 {
  margin-bottom: 10px !important;
}</style>
