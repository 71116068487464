<template>
  <FullPageLayout title="Users">
    <template #default>
      <v-row>
        <v-col>
          <v-btn
            v-if="can('users.create')"
            outlined
            color="primary"
            @click="showCreateUserForm = true"
          >
            Create User
          </v-btn>
        </v-col>
        <v-col>
          <v-form @submit.prevent="submitSearch()">
            <v-container pa-0>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="search"
                    pt-0
                    label="Name/Email/ImporterNumber"
                    append-icon="search"
                    @input="submitSearch()"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-col>
        <v-col>
          <v-form @submit.prevent="submitSearch()">
            <v-container pa-0>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="phone"
                    pt-0
                    label="Phone"
                    append-icon="phone"
                    @input="submitSearch()"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :loading="loading"
        item-key="id"
        :items="items"
        :options.sync="options"
        class="table-striped"
        :server-items-length="totalItems"
        :footer-props="{
          'items-per-page-options': [50, 100, 200, 500]
        }"
        :items-per-page="25"
        @click:row="rowClick"
      >
        <template #item.credits="{ item }">
          {{ item.credits | currency }}
        </template>
        <template #item.manager_user_id="{ item }">
          <span v-if="item.manager">
            {{ item.manager.name }}
          </span>
        </template>
        <template #item.created_at="{ item }">
          {{ item.created_at | date_pretty }}
        </template>
        <template #item.action="{ item }">
          <v-btn
            v-if="item.return_agree"
            color="primary"
            @click.stop="download(item)"
          >
            <v-icon
              small
              dark
            >
              mdi-download
            </v-icon>
            POA
          </v-btn>
        </template>
      </v-data-table>

      <create-user
        v-model="showCreateUserForm"
        @reloadUsers="updateTable"
      />
    </template>
  </FullPageLayout>
</template>

<script>
export default {
  data() {
    return {
      start_date: null,
      end_date: null,
      search: null,

      headers: [
        {
          text: 'ID',

          align: 'left',
          value: 'id'
        },
        {
          text: 'Username',

          align: 'left',
          value: 'username'
        },
        {
          text: 'Email',
          value: 'email'
        },

        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Location',
          value: 'branch_location.name',
          sortable: false
        },
        {
          text: 'Business',
          value: 'business_name'
        },
         {
          text: 'Phone',
          value: 'phone'
        },
        {
          text: 'Account Manager',
          value: 'manager_user_id'
        },
        {
          text: 'Credits',
          value: 'credits'
        },
        {
          text: 'Importer Number',
          value: 'importer_number'
        },
        {
          text: 'Created At',
          value: 'created_at'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },

      totalItems: 0,
      items: [],

      loading: false,

      timer: null,
      cancelSource: null,
      showCreateUserForm: false,
      phone: null
    };
  },

  watch: {
    '$route.query.action'() {
      this.updateTable();
    },
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    }
  },
  mounted() {
    this.getDataFromApi().then((data) => {
      this.desserts = data.items;
      this.totalDesserts = data.total;
      this.numPages = data.pages;
    });
  },

  methods: {
    rowClick(item) {
      this.$router.push({
        name: 'admin-user-show',
        params: {
          id: item.id
        }
      });
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.$store.state.main.selectedBatches = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then((data) => {
        this.items = data.items;
        this.totalItems = data.total;
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search,
          start_date: this.start_date,
          end_date: this.end_date,
          phone: this.phone
        };

        this.$http
          .request(`/admin/users`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })

          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },

    download(user) {
      this.$http({
        method: 'post',
        url: '/admin/users/poa',
        data: { user_id: user.id }
      })

        .then((response) => {
          this.downloadFileUrl(response.data,(user.name + '_POA.pdf').replace(' ', '_'));
        })
        .catch(() => {
          swal({
            title: 'Error',
            text: 'Unable to find POA',
            icon: 'error'
          });
        });
    }
  }
};
</script>
