<template>
  <div>
    <router-link
      exact
      router
      to="/merge-orders"
      :class="{ disabled: !can(PERMISSIONS.ORDERS) }"
    >
      Merge Orders
    </router-link>
    |
    <router-link
      exact
      router
      to="/scan"
      :class="{ disabled: !can(PERMISSIONS.ORDERS) }"
    >
      Scan
    </router-link>
    |
    <router-link
      exact
      router
      to="/products"
      :class="{ disabled: !can(PERMISSIONS.PRODUCTS) }"
    >
      Products
    </router-link>
    |
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <a
          href="javascript:void(0)"
          router
          v-bind="attrs"
          :class="{ disabled: !can(PERMISSIONS.AUTOMATION) }"
          v-on="on"
          @click="$refs.manageTags.openDialog()"
        >
          Tags
        </a>
      </template>
    </v-menu>
    |
    <router-link
      exact
      router
      to="/automation-rules"
      :class="{ disabled: !can(PERMISSIONS.AUTOMATION) }"
    >
      Automation
    </router-link>
    |
    <router-link
      exact
      router
      to="/filters"
      :class="{ disabled: !can(PERMISSIONS.AUTOMATION) }"
    >
      Filters
    </router-link>
    |
    <router-link
      exact
      router
      to="/list-criteria"
      :class="{ disabled: !can(PERMISSIONS.AUTOMATION) }"
    >
      List Criteria
    </router-link>
    |
    <router-link
      exact
      router
      to="/boxes"
      :class="{ disabled: !can(PERMISSIONS.AUTOMATION) }"
    >
      Boxes
    </router-link>

    <manage-tags
      ref="manageTags"
      :disabled="!can(PERMISSIONS.AUTOMATION)"
    />
  </div>
</template>
<script>
import PERMISSIONS from '@/constants/client-permissions';
export default {
  data() {
    return {
      PERMISSIONS: PERMISSIONS
    };
  }
};
</script>
