<template>
  <v-container
    grid-list-md
    mb-12
  >
    <v-card outlined>
      <v-card-subtitle>
        <v-row>
          <v-col class="text-right">
            <span v-if="autoRefresh === true">
              <v-icon left>
                mdi-loading mdi-spin
              </v-icon>
      
              Auto Refresh in {{ countDown }}
            </span>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          item-key="id"
          :options.sync="options"
          :server-items-length="totalItems"
          :footer-props="{
            'items-per-page-options': [5, 10, 15]
          }"
          :items-per-page="25"
          dense
        >
          <template #item.user_id="{ item }">
            {{ item.client_user.business_name || item.client_user.name }}
          </template>
          
          <template #item.created_at="{ item }">
            <span v-if="item.created_at">
              {{ item.created_at | date_pretty }}
              <br>
              ({{ item.created_at | datetime_from }})
            </span>
          </template>
      
          <template #item.completed_at="{ item }">
            <span v-if="item.completed_at">
              {{ item.completed_at | date_pretty }}
              <br>
              ({{ item.completed_at | datetime_from }})
            </span>
          </template>

          <template #item.file="{ item }">
            <a
              href="javascript:void(0);"
              @click="!loading && download(item.file)"
            >
              {{ item.file | file_download }}
            </a>
          </template>

          <template #item.status="{ item }">
            <span
              v-if="item.error"
            >
              <v-icon>
                mdi-alert
              </v-icon>
              {{ item.error }}
            </span>
            <span
              v-else-if="item.completed_at"
            >
              <v-icon>
                mdi-check
              </v-icon>
              Completed 
            </span>
            <span v-else>
              <v-icon
                small
                left
              >
                mdi-loading mdi-spin
              </v-icon>Processing
            </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
const DOWNLOAD_TAB = 2;
const COUNTDOWN = 10  ;

export default {
  props: {
    userId: {
      type: Number,
      default: null
    },
    tab: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Client',
          value: 'user_id',
          sortable: false
        },
        {
          text: 'Type',
          value: 'type',
          sortable: true
        },
        {
          text: 'File',
          value: 'file',
          sortable: true
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false
        },
        // {
        //   text: 'Created At',
        //   value: 'created_at',
        //   align: 'right',
        //   sortable: false
        // },
        {
          text: 'Completed At',
          value: 'completed_at',
          align: 'right',
          sortable: true
        }
      ],
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      loading: false,
      totalItems: 0,
      items: [],
      countDown: COUNTDOWN,
      autoRefresh: false
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    tab: {
      handler() {
        if (this.tab === DOWNLOAD_TAB) {
          this.updateTable();
        } else {
          this.autoRefresh = false;
        }
      },
      immediate: true
    }
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.updateTable();
      }
    },
    download: function(data) {
      this.loading = true
      this.$http
        .post('/admin/rate-estimates/download', { file: data })
        .then(response => {
          this.downloadFileUrl(response.data, data);
        })
        .finally(() => {
          this.loading = false
        });
    },
    updateTable() {
      if (this.tab !== DOWNLOAD_TAB) {
        return;
      }

      this.selected = [];
      this.totalItems = 0;
      this.items = [];

      this.getDataFromApi().then(data => {
        this.items = data.items;
        this.totalItems = data.total;

        for (var i = 0; i < this.items.length; i++) {
            this.autoRefresh = false;
            if (!this.items[i].completed_at && !this.items[i].error) {
              this.autoRefresh = true;
              this.countDown = COUNTDOWN;
              this.countDownTimer();
              break;
            }
          }
      });
    },

    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0]
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/admin/rate-estimates/files`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then(response => response.data)
          .then(response => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            resolve({
              items,
              total,
              pages
            });
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    }
  }
};
</script>
