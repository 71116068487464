<template>
  <v-tooltip top>
    <template #activator="{ on }">
      <v-btn
        icon
        small
        :disabled="disableWeightFetch"
        @click="getWeight"
        v-on="on"
      >
        <v-icon color="orange lighten-1">
          mdi-scale
        </v-icon>
      </v-btn>
    </template>
    <span>Fetch USB weight</span>
  </v-tooltip>
</template>

<script>

import {mapState} from "vuex";
import Vue from "vue";

export default {
  name: "AutoScaleButton",
  data() {
    return {
      weight: null,
      fetchWeight: '',
      weightStorage: [],
      disableWeightFetch: false,
    }
  },

  computed: {
    ...mapState(['userStores']),
    shipment: {
      get() {
        return this.$store.state.singleFlow.shipment;
      },
      set(value) {
        this.$store.state.singleFlow.shipment = value;
      }
    },
  },

  watch: {
    '$store.state.singleFlow.step': function() {
      if(this.$store.state.singleFlow.step === 3 && this.$store.state.qz.enabledAutoWeightFetch){
        this.startWeightRead();
      } else {
        this.closeScaleStream();
      }
    },
  },

  mounted() {
    Event.listen('close-auto-weights', () => {
      this.closeScaleStream();
    });
    if (this.$store.state.qz.qzStatus && this.$store.state.qz.enabledAutoWeightFetch) {
      this.startWeightRead();
    }
    this.$qz.hid.setHidCallbacks(async (streamEvent) => {
      if(streamEvent.type === "RECEIVE"){
        let newWeight = await this.parseStreamData(streamEvent.output);
        console.log("weight",newWeight.data.weight);
        if(newWeight.status){
          this.setAutoWeight(newWeight);
        }
      } else if(streamEvent.type === "ERROR"){
        await this.$store.dispatch('qz/putCurrentScaleStream', false);
        console.log('Stream closed / scale disconnected');
      }
    });
  },

  methods: {
    async getWeight() {
      this.disableWeightFetch = true;
      try {
        if (!this.$store.state.qz.currentScaleStream && this.$store.state.qz.qzStatus && this.$store.state.qz.enabledAutoWeightFetch) {
          await this.startWeightRead();
        } else {
          let weight = await this.getWeightFromScale();
          this.setWeight(weight);
        }
      } catch (e) {
        if(!this.$qz.websocket.isActive()){
          await this.$qz.websocket.connect();
        }
       await this.errorNotify(e.message);
      }
    },

    async startWeightRead() {
      if(!this.$store.state.qz.currentScaleStream) {
        await this.startScaleStream();
      }
    },

    setAutoWeight(weight){
      setTimeout(() => {
        this.disableWeightFetch = false;
      }, 1000);
      if (weight.status) {
        this.weightStorage.push(weight.data.weight);
        if (this.weightStorage.length === 8) {
          if(weight.data.weight < 0.1){
            this.$store.dispatch('qz/putWeight', {status: true, data: {weight: 0.0, weight_unit: weight.data.weight_unit}});
            this.shipment.weight = 0.0;
          } else {
            if(this.weightStorage[7] === this.weightStorage[6] && this.weightStorage[7] === this.weightStorage[5]){
            this.$store.dispatch('qz/putWeight', weight);
            this.shipment.weight = weight.data.weight;
            this.closeScaleStream();
            }
          }
          this.shipment.weight_unit = weight.data.weight_unit;
          this.weightStorage = [];
        }
      }
    },

    setWeight(weight) {
      setTimeout(() => {
        this.disableWeightFetch = false;
      }, 1000);
      if (weight.status) {
        Vue.notify({
          group: 'main',
          text: 'Fetched weight from scale...',
          duration: 2000,
          type: 'success'
        });
        this.shipment.weight = weight.data.weight;
        this.shipment.weight_unit = weight.data.weight_unit;
        this.$store.dispatch('qz/putWeight',weight);
        this.$emit('weight');
      }
    }
  }
}
</script>

<style scoped>

</style>
