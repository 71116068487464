<template>
  <SidePanelLayout :title="'Order Scan'">
    <template #side-panel>
      <div>
        <view-shipment-details
          v-if="shipment"
          ref="refViewShipmentDetails"
          style="font-size: 14px"
          :shipments="selected"
        />

        <v-overlay
          :z-index="1"
          :value="shipmentDetailsOverlay"
          :opacity="0.1"
          :absolute="true"
        />
      </div>
    </template>

    <template #page-links>
      <v-col cols="auto">
        <router-link
          to="/batches"
          :class="{ disabled: !can(PERMISSIONS.SHIPPING) }"
        >
          Batches
        </router-link>
        |
        <router-link to="/print-jobs">
          Print Jobs
        </router-link>
      </v-col>
    </template>

    <template #default>
      <scan-shipment
        v-if="!shipment || !showVerifySection"
        ref="scanShipment"
        :scan-type="scanType"
        @scanShipment="scanShipment"
      />

      <verify-items
        v-if="shipment && showVerifySection"
        class="mt-6"
        :shipment="shipment"
        @close="closeVerificationWindow"
      />

      <v-card
        v-else
        elevation="0"
        class="mt-6 d-flex align-center justify-center"
        min-height="70vh"
        style="background: rgb(234 234 230);"
      >
        <div>
          <h1> Scan a packing slip. </h1>
          You can also type in an order number.
        </div>
      </v-card>
    </template>

    <template #footer>
      <action-bar
        ref="refActionBar"
        :loading="loading"
        :shipments="selected"
        :scan-type="scanType"
        @update="$refs.shipmentTable.updateTable()"
      />
    </template>
  </SidePanelLayout>
</template>

<script>
import PERMISSIONS from '@/constants/client-permissions';
import SCAN_CONSTANTS from '@/constants/scan';
import ScanShipment from '../components/scan-shipment.vue';
import VerifyItems from '../components/verify-items.vue';
import ActionBar from '../components/action-bar.vue';
export default {
  metaInfo: {
    title: "Shipments Scan to Verify & Print | Stallion Express"
  },
  components: {
    ScanShipment,
    VerifyItems,
    ActionBar
  },
  data() {
    return {
      shipmentDetailsOverlay: false,
      shipment: null,
      scanType: SCAN_CONSTANTS.SCAN_TO_PRINT,
      PERMISSIONS: PERMISSIONS,
      loading: false
    };
  },
  computed: {
    showVerifySection() {
      return SCAN_CONSTANTS.VERIFY_SCAN_WORKFLOW.includes(this.scanType);
    },
    selected() {
      return !this.shipment ? [] : [this.shipment];
    },
    canEdit() {
      // 3 = INCOMPLETE
      return this.shipment && this.shipment.status_id == 3 && this.can('CLIENT.SHIPPING')
    },
  },
  mounted() {
    this.scanType = this.getUserSetting('default_scan_method', SCAN_CONSTANTS.SCAN_TO_PRINT);
    // Listen for updates from sidebar panel view-shipment-details.vue
    // ex: save / purchase button
    Event.listen('update-single', (shipment) => {
      this.setShipment(shipment);
      this.loading = false; // enables action-bar buttons
    });

    // Listen for updates from action-bar.vue markAsVerified
    Event.listen('mark-as-verified', (shipment) => {
      shipment.items_verified = 1;
      this.setShipment(shipment);
      this.shipmentDetailsOverlay = false;

      this.setShipmentTab();

      this.loading = true; // disables action-bar buttons

      setTimeout(() => {
        this.$nextTick(() => {
          this.openQuickShipFormDialog();
        });
      }, 300);
    });

    // Listen for updates from action-bar.vue markAsVerified
    Event.listen('enable-verification', (shipment) => {
      shipment.items_verified = 0;
      this.setShipment(shipment);
      this.shipmentDetailsOverlay = true;
    });
  },
  methods: {
    openQuickShipFormDialog() {
      if (!this.canEdit) {
        return;
      }

      setTimeout(() => {
        const ref = this.$refs.refViewShipmentDetails?.$refs?.refViewShipmentDetailsTab;
        if (!ref) {
          return;
        }

        if (this.scanType == SCAN_CONSTANTS.SCAN_TO_PRINT) {
          ref.openQuickShipFormDialog();
          this.quickShipPurchaseLabel();
        } else if (this.scanType == SCAN_CONSTANTS.SCAN_TO_VERIFY_AND_PRINT && this.shipment.items_verified) {
          ref.openQuickShipFormDialog();
          this.quickShipPurchaseLabel();
        }
      }, 100)
    },
    quickShipPurchaseLabel() {
      if (!this.canEdit) {
        return;
      }

      setTimeout(() => {
        const ref = this.$refs.refViewShipmentDetails?.$refs?.refViewShipmentDetailsTab?.$refs?.refQuickShipFormDialog;
        if (!ref) {
          return;
        }

        if (SCAN_CONSTANTS.PRINT_SCAN_WORKFLOW.includes(this.scanType)) {
          // auto purchase
          ref.submitForm();
        }
      }, 100)
    },
    setShipment(shipment) {
      if (!shipment) {
        this.$set(this.shipment, shipment)
        this.$forceUpdate();
      }
    },
    scanShipment(payload) {
      this.shipment = payload.shipment;
      this.scanType = payload.scanType;
      // disable overlay of sidebar panel
      if (!this.canEdit || !this.showVerifySection || this.shipment.items_verified) {
        this.shipmentDetailsOverlay = false
      } else {
        this.shipmentDetailsOverlay = true
      }

      this.setShipmentTab();
    },
    setShipmentTab() {
      setTimeout(() => {
        this.$nextTick(() => {
          let ref = this.$refs.refViewShipmentDetails;
          if (ref) {
            ref.setViewTab();
          }

          if (SCAN_CONSTANTS.PRINT_SCAN_WORKFLOW.includes(this.scanType)) {
            if (!this.canEdit) {
              // show print dialog if shipment is not editable
              ref = this.$refs.refActionBar;
              if (ref) {
                ref.buildPrinter('label_only');
              }
            } else {
              this.openQuickShipFormDialog();
            }
          }
        });
      }, 300);
    },
    closeVerificationWindow() {
      this.shipment = null;
      this.shipmentDetailsOverlay = false;
    }
  }
};
</script>
