<template>
  <div>
    <!--isPrimaryAccountHolder from mixin.js-->
    <v-btn
      v-if="!hideButtons.includes('process')"
      color="primary"
      :disabled="!canCreateLabelsFromShipments(shipments) || !can(PERMISSIONS.SHIPPING)"
      @click="openCreateLabelsDialogFromShipments"
    >
      Process
    </v-btn>
    
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
         
          v-bind="attrs"
          :disabled="shipments.length === 0 || !can(PERMISSIONS.ORDERS)"
          v-on="on"
        >
          Print
          <v-icon right>
            mdi-chevron-up
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          :disabled="shipments.length === 0 || !can(PERMISSIONS.ORDERS) || !isStoreOrders(shipments)"
          @click="show_product_pick_list = true"
        >
          <v-list-item-icon>
            <v-icon
              small
              dense
              left
            >
              mdi-select-group
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Pick List</v-list-item-title>
        </v-list-item>
      
        <v-list-item
          :disabled="shipments.length === 0 || !can(PERMISSIONS.ORDERS)"
          @click="buildPrinter('label_only')"
        >
          <v-list-item-icon>
            <v-icon
              small
              dense
              left
            >
              mdi-label
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shipment Label</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="shipments.length === 0 || !can(PERMISSIONS.ORDERS)"
          @click="show_packing = true"
        >
          <v-list-item-icon>
            <v-icon
              small
              dense
              left
            >
              mdi-package-variant-closed
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Packing Slip</v-list-item-title>
        </v-list-item>
        <v-list-item
          :disabled="shipments.length === 0 || !can(PERMISSIONS.ORDERS)"
          @click="buildPrinter('label_with_packing_slip')"
        >
          <v-list-item-icon>
            <v-icon
              small
              dense
              left
            >
              mdi-package-variant
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Packing Slip With Label</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!--isPrimaryAccountHolder from mixin.js-->
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
        
          color="primary"
          v-bind="attrs"
          :disabled="shipments.length === 0 || !can(PERMISSIONS.SHIPPING)"
          v-on="on"
        >
          Batch
          <v-icon right>
            mdi-chevron-up
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          :disabled="!canEditBatch || !can(PERMISSIONS.SHIPPING)"
          @click="show_batch = true"
        >
          <v-list-item-icon>
            <v-icon>mdi-plus-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Add to Batch</v-list-item-title>
        </v-list-item>

        <v-list-item
          :disabled="!canEditBatch || !can(PERMISSIONS.SHIPPING)"
          @click="batchRemove"
        >
          <v-list-item-icon>
            <v-icon>mdi-minus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Remove From Batch</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    

    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
         
          color="primary"
          v-bind="attrs"
          :disabled="shipments.length === 0"
          v-on="on"
        >
          Other
          <v-icon right>
            mdi-chevron-up
          </v-icon>
        </v-btn>
      </template>
      <v-list>
        <!--can from mixin.js-->
        <v-list-item
          :disabled="shipments.length === 0 || !can(PERMISSIONS.SHIPPING)"
          @click="show_void = true"
        >
          <v-list-item-icon>
            <v-icon>mdi-select-off</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Request Void</v-list-item-title>
        </v-list-item>

        <!--isPrimaryAccountHolder from mixin.js-->
        <v-list-item
          :disabled="shipments.length === 0 || !can(PERMISSIONS.SHIPPING)"
          @click="show_duplicate = true"
        >
          <v-list-item-icon>
            <v-icon>mdi-content-copy</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Duplicate</v-list-item-title>
        </v-list-item>

        <v-list-item
          :disabled="!allowReturnLabel || !can(PERMISSIONS.SHIPPING)"
          @click="openQuickShipFormDialog"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-return</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Return Label</v-list-item-title>
        </v-list-item>

        <!--isPrimaryAccountHolder from mixin.js-->

        <!--isPrimaryAccountHolder from mixin.js-->
        <v-list-item
          :disabled="!archiveable(shipments) || !can(PERMISSIONS.SHIPPING)"
          @click="show_archived = true"
        >
          <v-list-item-icon>
            <v-icon>mdi-archive-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title> Archive</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <quick-ship-form-dialog
      ref="quickShipFormDialog"
      v-model="showQuickShipFormDialog"
      :tax-identifiers="$store.getters['main/tax_identifiers']"
      :update-shipment-handler="emitUpdate"
    />
    <void-shipments
      v-model="show_void"
      :shipments="shipments"
      :update-event="emitUpdate"
    />
    <archive-shipments
      v-model="show_archived"
      :shipments="shipments"
      @update="emitUpdate()"
    />
    <duplicate-shipments
      v-model="show_duplicate"
      :shipments="shipments"
      :update-shipment-handler="emitUpdate"
      @update="emitUpdate()"
    />
    <create-labels
      ref="refCreateLabels"
      v-model="show_create_labels"
      @update="emitUpdate()"
    />
    <batch-add
      v-model="show_batch"
      :shipments="shipments"
      @update="emitUpdate()"
    />
    <print-product-pick-list
      v-model="show_product_pick_list"
      :items="shipments"
      :type="'shipments'"
      @update="emitUpdate()"
    />
    <print-shipments
      v-model="show_print"
      :shipments="shipments"
      :print-type="printType"
      @update="emitUpdate()"
    />

    <print-packing-slips
      v-model="show_packing"
      :items="shipments"
      :type="'shipments'"
      @update="emitUpdate()"
    />
  </div>
</template>

<script>
import BatchAdd from './BatchAdd.vue';
// import BatchRemove from './BatchRemove';
import CreateLabels from './CreateLabels/MainDialog.vue';
import ArchiveShipments from './ArchiveShipments.vue';
import DuplicateShipments from './DuplicateShipments.vue';
import PrintShipments from './PrintShipments.vue';
import PERMISSIONS from '@/constants/client-permissions';
import PrintPackingSlips from './PrintPackingSlips.vue';
import PrintProductPickList from './PrintProductPickList.vue';
import QuickShipFormDialog from '../../shipments/quick-ship/QuickShipFormDialog.vue';
export default {
  components: {
    BatchAdd,
    // BatchRemove,
    CreateLabels,
    ArchiveShipments,
    DuplicateShipments,
    PrintShipments,
    PrintPackingSlips,
    PrintProductPickList,
    QuickShipFormDialog
  },
  props: {
    shipments: {
      type: Array,
      default: () => []
    },
    hideButtons: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      PERMISSIONS: PERMISSIONS,
      show_void: false,
      show_archived: false,
      show_batch: false,
      show_print: false,
      show_duplicate: false,
      printType: 'label_only',
      show_packing: false,
      show_product_pick_list: false,
      // return_label_dialog: false,
      showQuickShipFormDialog: false,
      show_create_labels: false
    };
  },
  computed: {
    canEditBatch() {
      const ids = this.$store.getters['batches/openBatchIds'];

      if (
        (this.shipments.length != 0 &&
          this.shipments.every((r) => [2, 3, 4].includes(r.status_id))) ||
        this.shipments.every((r) => ids.includes(r.batch_id))
      ) {
        return true;
      } else {
        return false;
      }
    },
    voidable() {
      return this.shipments.length > 0;
    },
    allowReturnLabel() {
      return this.shipments.length > 0 && this.shipments.every((r) => this.returnHasValidPostage(r));

      // return !(
      //   this.shipments.length === 1 &&
      //   this.returnHasValidPostage(this.shipments[0])
      // );
    }
  },

  methods: {
    openCreateLabelsDialogFromShipments() {
      if (this.shipments.length == 0) {
        return
      }

      this.$refs.refCreateLabels.openFromShipments(this.shipments);
    },
    openQuickShipFormDialog() {
      if (this.shipments.length == 0) {
        return
      }

      this.$refs.quickShipFormDialog.startReturn(this.shipments);
    },
    emitUpdate() {
      this.$emit('update');
    },
    batchRemove: async function () {
      this.archive_loading = true;

      const ids = this.shipments.map((a) => a.id);

      this.$http
        .post(`/shipments/batch-remove`, {
          ids
        })
        .then(() => {
          this.$emit('update');
        })
        .catch(() => {
          this.errorMessage(
            'Could not remove the selected shipments from the batch'
          );
        })
        .finally(() => {
          this.archive_loading = false;
          this.dialog = null;
          this.number = 0;
        });
    },
    buildPrinter(type) {
      this.printType = type;
      this.show_print = true;
    },
    returnHasValidPostage(shipment) {
      if (shipment) {
        if (shipment.country_code == 'CA') {
          return true;
        } else if (shipment.country_code == 'US') {
          return true;
        } else {
          return false;
        }
      }
    }
  }
};
</script>
