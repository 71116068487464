<template>
  <div>
    <v-container
      grid-list-md
      mb-12
    >
      <v-layout wrap>
        <v-flex xs12>
          <h1>
            <span>Outbound Trip {{ tripNumber }}</span>
          </h1>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs12>
          <v-card>
            <v-card-text v-if="trip">
              <v-layout>
                <!--  -->
                <v-flex
                  xs12
                  class="d-flex flex-row justify-space-between"
                >
                  <div>
                    <h2># Shipments</h2>
                    <span v-if="trip.shipment_count">
                      {{ trip.shipment_count }}</span>
                    <span v-else>N/A</span>
                  </div>

                  <div v-if="auto_refresh === true">
                    Auto Refresh <v-icon>mdi-loading mdi-spin </v-icon>
                  </div>
                </v-flex>
                <v-flex
                  v-if="trip.created_at"
                  xs12
                >
                  <h2>Created At</h2>
                  {{ trip.created_at | datetime_pretty }}
                </v-flex>
                <v-flex xs12>
                  <h2>Status</h2>
                  <span v-if="trip.valid == null">Processing</span>
                  <span v-else-if="trip.valid == 0">Error</span>
                  <span v-else-if="trip.valid == 1">Complete</span>
                </v-flex>
                <v-flex xs12>
                  <h2>Input File</h2>

                  <a
                    href="javascript:void(0);"
                    @click="download(trip.input_file)"
                  >{{ trip.input_file | file_download }}</a>
                </v-flex>
                <v-flex
                  v-if="trip.output_files"
                  xs12
                >
                  <h2>Output Files</h2>
                  <div
                    v-for="file in trip.output_files"
                    :key="file"
                  >
                    <a
                      href="javascript:void(0);"
                      @click="download(file)"
                    >{{
                      file | file_download
                    }}</a>
                  </div>
                </v-flex>

                <v-flex
                  v-if="trip.errors"
                  xs12
                >
                  <h2>Errors</h2>
                  <div
                    v-for="error in trip.errors"
                    :key="error"
                  >
                    {{ error }}
                  </div>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    tripNumber: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      trip: null,
      auto_refresh: false
    };
  },
  mounted() {
    this.getTrip();
  },

  methods: {
    getTrip() {
      this.$http
        .get(`/admin/outbound-trips/${this.tripNumber}`)
        .then((response) => response.data)
        .then((response) => {
          this.trip = response;
          console.log(this.trip.valid);
          if (this.trip.valid === null) {
            this.auto_refresh = true;
            var self = this;
            setTimeout(function () {
              self.getTrip();
            }, 5000);
          } else {
            this.auto_refresh = false;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
    download: function (data) {
      this.$http
        .request('/admin/outbound-trips/download', {
          method: 'post',
          data: { file: data }
        })
        .then((response) => {
          this.downloadFileUrl(response.data,data, 'text/csv');
        });
    }
  }
};
</script>
