<template>
  <div>
    <transition
      name="component-fade"
      mode="out-in"
    >
      <v-card
        class="mb-5 pa-8"
        outlined
      >
        <v-row>
          <v-col>
            <h3>Filter Import</h3>
            <p>Use a filter to import shipments and buy postage.</p>
          </v-col>
          <v-col cols="auto">
            <v-btn
              :disabled="filters.filters.length === 0"
              color="primary"
              outlined
              @click="getOrders()"
            >
              <v-icon small>
                mdi-upload
              </v-icon>Import All
            </v-btn>

            <v-btn
              color="primary"
              :disabled="!can(PERMISSIONS.AUTOMATION)"
              @click="$refs.filterDialog.startNew()"
            >
              <v-icon small>
                mdi-plus
              </v-icon>Create Filter
            </v-btn>
          </v-col>
        </v-row>

        <!-- v-if="filters.length > 0" //above-->

        <v-row v-if="filters.loading">
          <v-col justify-center>
            <v-progress-linear
              indeterminate
              color="#f9ac40"
            />
          </v-col>
        </v-row>

        <v-list
          two-line
        >
          <transition-group
            name="component-fade"
            mode="out-in"
          >
            <template v-for="(filter, index) in filters.filters">
              <v-list-item
                :key="index"
                ripple
                @click="getOrders(filter)"
              >
                <!-- <v-list-item-avatar> -->
                <!-- <v-icon v-if="item.store.key === 'ebay'">
                  fab fa-ebay
                </v-icon>
                <v-icon v-if="item.store.key === 'amazon'">
                  mdi-amazon
                </v-icon>
                <v-icon v-if="item.store.key === 'shopify'">
                  mdi-shopify
                </v-icon>
                <v-icon v-if="item.store.key === 'etsy'">
                  mdi-etsy
                </v-icon>
                <v-icon
                  v-if="item.store.key === 'shipstation'"
                >
                  mdi-cogs
                </v-icon> -->
                <!-- </v-list-item-avatar> -->

                <v-list-item-content>
                  <v-list-item-title v-html="filter.name" />
                  <v-list-item-subtitle v-if="filter.updated_at != null">
                    Updated:
                    {{ filter.updated_at | datetime_pretty }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle v-if="filter.unfulfilled_count != null">
                    {{ filter.unfulfilled_count }} Unfulfilled Orders
                  </v-list-item-subtitle>
                  <!-- <v-list-item-subtitle>
                  {{ item.store.description }}
                </v-list-item-subtitle> -->
                  <!-- <v-list-item-subtitle v-if="item.synced_at != null">
                  Last Synced: {{ item.synced_at | datetime_pretty  }}
                </v-list-item-subtitle>
                  <v-list-item-subtitle v-else>
                  Not synced yet
                </v-list-item-subtitle> -->
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    icon
                    @click.stop="$refs.filterDialog.startEdit(filter)"
                  >
                    <v-icon
                      small
                      color="grey darken-1"
                    >
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click.stop="deleteFilter(filter.id)"
                  >
                    <v-icon
                      small
                      color="grey darken-1"
                    >
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider
                v-if="index + 1 < filters.length"
                :key="index"
                class="ma-0"
              />
            </template>
          </transition-group>
        </v-list>
      </v-card>
    </transition>
    <filter-dialog ref="filterDialog" />
  </div>
</template>

<script>
import FilterDialog from '../filters/FilterDialog.vue';
import { mapState } from 'vuex';
import PERMISSIONS from '@/constants/client-permissions';
export default {
  components: {
    FilterDialog
  },
  data() {
    return {
      PERMISSIONS: PERMISSIONS
    };
  },
  computed: {
    ...mapState(['imp', 'filters'])
  },
  watch: {},
  mounted() {
    if (this.filters.filters.length === 0) {
      this.$store.dispatch('filters/getFilters');
    }
  },
  methods: {
    async deleteFilter(id) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this filter?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        try {
          await this.$store.dispatch('filters/deleteFilter', id);
        } catch (e) {
          console.log(e);
        }
      }
    },
    showUpdate(filter) {
      this.show_edit = true;
      this.filter = filter;
    },
    showCreate() {
      this.show_edit = true;
      this.filter = null;
    },
    getOrders(filter) {
      let filterIds = [];
      let msg = '';
      let filterName = '';

      if (filter) {
        filterIds = [filter.id];
        filterName = filter.name;
        msg = `Importing orders from filter: ${filter.name}`;
      } else {
        filterIds = this.filters.filters.map((f) => f.id);
        filterName = 'All Filters';
        msg = `Importing orders from all filters`;
      }

      this.$store.commit('imp/loaderShow', msg);
      this.loading = true;
      return new Promise(() => {
        this.$http
          .request(`/orders/filter`, {
            method: 'post',
            data: {
              filter_id: filterIds
            }
          })
          .then((response) => response.data)
          .then((response) => {
            if (response.length === 0) {
              this.$notify({
                group: 'main',
                title: 'Nothing to Import',
                text: `No shipments found for ${filterName}`,
                duration: 5000,
                type: 'error'
              });

              return;
            }

            this.imp.selection.filters.filter_id = filterIds;

            const payload = {
              shipments: response,
              select_all: false,
              filters: this.imp.selection.filters,
              type: 'store'
            };

            this.$store.commit('imp/selectionStart', payload);
          })
          .finally(() => {
            this.imp.file = null;
            this.$store.commit('imp/loaderHide');
          });
      });
    }
  }
};
</script>
