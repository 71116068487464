<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    persistent
    title="Update Order Status"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
        >
          <div class="dialog-info">
            Change the status of {{ orders.length }} order
          </div>
        </v-flex>
        <v-flex
          xs12
          text-center
          mt-4
          mb-12
        >
          <v-select
            v-model="status"
            :items="statuses"
          />
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :loading="update_loading"
        :disabled="update_loading"
        @click="updateStatuses()"
      >
        Update
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    orders: {
      type: Array,
      default: () => []
    },

    disabled: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      update_loading: false,
      statuses: [],
      status: null,
      show: null
    };
  },
  mounted() {
    this.getOrderStatuses();
  },
  methods: {
    openDialog() {
      this.show = true;
    },
    getOrderStatuses() {
      this.$http
        .get(`/order-statuses`)
        .then(response => response.data)
        .then(response => {
          this.statuses = response
        });
    },
    updateStatuses: function() {
      this.update_loading = true;

      var ids = [];
      this.orders.forEach(function(shipment) {
        ids.push(shipment.id);
      });
      this.$http
        .post('orders/update-statuses', {
          ids: ids,
          status: this.status
        })
        .then((response) => {
          this.$emit('update')
          this.successMessage(response.data.message || 'Order Status Updated')
        })
        .finally(() => {
          this.update_loading = false;
          this.show = false;
        });
    }
  }
};
</script>
