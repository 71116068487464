<template>
  <div>
    <!-- Blog -->
    <v-card
      outlined
      :loading="loading"
      style="height:460.8px; overflow-y: scroll;"
    >
      <v-card-title>
        <h4>
          <a
            href="https://stallionexpress.ca/blog/"
            target="_blank"
            style="text-decoration: none; color: rgba(0, 0, 0, 0.87)"
          >Stallion Express Blog</a>
        </h4>
      </v-card-title>

      <v-card-text>
        <v-container
          v-for="post in posts"
          :key="post.id"
          class="px-0"
        >
          <p
            class="font-weight-bold"
            v-html="post.title.rendered"
          />
          <v-card-subtitle
            class="pa-0 pt-1 font-weight-regular"
            style="font-size: 13px"
          >
            {{ post.date | datetime_pretty }} |
            <a
              v-if="post.cat.link"
              :href="post.cat.link"
              target="_blank"
              v-html="post.cat.name"
            />
          </v-card-subtitle>

          <v-btn
            :ripple="false"
            text
            small
            color="primary"
            class="no-background-hover px-0"
            target="_blank"
            :href="post.link"
          >
            Read More
            <v-icon
              small
              right
            >
              mdi-arrow-right
            </v-icon>
          </v-btn>
          <!-- <v-divider class="mb-0"></v-divider> -->
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      posts: [],
      categories: [],
      loading: false
    };
  },
  mounted() {
    this.getBlogPosts();
  },

  methods: {
    getBlogPosts: function () {
      this.loading = true;

      this.$http
        .get(`blog`, {
          method: 'get'
        })
        .then((response) => response.data)
        .then((response) => {
          this.posts = response;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px;
}

.no-background-hover::before {
  background-color: transparent !important;
}

h4 {
  margin-bottom: 0px !important;
}
</style>
