<template>
  <div>
    <v-row>
      <v-col>
        <p>Connect a USB weight scale to automatically enter shipment weight where applicable.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SetupQz v-if="setup" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-switch
          v-model="easyScale"
          label="Enable Weight Scale"
          @change="changeEasyScale"
        />
      </v-col>
      <v-col>
        <v-switch
          v-model="enabledAutoWeightFetch"
          label="Enable Auto Weight Fetch"
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <h4>Supported Scales</h4>
        <v-row>
          <v-col cols="9">
            <v-select
              v-model="selectedScale"
              :items="scales"
              item-text="product"
              label="Select a usb Scale"
              :disabled="disableEasy"
              return-object
              outlined
            />
          </v-col>
          <v-col cols="3">
            <v-btn
              outlined
              color="primary"
              large
              :disabled="disableEasy"
              @click="refreshScales"
            >
              <v-icon left>
                mdi-refresh
              </v-icon>

              Refresh
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          color="primary"
          :disabled="disableEasy"
          @click="submit"
        >
          Update
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SetupQz from "./SetupQz.vue";

export default {
  name: "ScaleSettings",
  components: { SetupQz },
  data() {
    return {
      scales: [],
      selectedScale: '',
      easyScale: false,
      enabledAutoWeightFetch: false,
      disableEasy: true,
      setup: !this.easyScale,
    }
  },

  watch: {
    easyScale() {
      this.setup = !this.easyScale
    }
  },

  async mounted() {
    await this.initialiseQz();
    if (this.isScaleInitialised()) {
      await this.refreshScales();
      this.easyScale = true;
      this.disableEasy = false;
      this.selectedScale = this.$store.state.qz.currentScale;
      this.enabledAutoWeightFetch = this.$store.state.qz.enabledAutoWeightFetch;
    }
  },
  methods: {
    async submit() {
      if (typeof this.selectedScale !== "string") {
        this.selectedScale.enabledAutoWeightFetch = this.enabledAutoWeightFetch
        localStorage.scale = JSON.stringify(this.selectedScale);
        this.successMessage("Scale Saved");
      }
    },

    async refreshScales() {
      if(this.isQzInitialised()){
        this.scales = await this.getConnectedScales();
        this.$notify({
          group: 'main',
          title: 'Scales Refreshed',
          text: 'Scale list has been successfully updated',
          duration: 5000,
          type: 'success'
        });
      } else {
        this.$notify({
          group: 'main',
          title: 'Scales Refresh Error',
          text: 'Scale list cannot be fetched',
          duration: 5000,
          type: 'success'
        });
      }

    },

    async changeEasyScale() {
      await this.refreshScales();
      this.disableEasy = !this.easyScale;
      localStorage.removeItem('scale');
      if (this.disableEasy) {
        this.successMessage("Easy Scale Disabled");
      }
    },
  }
}
</script>

<style scoped>

</style>