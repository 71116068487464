<template>
  <StallionDialog
    v-model="show"
    :width="600"
    persistent
    title="Shipment Confirmed"
    @onDialogClosed="close()"
  >
    <template
      v-if="shipment"
      #content
    >
      <v-layout wrap>
        <v-flex
          xs12
          class="mb-4 text-center"
        >
          <h4>Thank you, your shipment is confirmed.</h4>
          <br>
        </v-flex>

        <v-flex
          xs12
          class="mb-4 text-center"
        >
          <p style="font-size: 1.2rem">
            <strong>Shipment Code:</strong>
            {{ shipment.ship_code }}
          </p>
        </v-flex>

        <v-flex
          v-if="shipment.postage_type_id == 23"
          xs12
          class="mb-4 text-center"
        >
          <p style="font-size: 1.2rem">
            <strong>Tracking Code:</strong> Not yet available
          </p>
        </v-flex>
        <v-flex
          v-else-if="shipment.tracking_code"
          xs12
          class="mb-4 text-center"
        >
          <p style="font-size: 1.2rem">
            <strong>Tracking Code:</strong>
            {{ shipment.tracking_code }}
          </p>
        </v-flex>

        <v-layout
          align-center
          class="mt-4 mb-4"
        >
          <v-flex
            xs12
            text-center
          >
            <v-btn
              v-if="is_printable(shipment)"
              color="primary"
              @click="showPrint = true"
            >
              <v-icon
                small
                dense
                left
              >
                mdi-printer
              </v-icon>
              Print
            </v-btn>

            <print
              ref="print"
              v-model="showPrint"
              :shipments="[shipment]"
              @onDialogClosed="close"
            />
          </v-flex>
        </v-layout>
      </v-layout>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true
    },
    shipment: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      showPrint: false
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    close() {
      this.show = null;
    }
  }
};
</script>
