<template>
  <div>
    <!--isPrimaryAccountHolder from mixin.js-->
    <v-row dense>
      <v-col />
      <v-col cols="auto">
        <v-btn
          small
          :disabled="selected.length == 0"
          color="primary"
          depressed
          outlined
          @click="bulkDelete()"
        >
          Delete
        </v-btn>
        <v-divider
          vertical
          class="ml-2 mr-1 mb-n1"
        />
        <v-btn
          color="primary"
          depressed
          small
          outlined
          @click="exportProducts"
        >
          Export
        </v-btn>
        <v-btn
          color="primary"
          depressed
          small
          outlined
          @click="$refs.productImportDialog.startNew()"
        >
          Import
        </v-btn>

        <v-btn
          color="primary"
          depressed
          small
          outlined
          @click="$refs.productDialog.startNew()"
        >
          New Product
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-form @submit.prevent>
          <v-container>
            <v-row
              class="pb-2"
              style="justify-content: space-between"
            >
              <v-text-field
                v-model="search"
                class="mr-2"
                label="Search"
                prepend-inner-icon="search"
                outlined
                clearable
                dense
                hide-details
                data-lpignore="true"
                @input="submitSearch()"
              />
            </v-row>
          </v-container>
        </v-form>
      </v-col>
      <v-col cols="12">
        <v-data-table
          v-model="selected"
          item-key="id"
          class="table-striped"
          :headers="headers"
          :server-items-length="totalItems"
          :items="items"
          show-select
          :loading="loading"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [25, 50, 100, 200]
          }"
          @item-selected="bulkSelect"
        >
          <template #item.created_at="{ item }">
            {{ item.created_at | date_pretty }}
            <br>
            ({{ item.created_at | datetime_from }})
          </template>

          <template #item.weight="{ item }">
            <span
              v-if="item.weight > 0"
            >{{ item.weight }}{{ item.weight_unit }}</span>
          </template>

          <template #item.dims="" />

          <template #item.override_weight="{ item }">
            <v-icon v-if="item.override_weight">
              mdi-check-circle-outline
            </v-icon>
            <v-icon v-else>
              mdi-close
            </v-icon>
          </template>
          <template #item.override_name="{ item }">
            <v-icon v-if="item.override_name">
              mdi-check-circle-outline
            </v-icon>
            <v-icon v-else>
              mdi-close
            </v-icon>
          </template>
          <template #item.override_value="{ item }">
            <v-icon v-if="item.override_value">
              mdi-check-circle-outline
            </v-icon>
            <v-icon v-else>
              mdi-close
            </v-icon>
          </template>
          <template #item.actions="{ item }">
            <v-btn
              small
              color="red"
              text
              @click="deleteFilter(item.id)"
            >
              Delete
            </v-btn>
            <v-btn
              small
              color="primary"
              text
              @click="$refs.productDialog.startEdit(item)"
            >
              Edit
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <new-product-dialog
      ref="productDialog"
      @update="updateProduct"
      @add="updateTable"
    />
    <product-upload-dialog
      ref="productImportDialog"
      @update-products="updateTable()"
    />
  </div>
</template>
<script>
import NewProductDialog from './NewProductDialog.vue';
import ProductUploadDialog from './ProductUploadDialog.vue';
export default {
  components: {
    NewProductDialog,
    ProductUploadDialog
  },
  data() {
    return {
      product: {},
      show_edit: false,
      totalItems: 0,
      loading: false,
      items: [],
      search: null,
      selected: [],

      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },

      headers: [
        { text: 'SKU', value: 'sku' },
        { text: 'Name', value: 'name' },
        { text: 'Weight', value: 'weight' },
        { text: 'Override Weight', value: 'override_weight' },
        { text: 'Override Name', value: 'override_name' },
        { text: 'Override Value', value: 'override_value' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            products: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },
  mounted() {
    this.updateTable();

    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.products ?? 25;

    this.selected = [];
  },
  methods: {
    updateProduct(product) {
      const index = this.items.findIndex((x) => x.id === product.id);
      this.$set(this.items, index, product);
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.options.page = 1;
      this.timer = setTimeout(function () {
        self.updateTable();
      }, ms);
    },
    updateTable() {
      this.selected = [];
      this.totalItems = 0;
      this.items = [];

      this.loading = true;
      this.getDataFromApi()
        .then((data) => {
          this.items = data.items;
          this.totalItems = data.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDataFromApi() {
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = [];
        let total = 0;
        let pages = 0;

        console.log(this.options);
        var params = {
          page: page,
          per_page: itemsPerPage,
          sort_by: sortBy[0],
          desc: sortDesc[0],
          search: this.search
        };

        if (this.cancelSource) {
          this.cancelSource.cancel('Start new search, stop active search');
        }
        this.cancelSource = axios.CancelToken.source();

        this.$http
          .request(`/products`, {
            method: 'get',
            params: params,
            cancelToken: this.cancelSource.token
          })
          .then((response) => response.data)
          .then((response) => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            resolve({
              items,
              total,
              pages
            });
          });
      });
    },
    async deleteFilter(id) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this product?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        try {
          await axios.request(`/products/${id}`, {
            method: 'delete'
          });
          this.updateTable();
        } catch (err) {
          console.log(err);
        }
      }
    },
    async bulkDelete() {
      let productsCount = this.selected.length;
      const isConfirm = await swal({
        title: 'Confirm Bulk Delete',
        text: `Do you really want to delete ${productsCount} product(s)?`,
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        try {
          await axios.post(`/products/delete-multiple`, {
            ids: this.selected.map(item => item.id)
          });
          this.updateTable();
        } catch (err) {
          console.log(err);
        }
      }
    },
    showUpdate(product) {
      this.show_edit = true;
      this.product = product;
    },
    showCreate() {
      this.show_edit = true;
      this.product = null;
    },
    exportProducts() {
      this.isExporting = true;

      var params = {
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
        needs_postage: this.needs_postage,
        country_code: this.country_code,
        pending: this.pending,
        status: this.filter,
        package_type_id: this.packageType,
        postage_type_id: this.postageType,
        status_id: this.status_id,
        closeout_id: this.closeout_id,
        batch_id: this.batch_id,
        batched: this.batched,
        error: this.error,
        return_label: this.return_label
      };

      this.$http
        .get('products/export', {
          params: params
        })
        .then((response) => {
          this.downloadFileUrl(response.data,`Stallion_Products_Export.csv`, 'text/csv');
        })
        .catch((err) => {
          if (err.response.status === 403) {
            this.errorMessage('This action is unauthorized.');
          } else {
            this.errorMessage('Nothing to export');
          }
        })
        .finally(() => {
          this.isExporting = false;
        });
    },
    bulkSelect({ item: b, value }) {
      console.log(value);
      const { currentSelect, shiftKeyOn } = this;

      if (this.selected && this.selected.length == 1 && value == true && shiftKeyOn) {
        let [a] = this.selected;

        if (this.lastSelect !== null) {
          a = this.lastSelect;
        }

        let start = currentSelect.findIndex((item) => item == a);
        let end = currentSelect.findIndex((item) => item == b);

        if (start - end > 0) {
          let temp = start;
          start = end;
          end = temp;
        }
        for (let i = start; i <= end; i++) {
          this.selected.push(currentSelect[i]);
        }
      }

      this.lastSelect = b;
    },
  }
};
</script>
