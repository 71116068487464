<template>
  <div>
    <template v-if="canEdit && showVerifySection">
      <template v-if="!shipment.items_verified">
        <v-btn
          v-if="shipment"
          color="primary"
          :disabled="verifiableCount > 0 || loading"
          @click="markAsVerified"
        >
          Mark as Verified ({{ verifiableCount }})
        </v-btn>
      </template>

      <template v-else>
        <span>
          <v-icon color="green">
            mdi-check-circle
          </v-icon>
          <strong> Verified </strong>
        </span>

        <v-btn
          color="primary"
          :disabled="loading"
          @click="enableVerification"
        >
          Edit Verification
        </v-btn>
      </template>
    </template>

    <template v-if="shipment">
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
     
            v-bind="attrs"
            :disabled="disablePrint"
            v-on="on"
          >
            Print
            <v-icon right>
              mdi-chevron-up
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            :disabled="disablePrint || !isStoreOrders(shipments)"
            @click="show_product_pick_list = true"
          >
            <v-list-item-icon>
              <v-icon
                small
                dense
                left
              >
                mdi-select-group
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pick List</v-list-item-title>
          </v-list-item>
  
          <v-list-item
            :disabled="disablePrint"
            @click="buildPrinter('label_only')"
          >
            <v-list-item-icon>
              <v-icon
                small
                dense
                left
              >
                mdi-label
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Shipment Label</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="disablePrint"
            @click="show_packing = true"
          >
            <v-list-item-icon>
              <v-icon
                small
                dense
                left
              >
                mdi-package-variant-closed
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Packing Slip</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="disablePrint"
            @click="buildPrinter('label_with_packing_slip')"
          >
            <v-list-item-icon>
              <v-icon
                small
                dense
                left
              >
                mdi-package-variant
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Packing Slip With Label</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <print-product-pick-list
      v-model="show_product_pick_list"
      :items="shipments"
      :type="'shipments'"
      @update="emitUpdate()"
    />
    <print-shipments
      v-model="show_print"
      :shipments="shipments"
      :print-type="printType"
      @update="emitUpdate()"
    />
  </div>
</template>

<script>
import PERMISSIONS from '@/constants/client-permissions';
import SCAN_CONSTANTS from '@/constants/scan';
import PrintShipments from '@/components/main/shipments/action-bar/PrintShipments.vue';
import PrintProductPickList from '@/components/main/shipments/action-bar//PrintProductPickList.vue';
export default {
  components: {
    PrintShipments,
    PrintProductPickList
  },
  props: {
    scanType: {
      type: String,
      default: SCAN_CONSTANTS.SCAN_TO_PRINT
    },
    shipments: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      PERMISSIONS: PERMISSIONS,
      show_print: false,
      printType: 'label_only',
      show_packing: false,
      show_product_pick_list: false,
    };
  },
  computed: {
    showVerifySection() {
      return SCAN_CONSTANTS.VERIFY_SCAN_WORKFLOW.includes(this.scanType);
    },
    shipment() {
      return this.shipments[0];
    },
    disablePrint() {
      return this.shipments.length === 0 || !this.can(PERMISSIONS.ORDERS) || this.loading;
    },
    canEdit() {
      // 3 = INCOMPLETE
      return this.shipment && this.shipment.status_id == 3 && this.can('CLIENT.SHIPPING')
    },
    verifiableCount() {
      if (!this.shipment || !this.shipment.items) {
        return 0
      }

      return this.shipment.items.filter(item => item.quantity_verified < item.quantity)
        .map(item => item.quantity - item.quantity_verified)
        .reduce((a, b) => a + b, 0)
    }
  },

  methods: {
    async markAsVerified() {
      try {
        await this.$http.post('/shipment-verification/' + this.shipment.id + '/shipment', {
          items_verified: 1
        })

        this.$notify({
          group: 'main',
          title: 'Verified!',
          text: 'Items have been marked as verified.',
          duration: 3000,
          type: 'success'
        });

        // event registered @shipments-scan-to-verify/view/index.vue
        Event.fire('mark-as-verified', this.shipment);
      } catch (err) {
        this.$notify({
          group: 'main',
          title: 'Error!',
          text: 'An error occurred while marking items as verified.',
          duration: 3000,
          type: 'error'
        });
      }
    },
    async enableVerification() {
      try {
        await this.$http.post('/shipment-verification/' + this.shipment.id + '/shipment', {
          items_verified: 0
        })

        // event registered @shipments-scan-to-verify/view/index.vue
        Event.fire('enable-verification', this.shipment);
      } catch (err) {
        this.$notify({
          group: 'main',
          title: 'Error!',
          text: 'An error occurred while enabling verification.',
          duration: 3000,
          type: 'error'
        });
      }
    },
    emitUpdate() {
      this.$emit('update');
    },
    buildPrinter(type) {
      this.printType = type;
      this.show_print = true;
    }
  }
};
</script>
