<template>
  <v-main>
    <v-row
      v-if="$auth.user"
      container--fluid
      fill-height
      style="overflow-y: scroll"
    >
      <v-col
        cols="12"
        md="4"
        class="image-column"
        style="background-color: #f9ac40; height: 100vh"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="px-10 mb-12"
            >
              <img
                class="ml-0 img-responsive"
                :src="asset('images/StallionExpress-logo-white.png')"
                style="width: 150px;"
              >
            </v-col>
            <v-col
              cols="12"
              class="px-10 py-0"
              style="text-align: left"
            >
              <h1
                class="font-weight-bold"
                style="color: white; font-size: 28px"
              >
                Ship Faster, Smarter, and Cheaper with Stallion!
              </h1>
            </v-col>
            <v-col
              cols="12"
              class="px-10 py-0"
            >
              <img
                class="img-responsive login-image"
                :src="asset('images/login-graphic.png')"
                style="width: 310px;"
              >
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col
        cols="12"
        md="8"
        class="pa-0"
        style="height: 100vh"
      >
        <v-card class="elevation-0 pa-5 px-12">
          <v-container class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="text-right"
              >
                <div>
                  <p>
                    Need Help?
                    <a
                      style="font-weight: 700; text-decoration: none"
                      target="_blank"
                      @click="toggleBeacon()"
                    >Contact Us</a>
                  </p>
                </div>
              </v-col>
              <v-col
                cols="12"
                class="pb-12 text-right"
              >
                <div class="float-right">
                  <weglot-switcher />
                </div>
              </v-col>

              <v-col cols="12">
                <h2 class="font-weight-bold">
                  Terms and Conditions
                </h2>
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row>
              <v-col cols="12">
                Please read these Terms and Conditions carefully before
                proceeding to use Stallion Express.
              </v-col>
              <v-col cols="12">
                By using this website, you accept the following
                <a
                  href="https://stallionexpress.ca/terms-of-use/"
                  target="_blank"
                >Terms of Use
                </a>
                and
                <a
                  href="https://stallionexpress.ca/privacy/"
                  target="_blank"
                >
                  Privacy Policy.</a>
              </v-col>
              <v-col cols="12">
                Stallion Express values our carrier partners. You agree and
                understand that the UPS service cannot be provided if you have
                an active account with them already. You hereby verify that you
                do not have an active account with UPS.
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="$auth.user.ups_active"
                  v-validate="'required'"
                  :items="[
                    {
                      text: 'Yes',
                      value: 0
                    },
                    {
                      text: 'No',
                      value: 1
                    }
                  ]"
                  label="Do you currently have an active UPS account?"
                  outlined
                  :error-messages="errs.collect('ups_account')"
                  data-vv-name="ups_account"
                  dusk="ups_account"
                />
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="$auth.user.terms_agree"
                  label="I agree to the terms and conditions"
                  dusk="agree"
                />
              </v-col>

              <v-col
                cols="12"
                class="py-0 d-flex flex-row"
              >
                <v-btn
                  color="primary"
                  class="px-6"
                  :disabled="!$auth.user.terms_agree"
                  name="submit"
                  @click="submit"
                >
                  Submit
                </v-btn>
                <div class="py-2 px-6">
                  <p>
                    Or
                    <a
                      style="font-weight: 700; text-decoration: none"
                      @click.prevent="logout()"
                    >Log me out</a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      checkbox: false,
      ups_active: true
    };
  },
  methods: {
    submit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.loading = true;

          this.$http
            .post('/auth/terms-and-conditions', this.$auth.user)
            .then((res) => {
              if (res.data.success) {
                this.getUser().then(() => {
                  this.$router.push('/shipments');
                });
              } else {
                swal('Error!', res.data.message);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px !important;
}

.v-stepper__step {
  padding-top: 0px;
  padding-bottom: 0px;
}

.v-input--selection-controls {
  margin-top: 0px !important;
}

@media only screen and (min-width: 1440px) {
  .login-image {
    width: 400px !important;
  }

  .stepper-style {
    margin-bottom: 100px !important;
    width: 40% !important;
  }

  .stallion-title {
    margin-bottom: 50px !important;
  }

  .stepper-container {
    width: 70% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .login-image {
    width: 260px !important;
  }
}

@media only screen and (max-width: 1550px) and (min-width: 1080px) {
  .stepper-style {
    width: 60% !important;
  }
}

@media only screen and (max-width: 1080px) and (min-width: 960px) {
  .stepper-style {
    width: 90% !important;
  }
}

@media only screen and (max-width: 960px) {
  .login-image {
    display: none !important;
  }

  .image-column {
    height: 40vh !important;
  }

  .v-stepper__header {
    display: none !important;
  }
}
</style>
