<template>
  <v-row justify="center">
    <v-col
      cols="12"
      sm="8"
      md="6"
      mb-12
      mt-4
    >
      <v-card class="elevation-5 mb-3">
        <v-col
          col-4
          text-left
          style="padding-bottom: unset"
        >
          <v-btn
            large
            text
            style="
              padding: unset;
              justify-content: start;
              align-items: start;
              align-content: start;
              height: unset;
              width: unset;
              margin: 0;
              text-align: center;
              min-width: unset;
              margin: 0 5px 0 0;
              padding: 0.5rem;
            "
            @click="setStep('package')"
          >
            <v-icon dark>
              mdi-arrow-left
            </v-icon> Back
          </v-btn>
        </v-col>
        <v-card-text style="margin: unset; padding-top: unset">
          <v-row>
            <v-col
              cols="12"
              class="text-center"
            >
              <h3 class="text-h6 font-weight-bold mt-4">
                Weight
              </h3>
            </v-col>
            <v-col cols="6">
              <v-text-field
                ref="weight"
                v-model="shipment.weight"
                v-validate="'required|min_value:0.001'"
                type="number"
                :error-messages="errs.collect('weight')"
                label="Weight"
                data-vv-name="weight"
                hint="50lb weight limit applies."
                persistent-hint
                required
              >
                <template
                  v-if="isScaleInitialised()"
                  #append-outer
                >
                  <AutoScaleButton />
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="shipment.weight_unit"
                :items="['lbs', 'kg', 'g', 'oz']"
                label="Weight Unit"
                dusk="sf_weight_unit"
              />
            </v-col>
          </v-row>
          <!-- USPS Options -->
          <v-row
            v-if="shipment.country_code == 'US' && shipment.is_fba == false"
          >
            <v-col
              cols="12"
              class="text-center"
              style="margin: unset; padding-top: unset"
            >
              <h2 class="font-weight-bold">
                Package Type
              </h2>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card outlined>
                <v-card-text>
                  <v-radio-group v-model="package_provider">
                    <v-radio value="own">
                      <template #label>
                        <div>
                          <v-icon left>
                            far fa-user-circle
                          </v-icon>Own
                          Packaging
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-card outlined>
                <v-card-text>
                  <v-radio-group v-model="package_provider">
                    <v-radio value="carrier-provided">
                      <template #label>
                        <div>
                          <v-icon left>
                            fab fa-usps
                          </v-icon>USPS Provided
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="shipment.country_code == 'US' && shipment.is_fba == false"
          >
            <v-col
              cols="12"
              md12
            >
              <v-card outlined>
                <v-list
                  two-line
                  subheader
                >
                  <template v-for="package_type in package_types">
                    <v-list-item
                      v-if="package_type.package_provider == package_provider"
                      :key="package_type.id"
                      ripple
                    >
                      <v-list-item-action>
                        <v-radio-group
                          v-model="shipment.package_type_id"
                          @change="classification = package_type.classification"
                        >
                          <v-radio :value="Number(package_type.id)" />
                        </v-radio-group>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span v-html="package_type.description" />
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span v-if="package_provider == 'own'">
                            {{
                              $options.filters.package_descriptions(
                                package_type.id
                              )
                            }}
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              style="margin: unset; padding-top: unset"
            >
              <!-- Need Dimenstions -->
              <v-form v-if="[19, 20].includes(shipment.package_type_id) || ['CA', 'US'].includes(shipment.country_code)">
                <v-container fluid>
                  <v-row align="center">
                    <v-col
                      cols="12"
                      class="text-center"
                      style="margin: unset; padding-top: unset"
                    >
                      <h2 class="font-weight-bold mt-4">
                        Dimensions
                      </h2>
                    </v-col>
                    <v-col
                      cols="4"
                      md="3"
                    >
                      <v-text-field
                        ref="length"
                        v-model="shipment.length"
                        v-validate="dimensionsRequired ? 'required' : null"
                        type="number"
                        :error-messages="errs.collect('length')"
                        label="Length"
                        data-vv-name="length"
                        required
                        dusk="sf_length"
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      md="3"
                    >
                      <v-text-field
                        v-model="shipment.width"
                        v-validate="dimensionsRequired ? 'required' : null"
                        type="number"
                        :error-messages="errs.collect('width')"
                        label="Width"
                        data-vv-name="width"
                        required
                        dusk="sf_width"
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      md="3"
                    >
                      <v-text-field
                        v-model="shipment.height"
                        v-validate="dimensionsRequired ? 'required' : null"
                        type="number"
                        :error-messages="errs.collect('height')"
                        label="Height"
                        data-vv-name="height"
                        required
                        dusk="sf_height"
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      md="3"
                    >
                      <!-- $sizeUnits from mixin.js  -->
                      <v-autocomplete
                        v-model="shipment.size_unit"
                        v-validate="dimensionsRequired ? 'required' : null"
                        :items="$sizeUnits()"
                        label="Size Unit"
                        :search-input.sync="searchInput2"
                        :error-messages="errs.collect('size_unit')"
                        data-vv-name="size_unit"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>

          <v-row align-center>
            <v-col
              cols="12"
              class="text-center"
            >
              <v-btn
                dusk="sf_package_type_next"
                large
                depressed
                color="primary"
                @click="updateShipment()"
              >
                Next
                <v-icon dark>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import AutoScaleButton from "../AutoScaleButton.vue";
export default {
  components: {AutoScaleButton},
  data() {
    return {
      errors: {},
      classification: 'box',
      package_provider: 'own',
      searchInput2: null
    };
  },
  computed: {
    shipment: {
      get() {
        return this.$store.state.singleFlow.shipment;
      },
      set(value) {
        this.$store.state.singleFlow.shipment = value;
      }
    },
    package_types: {
      get() {
        return this.$store.getters['main/package_types'];
      }
    },
    step: {
      get() {
        return this.$store.state.singleFlow.step;
      },
      set(value) {
        this.$store.state.singleFlow.step = value;
      }
    },
    processing: {
      get() {
        return this.$store.state.singleFlow.processing;
      },
      set(value) {
        this.$store.state.singleFlow.processing = value;
      }
    },
    dimensionsRequired: function () {
      const isStallionBox =
        parseInt(this.shipment.package_type_id, 10) == 20 &&
        this.package_provider === 'own';
      const isStallionThickEnvelope =
        this.classification === 'envelope' &&
        this.package_provider === 'own' &&
        parseInt(this.shipment.package_type_id, 10) === 19;
      return (
        isStallionBox || (isStallionThickEnvelope && this.isShipmentOver1lb())
      );
    }
  },
  watch: {
    step: {
      immediate: true,
      handler(newValue) {
        if (newValue == 4) {
          if (
            this.shipment.is_fba == true ||
            !['US', 'CA'].includes(this.shipment.country_code)
          ) {
            this.shipment.package_type_id = 20;
            this.classification = 'box';
            this.package_provider = 'own';
          } else if (this.shipment.package_type_id != null) {
            this.classification =
              this.shipment.package_type_details?.classification;
            this.package_provider =
              this.shipment.package_type_details?.package_provider;
          } else {
            this.shipment.package_type_id = 20;
            this.classification = 'box';
            this.package_provider = 'own';
          }

          if (this.shipment.package_type_id == 20) {
            var self = this; //code before the pause
            setTimeout(function () {
              self.$refs.weight.focus();
            }, 100);
          }
        }
      }
    },
    shipment: function () {
      if (this.shipment.test) {
        this.shipment.length = 0;
        this.shipment.width = 0;
        this.shipment.height = 0;
      }
    },
    classification: function () {
      this.shipment.length = '';
      this.shipment.width = '';
      this.shipment.height = '';
      if (this.classification == 'box' && this.package_provider == 'own') {
        this.shipment.package_type_id = 20;
      }
    },
    package_provider: function () {
      this.shipment.length = '';
      this.shipment.width = '';
      this.shipment.height = '';
      if (this.classification == 'box' && this.package_provider == 'own') {
        this.shipment.package_type_id = 20;
      }
    }
  },
  mounted() {
    // window.addEventListener('keydown', (e) => {
    //   if (this.processing == 0 && this.step == 4 && e.key == 'Enter') {
    //     this.updateShipment();
    //   }
    // });
  },
  methods: {
    isShipmentOver1lb() {
      const unit = this.shipment.weight_unit;
      switch (unit) {
        case 'lbs':
          return this.shipment.weight >= 1;
        case 'kg':
          return this.shipment.weight >= 0.45;
        case 'g':
          return this.shipment.weight >= 453;
        case 'oz':
          return this.shipment.weight >= 16;
        default:
          return false;
      }
    },
    setStep() {
      this.$store.commit('singleFlow/previous');
    },
    updateShipment() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.$http
            .put('/shipments/' + this.shipment.id, this.shipment)
            .then((res) => {
              this.shipment = res.data;
              this.$store.commit('singleFlow/next');
            })
            .catch(() => {});
        }
      });
    }
  }
};
</script>
