function initialState() {
  return {
    qzStatus: false,
    currentScaleWeight: {},
    currentScale:{},
    currentScaleStream:false,
    currentPrinter:'',
    printerFormat:'',
    isQuickShip:false,
    notified:false,
    certificate:'',
    enabledAutoWeightFetch:false,
  };
}

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    setWeightState(state, value) {
      state.currentScaleWeight = value;
    },
    qzState(state,value) {
      state.qzStatus = value;
    },
    currentScaleState(state,value) {
      state.currentScale = value;
    },
    currentPrinterState(state,value) {
      state.currentPrinter = value[0];
      state.printerFormat = value[1];
    },
    isQuickShipState(state,value) {
      state.isQuickShip = value;
    },
    certificateState(state,value) {
      state.certificate = value;
    },
    currentScaleStreamState(state,value) {
      state.currentScaleStream = value;
    },
    notifiedState(state,value) {
      state.notified = value;
    },
    enabledAutoWeightFetch(state,value) {
      state.enabledAutoWeightFetch = value;
    },
  },
  actions: {
    
    makeQzActive({commit}) {
      commit('qzState',true);
    },
    makeQzInActive({commit}) {
      commit('qzState',false);
    },
    putWeight({commit}, data) {
      commit('setWeightState',data);
    },
    putCurrentScale({commit}, data) {
      commit('currentScaleState',data);
    },
    putCurrentPrinterState({commit}, data) {
      commit('currentPrinterState',data);
    },
    putIsQuickShipState({commit}, data) {
      commit('isQuickShipState',data);
    },
    putCertificateState({commit}, data) {
      commit('certificateState',data);
    },
    putCurrentScaleStream({commit}, data) {
      commit('currentScaleStreamState',data);
    },
    putNotified({commit}, data) {
      commit('notifiedState',data);
    },
    putEnabledAutoWeightFetch({commit}, data) {
      commit('enabledAutoWeightFetch',data);
    },
  }
};
