<template>
  <StallionDialog
    v-model="show"
    :fullscreen="true"
    :title="edit ? 'Edit Template' : 'Create Template'"
    persistent
  >
    <template #content>
      <v-form
        v-if="template"
        @submit.prevent
      >
        <v-container>
          <v-row>
            <v-col
              cols="6"
              md="6"
            >
              <v-text-field
                v-model="template.name"
                v-validate="'required'"
                autofocus
                dense
                outlined
                label="Template Name"
                data-vv-name="name"
                :error-messages="errs.collect('name')"
                name="name"
                data-vv-validate-on="blur"
              />
            </v-col>

            <v-col
              cols="3"
              md="3"
            >
              <v-select
                v-model="template.size"
                v-validate="'required'"
                dense
                outlined
                label="Print Size"
                data-vv-name="size"
                :error-messages="errs.collect('size')"
                name="size"
                data-vv-validate-on="blur"
                :items="templateSizes"
              />
            </v-col>

            <v-col
              cols="3"
              md="3"
              style="margin-top: -10px"
            >
              <v-switch
                v-model="template.is_default"
                label="Set as default"
              />
            </v-col>
          </v-row>

          <v-tabs v-model="tab">
            <v-tab>Page Header</v-tab>
            <v-tab>Item Header</v-tab>
            <v-tab>Item Content</v-tab>
            <v-tab>Page Footer</v-tab>
            <v-tab>CSS</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!--Page Header-->
            <v-tab-item>
              <TemplateEditor
                v-model="template.page_header"
                :field-replacements="fieldReplacements"
                label="Page Header"
              />
            </v-tab-item>

            <!--Item Header-->
            <v-tab-item>
              <TemplateEditor
                v-model="template.item_header"
                :field-replacements="fieldReplacements"
                label="Item Header"
              />
            </v-tab-item>

            <!--Item Content-->
            <v-tab-item>
              <TemplateEditor
                v-model="template.item_content"
                :field-replacements="fieldReplacements"
                label="Item Content"
              />
            </v-tab-item>

            <!--Page Footer-->
            <v-tab-item>
              <TemplateEditor
                v-model="template.page_footer"
                :field-replacements="fieldReplacements"
                label="Page Footer"
              />
            </v-tab-item>

            <!--CSS-->
            <v-tab-item>
              <TemplateEditor
                v-model="template.css"
                :field-replacements="fieldReplacements"
                label="CSS"
                :show-field-replacements="false"
                language="css"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-spacer />
      <v-btn
        color="primary"
        class="px-7"
        :disabled="loading"
        @click="$emit('previewTemplate', template)"
      >
        Preview
      </v-btn>

      <v-btn
        v-if="edit"
        color="primary"
        class="px-7"
        :disabled="loading"
        @click="updatetemplate"
      >
        Update
      </v-btn>

      <v-btn
        v-else
        color="primary"
        class="px-7"
        :disabled="loading"
        @click="savetemplate"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>
<script>
import DefaultTemplate from './DefaultTemplate'
import TemplateEditor from './TemplateEditor.vue';
import _cloneDeep from 'lodash/cloneDeep'

export default {
  components: {
    TemplateEditor
  },
  props: {
    loadingPreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      submitting: false,
      tab: null,
      templateSizes: [],
      fieldReplacements: [],
      edit: false,
      template: _cloneDeep(DefaultTemplate),
      show: false
    };
  },
  computed: {
    loading() {
      return this.submitting || this.loadingPreview;
    }
  },
  watch: {
    show: {
      handler() {
        if (this.show) {
          this.tab = null
          this.$validator.reset()
        }
      }
    }
  },
  created() {
    this.getTemplateSizes();
    this.getFieldReplacements();
  },
  methods: {
    closeDialog() {
      this.show = false;
      this.template = null;
      this.$emit('updateTable');
      this.$store.dispatch('packingSlipTemplates/getTemplates');
    },
    getTemplateSizes() {
      this.$http
        .get('packing-slip-templates/sizes')
        .then((response) => response.data)
        .then((response) => {
          this.templateSizes = response;
        })
    },
    getFieldReplacements() {
      this.$http
        .get('packing-slip-templates/field-replacements')
        .then((response) => response.data)
        .then((response) => {
          this.fieldReplacements = response;
        })
    },
    startNew() {
      this.show = true;
      this.template = Object.assign({}, _cloneDeep(DefaultTemplate));
      this.edit = false;
    },
    duplicate(template) {
      this.show = true;
      this.template = Object.assign(_cloneDeep(DefaultTemplate), template);
      this.template.id = null;
      this.template.name = `${template.name} (Copy)`;
      this.edit = false;
    },
    startEdit(template) {
      this.show = true;
      this.template = Object.assign(_cloneDeep(DefaultTemplate), template);
      this.edit = true;
    },
    async validateTemplate() {
      return this.$validator.validate().then((result) => {
        return result ? true : false;
      });
    },
    async savetemplate() {
      if (!(await this.validateTemplate())) return;

      this.submitting = true;
      this.$http
        .post('/packing-slip-templates', this.template)
        .then((response) => response.data)
        .then((response) => {
          this.processSuccess(response, 'Template has been created');
        })
        .catch((err) => {
          this.processError(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    async updatetemplate() {
      if (!(await this.validateTemplate())) return;

      this.submitting = true;
      this.$http
        .put(`/packing-slip-templates/${this.template.id}`, this.template)
        .then((response) => response.data)
        .then((response) => {
          this.processSuccess(response, 'Template has been updated');
        })
        .catch((err) => {
          this.processError(err);
        })
        .finally(() => {
          this.submitting = false;
        });
    },
    processSuccess(response, message) {
      swal({
        title: 'Success',
        text: message,
        icon: 'success'
      });
      this.closeDialog();
    },
    processError(err) {
      if (
        err.response.status === 422 &&
        typeof err.response.data.errors !== 'undefined'
      ) {
        const errors = err.response.data.errors;
        let message = '';

        for (var field in errors) {
          message += `${errors[field][0]}\n`;
        }
        this.errorMessage(message);
      } else {
        this.errorMessage('An error occurred while processing your request');
      }
    },
  }
};
</script>
