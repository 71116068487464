<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          dark
          @click="$refs.filterDialog.startNew()"
        >
          Create Filter
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          class="table-striped"
          :headers="headers"
          :items="items"
          :loading="loading"
          :items-per-page="10"
        >
          <template
            v-if="items.length > 0"
            #body="props"
          >
            <draggable
              v-if="props.items.length > 0"
              v-bind="dragOptions"
              :list="items"
              tag="tbody"
              @change="updateOrder"
            >
              <transition-group
                v-for="(item, index) in props.items"
                :key="index"
                tag="tr"
                type="transition"
                name="flip-list"
              >
                <td :key="`${index}name`">
                  {{ item.name }}
                </td>
                <td :key="`${index}updated_at`">
                  {{ item.updated_at | datetime_pretty }}
                </td>

                <td :key="`${index}actions`">
                  <v-btn
                    small
                    color="red"
                    text
                    :loading="loading"
                    @click="deleteFilter(item.id)"
                  >
                    Delete
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    text
                    @click="$refs.filterDialog.startEdit(item)"
                  >
                    Edit
                  </v-btn>
                </td>
              </transition-group>
            </draggable>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <filter-dialog
      ref="filterDialog"
      @update-filters="updateTable()"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import FilterDialog from './FilterDialog.vue';
export default {
  components: {
    FilterDialog,
    draggable
  },
  data() {
    return {
      filter: {},
      show_edit: false,
      loading: false,
      items: [],
      options: {},
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      },
      headers: [
        { text: 'Filter Name', value: 'name' },
        { text: 'Updated At', value: 'updated_at' },
        { text: 'Actions', value: 'actions', align: 'right', sortable: false }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.updateTable();
      },
      deep: true
    },
    'options.itemsPerPage': function (newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            filters: newVal
          }
        };
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },

  mounted() {
    this.updateTable();

    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.filters ?? 5;
  },
  methods: {
    updateTable() {
      this.loading = true;
      this.totalItems = 0;
      this.items = [];

      this.$http
        .request(`/filters`, {
          method: 'get'
        })
        .then((response) => response.data)
        .then((response) => {
          this.items = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async deleteFilter(id) {
      const isConfirm = await swal({
        title: 'Confirm Delete',
        text: 'Do you really want to delete this filter?',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      });

      if (isConfirm) {
        this.loading = true
        try {
          await this.$store.dispatch('filters/deleteFilter', id);
          this.updateTable();
        } catch (e) {
          console.log(e);
        }
        this.loading = false
      }
    },
    showUpdate(filter) {
      this.show_edit = true;
      this.filter = filter;
    },
    showCreate() {
      this.show_edit = true;
      this.filter = null;
    },
    updateOrder() {
      this.$http
        .request(`/filters/order`, {
          method: 'post',
          data: {
            rules: this.items
          }
        })
        .then((response) => response.data)
        .then(() => {
          // this.updateTable();
        });
    }
  }
};
</script>
<style>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
