<template>
  <SidePanelLayout title="Orders">
    <template #side-panel>
      <order-details :orders="selected" />
      <!-- <view-order-details style="font-size: 14px" :selected="main.selectedselected"></view-order-details> -->
    </template>

    <template #page-links>
      <v-col class="text-right">
        <order-links />
      </v-col>
    </template>

    <template #footer>
      <v-btn
        color="primary"
        :disabled="!canCreateLabelsFromOrders(selected) || !can(PERMISSIONS.SHIPPING)"
        @click="openCreateLabelsDialogFromOrders"
      >
        Process
      </v-btn>
      
      <!--isPrimaryAccountHolder from mixin.js-->
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            :disabled="selected.length === 0"
            v-on="on"
          >
            Print
            <v-icon right>
              mdi-chevron-up
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            :disabled="selected.length === 0"
            @click="show_product_pick_list = true"
          >
            <v-list-item-icon>
              <v-icon
                small
                dense
                left
              >
                mdi-select-group
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pick List</v-list-item-title>
          </v-list-item>
        
          <v-list-item
            :disabled="selected.length === 0 "
            @click="show_print = true"
          >
            <v-list-item-icon>
              <v-icon
                small
                dense
                left
              >
                mdi-package-variant-closed
              </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Packing Slip</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>


      
     

      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            :disabled="selected.length === 0"
            v-on="on"
          >
            Other
            <v-icon right>
              mdi-chevron-up
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            :disabled="selected.length === 0 || !can(PERMISSIONS.ORDERS) || selected.length > 1"
            @click="show_split = true"
          >
            <v-list-item-icon>
              <v-icon>alt_route</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Split</v-list-item-title>
          </v-list-item>
          
          <v-list-item
            :disabled="selected.length === 0 || !can(PERMISSIONS.ORDERS)"
            @click="show_duplicate = true"
          >
            <v-list-item-icon>
              <v-icon>mdi-content-copy</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Duplicate</v-list-item-title>
          </v-list-item>
       
          <v-list-item
            :disabled="selected.length === 0"
            @click="$refs.updateOrderStatus.openDialog()"
          >
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Edit Status</v-list-item-title>
          </v-list-item>
     
          <v-list-item
            :disabled="selected.length === 0"
            @click="$refs.deleteOrders.startNew()"
          >
            <v-list-item-icon>
              <v-icon>mdi-archive-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title> Archive</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
       
    <template #default>
      <orders-table
        ref="ordertable"
        v-model="selected"
      />
      <print-packing-slips
        v-model="show_print"
        :items="selected"
        :type="'orders'"
        @update="emitUpdate()"
      />

      <print-product-pick-list
        v-model="show_product_pick_list"
        :items="selected"
        :type="'orders'"
        @update="emitUpdate()"
      />

      <delete-orders 
        ref="deleteOrders" 
        :orders="selected"
        :disabled="selected.length === 0"
      />

      <duplicate-orders
        v-model="show_duplicate"
        :orders="selected"
        @update="$refs.ordertable.updateTable()"
      />

      <split-order
        v-model="show_split"
        :order="selected[0]"
        @update="$refs.ordertable.updateTable()"
      />

      <create-labels
        ref="refCreateLabels"
        v-model="show_create_labels"
        @update="$refs.ordertable.updateTable()"
      />

      <update-order-status
        ref="updateOrderStatus"
        :orders="selected"
        :disabled="selected.length == 0"
        @update="$refs.ordertable.updateTable()"
      />
    </template>
  </SidePanelLayout>
</template>

<script>
import { mapState } from 'vuex';
import CreateLabels from '../../../components/main/shipments/action-bar/CreateLabels/MainDialog.vue';
import OrdersTable from './OrdersTable.vue';
import OrderDetails from './details/OrderDetails.vue';
import DeleteOrders from './DeleteOrders.vue';
import UpdateOrderStatus from './UpdateOrderStatus.vue';
import PrintPackingSlips from '@/components/main/shipments/action-bar/PrintPackingSlips.vue'
import PrintProductPickList from '@/components/main/shipments/action-bar/PrintProductPickList.vue'
import PERMISSIONS from '@/constants/client-permissions'
import DuplicateOrders from '@/components/main/orders/DuplicateOrders.vue'
import SplitOrder from '@/components/main/orders/SplitOrder.vue'

export default {
  metaInfo: {
    title: "Orders | Stallion Express"
  },
  components: {
    OrdersTable,
    DeleteOrders,
    UpdateOrderStatus,
    OrderDetails,
    PrintPackingSlips,
    PrintProductPickList,
    DuplicateOrders,
    SplitOrder,
    CreateLabels
  },
  data() {
    return {
      show: false,
      selected: [],
      show_print: false,
      show_duplicate: false,
      show_split: false,
      show_product_pick_list: false,
      show_create_labels: false,
      PERMISSIONS: PERMISSIONS
    };
  },
  computed: {
    ...mapState(['main']),
    filter_formated() {
      return this.$options.filters.capitalize(this.filter);
    }
  },
  methods: {
    openCreateLabelsDialogFromOrders() {
      this.$refs.refCreateLabels.openFromOrders(this.selected);
    }
  }
};
</script>
