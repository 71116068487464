export default {
  DEFAULT_TABLE_COLUMNS: {
    table_columns_client_shipments: [
      { value: 'name', visible: true },
      { value: 'order_id', visible: true },
      { value: 'postage_type_id', visible: true },
      { value: 'postage_rate', visible: true },
      { value: 'status_id', visible: true },
      { value: 'batch_id', visible: true },
      { value: 'closeout_id', visible: true },
      { value: 'created_at', visible: true }
    ],
    table_columns_client_batch_shipments: [
      { value: 'name', visible: true },
      { value: 'order_id', visible: true },
      { value: 'postage_type_id', visible: true },
      { value: 'package_type_id', visible: true },
      { value: 'postage_rate', visible: true },
      { value: 'status_id', visible: true },
      { value: 'package_contents', visible: true },
      { value: 'value', visible: true },
      { value: 'created_at', visible: true },
      { value: 'actions', visible: true }
    ],
    table_columns_client_orders: [
      { value: 'order_id', visible: true },
      { value: 'user_store.identifier', visible: true },
      { value: 'items_count', visible: true },
      { value: 'value', visible: true },
      // { value: 'shipment', visible: true },
      { value: 'country_code', visible: true },
      { value: 'order_at', visible: true }
    ]
  }
}