<template>
  <div>
    <v-menu>
      <template #activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          color="primary"
          :disabled="disabled"
          v-on="on"
        >
          Print
          <v-icon right>
            mdi-chevron-up
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="showDialog('Invoice')">
          <v-list-item-icon>
            <v-icon
              small
              dense
              left
            >
              mdi-receipt
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Order Invoice</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="showLabel"
          @click="showDialog('Label')"
        >
          <v-list-item-icon>
            <v-icon
              small
              dense
              left
            >
              mdi-label
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Order Label</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <StallionDialog
      v-model="show"
      :width="500"
      :disabled="disabled"
      :title="'Print '.concat(key)"
    >
      <template #content>
        <v-layout column>
          <v-flex
            xs12
            text-center
            mt-4
            mb-12
          >
            <h4>
              {{ invoices.length }} supply
              {{ getKeyDescription | pluralize(getKeyDescription) }} ready to
              be printed
            </h4>
            <span>Your order has been placed. Confirmation email will be sent in
              24-48hrs when the order is ready for pickup at your pickup
              location.</span>
          </v-flex>
        </v-layout>
      </template>

      <template #actions>
        <v-btn
          color="primary"
          text
          download
          :loading="dowload_loading"
          :disabled="dowload_loading"
          @click.prevent="getLabel('download')"
        >
          <v-icon
            small
            dark
          >
            mdi-download
          </v-icon>
          Download
        </v-btn>

        <v-btn
          color="primary"
          :loading="print_loading"
          :disabled="print_loading"
          @click="getLabel('print')"
        >
          <v-icon
            small
            dark
          >
            mdi-printer
          </v-icon>
          Print
        </v-btn>
      </template>
    </StallionDialog>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: null
    },
    disabled: {
      default: false,
      type: Boolean
    },
    button: {
      default: true,
      type: Boolean
    },
    show: {
      default: false,
      type: Boolean
    },
    invoices: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      print_loading: false,
      dowload_loading: false,
      key: 'Invoice'
    };
  },
  computed: {
    getKeyDescription: function () {
      return this.key.toLowerCase();
    },
    showLabel: function () {
      return this.can('supplies.scan') && this.isAdminPanel;
    },
    isAdminPanel: function () {
      return this.$router.history.current.meta?.admin;
    }
  },

  methods: {
    getLabel: async function (action) {
      action === 'download'
        ? (this.download_loading = true)
        : (this.print_loading = true);

      var ids = [];
      this.invoices.forEach(function (item) {
        ids.push(item.invoice_number);
      });

      try {
        if (!this.key) {
          this.key = 'Invoice';
        }
        const response = await this.$http.post(
          '/supplies-invoices/print',
          { ids: ids, document: this.key }
        );
        if (response) {
          action === 'download'
            ? this.download(response.data)
            : this.print(response.data);
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 403) {
          this.errorMessage('This action is unauthorized.');
        }
      }
    },
    print: function (data) {
      this.printFileUrl(data);
      this.print_loading = false;
    },
    showDialog: function (key) {
      this.key = key;
      this.show = true;
    },
    download: function (data) {
      this.downloadFileUrl(data, 'StallionExpressSuppliesInvoice.pdf');
      this.download_loading = false;
    }
  }
};
</script>
