<template>
  <v-app id="inspire">
    <v-navigation-drawer
      app
      width="240"
      :value="drawer"
    >
      <v-img
        style="width: 60%; margin: 25px"
        src="./images/StallionExpress-Black-Logo.png"
      />
    
      <drop-off-list />

      <linked-accounts-list />

      <main-list />

      <open-batches-list v-if="can('CLIENT.SHIPPING')" />
    </v-navigation-drawer>

    <v-app-bar
      :clipped-right="$vuetify.breakpoint.mdAndUp"
      color="white"
      height="55"
      app
      flat
      fixed
      :outlined="true"
      style="border: 1px solid #e7e7e7 !important"
    >
      <v-toolbar-title>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <strong v-if="$auth.user">{{ $auth.user.business_name || $auth.user.name }}</strong>
      </v-toolbar-title>
      <v-spacer />

      <weglot-switcher class="mt-1" />

      <v-toolbar-items class="pa-1">
        <user-credits-button />

        <cart-menu />

        <notification-menu />

        <account-menu />
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <slot />
    </v-main>

    <newShipmentModal />
    <add-credits-modal />
  </v-app>
</template>

<script>
import LinkedAccountsList from './NavLists/LinkedAccountsList.vue';
import NotificationMenu from './Menu/NotificationMenu.vue';
import CartMenu from './Menu/CartMenu.vue';
import AccountMenu from './Menu/AccountMenu.vue';
import UserCreditsButton from './Buttons/UserCreditsButton.vue';
import weglotSwitcher from '../main/weglot/weglot-switcher.vue';
import DropOffList from './NavLists/DropOffList.vue';
import MainList from './NavLists/MainList.vue';
import OpenBatchesList from './NavLists/OpenBatchesList.vue';

export default {
  components: {
    weglotSwitcher,
    LinkedAccountsList,
    NotificationMenu,
    CartMenu,
    AccountMenu,
    UserCreditsButton,
    DropOffList,
    MainList,
    OpenBatchesList
  },
  props: {
    source: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      drawer: null
    };
  },
  mounted() {
    Event.listen('display-announcement', () => {
      if (!this.isAdminPanel) {
        this.displayAnnouncement();
      }
    });
    this.displayAnnouncement();
  },
  methods: {
    displayAnnouncement() {
      const announcements = this.$store.getters['main/announcements'];
      if (announcements.unread.length > 0 && !this.isAdminPanel) {
        this.$notify({
          group: 'announcement',
          title: announcements.unread[0].title,
          text: announcements.unread[0].description,
          duration: -1,
          data: { id: announcements.unread[0].id }
        });
      }
    }
  }
};
</script>

<style>
.rounded-list {
  border-radius: 40px;
}
.v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding-left: 0 !important;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 25px;
}

.v-list-item .v-list-item__title,
.v-list-group__header .v-list-item__title {
  font-size: 13px;
  font-weight: 600;
}

.v-list-item--active .v-list-item__title {
  font-weight: 700;
}

.v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
  margin-bottom: 5px;
}

.v-list .v-list-item--active[data-v-2e2b5276] {
  color: #f1a029;
}

.v-menu__content {
  max-width: 500px;
}

.v-badge__badge primary {
  font-size: 9px;
  height: 16px;
}

.btn-fix:focus::before {
  opacity: 0 !important;
}

.v-btn:before {
  opacity: 0 !important;
}

.v-ripple__container {
  opacity: 0 !important;
}

.v-application p {
  margin-bottom: 10px;
  line-height: 1.2em;
}
</style>
