<template>
  <div>
    <v-list
      v-for="(parent, parent_index) in filterdMenu"
      :key="parent_index"
      dense
    >
      <v-subheader
        :key="parent.title + '1'"
        style="height: 30px"
      >
        {{ parent.sub_header }}
      </v-subheader>

      <template v-for="(menu, menu_index) in parent.menu">
        <v-list-group
          v-if="isValidSubMenu(menu)"
          :key="menu.title"
          v-model="menu.model"
          :prepend-icon="menu.icon"
          router
          :to="menu.route"
          no-action
        >
          <template #activator>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </template>

          <template v-for="(sub_menu, sub_menu_index) in menu.sub_menu">
            <v-subheader
              v-if="sub_menu?.subheader"
              :key="'sub-menu-header' + sub_menu_index"
              style="height: 30px; margin-left: 55px"
            >
              {{ sub_menu?.subheader }}
            </v-subheader>
            <v-list-item
              v-if="sub_menu?.permission?.length > 0 ? validatePermission(sub_menu.permission) : true"
              :key="'sub-menu-item' + sub_menu_index"
              router
              :to="sub_menu.route"
              exact
            >
              <v-list-item-content>
                <v-list-item-title>{{ sub_menu.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon v-if="sub_menu.icon">
                <v-badge
                  v-if="sub_menu?.show_badge"
                  color="primary"
                  right
                  :value="counts != null && counts[sub_menu?.badge_field] && counts[sub_menu?.badge_field] > 0"
                >
                  <template #badge>
                    {{ counts[sub_menu?.badge_field] }}
                  </template>
                  <v-icon>{{ sub_menu.icon }}</v-icon>
                </v-badge>

                <v-icon v-else>
                  {{ sub_menu.icon }}
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider
              v-if="sub_menu?.show_divider"
              :key="sub_menu_index"
              class="my-2"
            />
          </template>
          <v-divider
            v-if="menu_index != parent.menu?.length - 1"
            class="mt-2"
          />
        </v-list-group>

        <v-list-item
          v-else-if="isValidMenu(menu)"
          :key="menu_index"
          router
          :to="menu.route"
        >
          <v-list-item-icon v-if="menu.icon">
            <v-badge
              v-if="menu?.show_badge"
              color="primary"
              right
              :value="counts != null && counts[menu?.badge_field] && counts[menu?.badge_field] > 0"
            >
              <template #badge>
                {{ counts[menu?.badge_field] }}
              </template>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-badge>

            <v-icon v-else>
              {{ menu.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menu.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider class="mt-2" />
    </v-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      counts: [],
      side_menu: [
        {
          sub_header: "Outbound Operations",
          permission: 'shipments.view',
          menu: [

            {
              title: "Shipments",
              icon: 'mdi-package-variant-closed', 'icon-alt': 'mdi-package-variant-closed',
              permission: 'shipments.view',
              model: false,
              sub_menu: [
                { title: 'All', icon: 'mdi-format-list-bulleted', route: '/admin/shipments' },
                { title: 'Incomplete', icon: 'mdi-circle-edit-outline', route: '/admin/shipments/incomplete' },
                { title: 'Pending', icon: 'mdi-pause-circle-outline', route: '/admin/shipments/pending' },
                { title: 'Ready', icon: 'mdi-cube-outline', route: '/admin/shipments/ready' },
                { title: 'Received', icon: 'mdi-marker-check', route: '/admin/shipments/received' },
                { title: 'In Transit', icon: 'mdi-cube-send', route: '/admin/shipments/in-transit' },
                { title: 'Complete', icon: 'mdi-check', route: '/admin/shipments/complete' },
                { title: 'Exception', icon: 'mdi-alert-circle-outline', route: '/admin/shipments/exception' },
                { title: 'Void Requested', icon: 'mdi-select', route: '/admin/shipments/void-requested' },
                { title: 'Voided', icon: 'mdi-select-off', route: '/admin/shipments/voided' },
                { title: 'Postage Expired', icon: 'mdi-clock-alert-outline', route: '/admin/shipments/postage-expired' }
              ]
            },
            { title: "Branch Scan", route: "/admin/scan/branch", permission: "shipments.scan", icon: "mdi-barcode-scan" },
            { title: "Manual Scan", route: "/admin/scan/manual", permission: "shipments.scan", icon: "mdi-barcode" },
            {
              title: "Sort", route: "/adminadmin/admin/returns", permission: 'shipments.sort', icon: "mdi-sort-variant", model: false,
              sub_menu: [
                { title: 'Containers', icon: 'mdi-cube', route: '/admin/npi/containers', show_divider: true },
                { title: "Media + Library", icon: "fab fa-usps", route: "/admin/sort/media-library" },
                { title: "USPS General", icon: "fab fa-usps", route: "/admin/sort/usps-general" },
                { title: "USPS Non Tracked", icon: "fab fa-usps", route: "/admin/sort/usps-non-track" },
                { title: "FBA", icon: "fab fa-amazon", route: "/admin/fba" },
                { title: "Non-FBA UPS", icon: "fab fa-ups", route: "/admin/sort/ups-non-fba" },
                { title: "Fedex", icon: "fab fa-fedex", route: "/admin/sort/fedex-non-fba" },
                { title: "Fedex Express", icon: "fab fa-fedex", route: "/admin/sort/fedex-express" },
                { title: "APC", icon: "mdi-globe-model", route: "/admin/sort/apc" },
                { title: "OSM", icon: "fas fa-shipping-fast", route: "/admin/sort/osm" },
                { title: "UPS MI", icon: "fas fa-shipping-fast", route: "/admin/sort/ups-mail-innovations" },
              ]
            },
            {
              title: "Supplies", permission: 'supplies.view', icon: "mdi-email-open-outline", model: false,
              sub_menu: [
                { title: "Inventory", icon: "mdi-clipboard-pulse", route: "/admin/supplies-inventory", permission: "supplies.edit.inventory", show_divider: true },
                { title: "In-Transit", icon: "mdi-barcode", route: { name: 'admin-supplies-scan', params: { status_id: 4 } }, permission: 'supplies.scan', subheader: "Order Scan" },
                { title: "Ready", icon: "mdi-barcode", route: { name: 'admin-supplies-scan', params: { status_id: 5 } }, permission: 'supplies.scan' },
                { title: "Complete", icon: "mdi-barcode", route: { name: 'admin-supplies-scan', params: { status_id: 2 } }, permission: 'supplies.scan' },
                { title: "All", icon: "mdi-format-list-bulleted", route: { name: 'admin-supplies-orders' }, subheader: "Orders" },
                { title: "Pending", icon: "mdi-clipboard-outline", route: { name: 'admin-supplies-orders', query: { status_id: 6 } } },
                { title: "In-Transit", icon: "mdi-clipboard-outline", route: { name: 'admin-supplies-orders', query: { status_id: 4 } } },
                { title: "Ready", icon: "mdi-clipboard-check-outline", route: { name: 'admin-supplies-orders', query: { status_id: 5 } } },
                { title: "Complete", icon: "mdi-select-off", route: { name: 'admin-supplies-orders', query: { status_id: 2 } } },
                { title: "Delivery Required", icon: "mdi-cube-outline", route: { name: 'admin-supplies-orders', query: { delivery: true } } }
              ]
            },
            {
              title: "Closeout", route: "/adminadmin/admin/returns", permission: 'closeouts.view', icon: "mdi-clipboard-check", model: false,
              sub_menu: [
                { title: "History", icon: "mdi-history", route: "/admin/closeouts", show_divider: true },
                { title: "PostNL", icon: "mdi-airplane", route: "/admin/closeout/postnl" },
                { title: "UniUni", icon: "mdi-airplane", route: "/admin/closeout/uni-uni" },
                { title: "FleetOptics", icon: "mdi-telescope", route: "/admin/closeout/fleetoptics" },
                { title: "Canada post", icon: "mdi-postage-stamp", route: "/admin/closeout/canada-post" },
                { title: "GSM", icon: "mdi-postage-stamp", route: "/admin/closeout/gsm" },
                { title: "UPS", icon: "fab fa-ups", route: "/admin/closeout/ups-canada" },
                { title: "US", icon: "mdi-wall", route: "/admin/closeout/us" },
                { title: "ICS", icon: "mdi-truck", route: "/admin/closeout/ics" },
                { title: "CANPAR", icon: "mdi-truck-delivery", route: "/admin/closeout/canpar" }
              ]
            },
            {
              title: "Outbound Trips", permission: 'trips.manage', icon: "mdi-truck", model: false,
              sub_menu: [
                { title: "All", icon: "mdi-format-list-bulleted", route: "/admin/outbound-trips" },
                { title: "Create Trip", icon: "mdi-plus", route: "/admin/outbound-trips/new" }
              ]
            },
            { title: "Voids", route: "/admin/voids", permission: 'voids.view', icon: "mdi-select-off" },
            { title: "Claims", route: "/admin/claims", permission: 'claims.view', icon: "mdi-chat-alert" },
            { title: "Batches", route: "/admin/batches", permission: 'batches.manage', icon: "mdi-file-document" },
            { title: "Pickups", route: "/admin/pickups", permission: 'pickups.view', icon: "mdi-truck" }
          ]
        },
        {
          sub_header: "Inbound Operations",
          permission: 'returns.view',
          menu: [
            { title: "Create Return", icon: 'mdi-plus-box', route: "/admin/returns-create", permission: 'returns.edit' },
            {
              title: "Returns", icon: 'mdi-keyboard-backspace', permission: 'returns.view', model: false,
              sub_menu: [
                { title: 'All', icon: 'mdi-format-list-bulleted', route: '/admin/returns' },
                { title: 'Unassigned', icon: 'mdi-file-question-outline', route: '/admin/returns?filter=unassigned' },
                { title: 'Pending Details', icon: 'mdi-comment-question-outline', route: '/admin/returns?filter=pending-details' },
                { title: 'Awaiting Review', icon: 'mdi-account-question-outline', route: '/admin/returns?filter=awaiting-review', show_badge: true, badge_field: 'awaiting_review' },
                { title: 'Photo Requested', icon: 'mdi-camera', route: '/admin/returns?filter=photo-requested', show_badge: true, badge_field: 'photo_requested' },
                { title: 'Ready', icon: 'mdi-cube', route: '/admin/returns?filter=ready' },
                { title: 'In-Transit', icon: 'mdi-cube-send', route: '/admin/returns?filter=in-transit' },
                { title: 'In Canada', icon: 'mdi-home-map-marker', route: '/admin/returns?filter=in-canada' },
                { title: 'Complete', icon: 'mdi-check', route: '/admin/returns?filter=complete' },
                { title: 'Hold', icon: 'mdi-pause-octagon-outline', route: '/admin/returns?filter=hold' },
                { title: 'US Disposal', icon: 'mdi-home-map-marker', route: '/admin/returns?filter=us-disposal' },

              ]
            },
            {
              title: "Received Scan", icon: 'mdi-library-shelves',
              sub_menu: [
                { title: 'ALL', icon: 'mdi-format-list-bulleted', route: '/admin/returns/scan' },
                { title: 'US', icon: 'mdi-home-map-marker', route: '/admin/returns/scan?filter=US' }
              ]
            },
            { title: "Order Scan", icon: 'mdi-barcode', route: '/admin/returns/scan-order', permission: 'return_orders.scan' },
            {
              title: "Orders", icon: 'mdi-clipboard-text', model: false, permission: 'return_orders.view',
              sub_menu: [
                { title: 'All', icon: 'mdi-format-list-bulleted', route: '/admin/orders' },
                { title: 'In-Transit', icon: 'mdi-cube-send', route: '/admin/orders?filter=in-transit' },
                { title: 'Ready For Pickup', icon: 'mdi-home-map-marker', route: '/admin/orders?filter=ready-for-pickup' },
                { title: 'Complete', icon: 'mdi-check', route: '/admin/orders?filter=complete' },
                { title: 'Dispose', icon: 'mdi-delete-circle-outline', route: '/admin/orders?filter=dispose' }
              ]
            },
            {
              title: "Inbound Trips", icon: 'mdi-truck', model: false, permission: 'trips.manage',
              sub_menu: [
                { title: 'New', icon: 'mdi-plus', route: '/admin/return-trips/new' },
                { title: 'All', icon: 'mdi-format-list-bulleted', route: '/admin/return-trips' }
              ]
            },
            { title: "Return Lookup", icon: 'mdi-format-list-bulleted', route: '/admin/return-lookup', permission: 'returns.view' },
            { title: "Domestic Entry Scan", icon: 'mdi-barcode', route: '/admin/scan-domestic', permission: 'shipments.scan' },
            { title: "Delivery Required", icon: "mdi-cube-outline", route: '/admin/returns/delivery-required', show_badge: true, badge_field: 'admin_delivery_required', permission: 'returns.view' },
          ]
        },
        {
          sub_header: "User Accounts",
          permission: 'users.view',
          menu: [
            { title: "Users", icon: 'mdi-account-multiple', route: "/admin/users", permission: 'users.view' },
            { title: "User Groups", icon: 'mdi-account-group', route: "/admin/user-groups", permission: 'user_groups.manage' },
            { title: "Recurring Charges", icon: 'mdi-credit-card-refresh-outline', route: "/admin/recurring-charges", permission: 'recurring_charges.manage' },
            { title: "Roles and Permissions", icon: 'mdi-pencil-lock', route: "/admin/roles-and-permissions", permission: 'roles_and_permissions.manage' },
            { title: "Postage Adjustments", icon: 'mdi-adjust', route: "/admin/postage-adjustments", permission: 'adjustments.manage' },
            { title: "Custom Pricing", icon: 'mdi-account-star', route: "/admin/custom-pricing", permission: 'pricing.manage' },
            { title: "Commission Structures", icon: 'mdi-wallet-membership', route: "/admin/commission-structures", permission: 'commissions.manage' },
            { title: "Commission Payouts", icon: 'mdi-cash-clock', route: "/admin/commission-payouts", permission: 'commissions.manage' }
          ]
        },
        {
          sub_header: "US Employee",
          permission: 'access.us',
          menu: [
            { title: "Rack Scan", icon: 'mdi-library-shelves', route: "/admin/rack-scan", permission: 'access.us' },
            { title: "Create Return", icon: 'mdi-plus-box', route: "/admin/returns-create", permission: 'returns.edit' },
            // { title: "In-Transit Scan", icon: 'mdi-barcode', route: "/admin/us-scan",  permission: 'access.us' },
            { title: "Sort Scan", icon: 'mdi-barcode', route: "/admin/us-sort-scan", permission: 'access.us' },
            { title: "Sort", icon: 'mdi-sort-variant', route: "/admin/us-sort", permission: 'access.us' },
            { title: "Disposal Scan", icon: 'mdi-delete', route: "/admin/disposal-scan", permission: 'access.us' },
            { title: "Rack Labels", icon: 'mdi-tag', route: "/admin/rack-labels", permission: 'access.us' },
            { title: "Return Labels", icon: 'mdi-tag', route: "/admin/labels/returns", permission: 'access.us' },
            { title: "Pallet Labels", icon: 'mdi-tag', route: "/admin/labels/pallets", permission: 'access.us' }
          ]
        },
        {
          sub_header: "Advanced",
          permission: 'sample_rates.view,shipments.view,analytics.view',
          conditional_operator: 'OR',
          menu: [
            { title: "Analytics", icon: 'mdi-chart-bar', route: "/admin/analytics", role: 'super_admin' },
            { title: "Provider Credentials", icon: 'mdi-account-key', route: "/admin/provider-credentials", role: 'super_admin' },
            { title: "Location Invoices", icon: 'mdi-receipt', route: "/admin/dropoff-invoices", permission: 'users.dropoff_invoices' },
            { title: "Analytics Carrier", icon: 'mdi-chart-line', route: "/admin/analytics-carrier", role: 'super_admin' },
            { title: "Rack Labels", icon: 'mdi-tag', route: "/admin/admin-rack-labels", permission: 'rack_label.view' },
            { title: "Generate Rates", icon: 'mdi-alarm-panel-outline', route: "/admin/generate-rates", permission: 'sample_rates.create' },
            { title: "Export Shipments", icon: 'mdi-export', route: "/admin/export", permission: 'shipments.view' },
            { title: "Roles and Permissions", icon: 'mdi-pencil-lock', route: "/admin/roles-and-permissions", permission: 'roles_and_permissions.manage' },
            { title: "Dropoff Locations", icon: 'mdi-warehouse', route: "/admin/dropoff-locations", permission: 'dropoff_locations.edit' },
            { title: "Regions", icon: 'mdi-map-marker-radius', route: "/admin/regions", permission: 'regions.edit' },
            { title: "Postage Restrictions", icon: 'mdi-truck', route: "/admin/postage-restrictions", permission: 'postage.restrictions' },
            { title: "Announcements", icon: 'mdi-alert-circle-check-outline', route: "/admin/announcements", permission: 'announcements.manage' },
            { title: "System Settings", icon: 'mdi-wrench', route: "/admin/system-settings", permission: 'system_settings.manage' }
          ]
        }
      ]
    }
  },
  computed: {
    filterdMenu() {
      return this.filterSideMenuByPermission(this.side_menu)

    }
  },
  mounted() {
    this.getReturnCounts()
    Event.listen('update-admin-returns', () => {
      this.getReturnCounts();
    });
  },
  methods: {
    getReturnCounts: function () {
      this.$http.get('/admin/returns/counts').then((res) => {
        this.counts = res.data.returns;
      });
    },
    isValidSubMenu(menu) {
      if (menu.sub_menu?.length > 0) {
        return menu?.permission?.length > 0 ? this.validatePermission(menu.permission) : true
      }
      return false
    },

    isValidMenu(menu) {
      if (menu?.permission?.length > 0) {
        return this.validatePermission(menu.permission)
      } else if (menu?.role?.length > 0) {
        return this.hasRole(menu?.role)
      }
      return true
    },
    filterSideMenuByPermission() {
      if (!this.$auth.user) {
        return []
      }

      return this.side_menu.map((subHeader) => {
        const filteredMenu = subHeader.menu.filter((menuItem) => {
          if(menuItem.route == '/admin/scan/branch' && (!this.$auth.user.employee_location || this.$auth.user.employee_location.region_id != 1)) {
            return false
          }

          if (menuItem.sub_menu) {
            const filteredSubMenu = menuItem.sub_menu.filter((subMenuItem) => {
              if(subMenuItem.route == '/admin/npi/containers' && (!this.$auth.user.employee_location || this.$auth.user.employee_location.sort_method == 'Manual')) {
                return false
              }
              if (subMenuItem.permission) {
                return this.validatePermission(subMenuItem.permission);
              }
              return true; // If no permission defined, assume it's accessible
            });

            if (filteredSubMenu.length > 0) {
              menuItem.sub_menu = filteredSubMenu;
              return true;
            }
          } else {
            if (menuItem.permission) {
              return this.validatePermission(menuItem.permission);
            }
            return true; // If no permission defined, assume it's accessible
          }
        });

        subHeader.menu = filteredMenu;

        return subHeader;
      });
    },

    validatePermission(permission_str, operator = 'AND') {
      if (permission_str?.length > 0) {
        const permissions = permission_str.split(',').map(obj => ({ ['permission']: obj, ['has_permission']: this.can(obj) }))
        const allowed_permissions_count = permissions.filter(obj => obj.has_permission).length
        switch (operator) {
          case "OR":
            return allowed_permissions_count > 0;
          case "AND":
            return allowed_permissions_count == permissions.length;
          default:
            return false;
        }
      } else {
        return true
      }
    }
  }
}
</script>
<style scoped>
.v-list-item__title {
  font-size: 13px;
}
</style>