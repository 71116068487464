import _find from 'lodash/find'
import CONSTANTS from '../constants/index';

const LIST_OPERATOR_VALUES = [
  'in_list',
  'not_in_list',
  'in_list_contains',
  'not_in_list_contains',
  'in_list_starts_with',
  'in_list_not_starts_with',
  'in_list_ends_with',
  'in_list_not_ends_with'
]

// const JSON_OPERATORS_VALUES = [
//   'includes',
//   'does_not_include',
//   'is_empty',
//   'is_not_empty',
//   'in_list',
//   'not_in_list'
// ];

const STRING_OPERATORS = [
  {
    text: 'Is Empty',
    value: 'is_empty',
    hideInput: true
  },
  {
    text: 'Is Not Empty',
    value: 'is_not_empty',
    hideInput: true
  },
  {
    text: 'Equals',
    value: 'equals'
  },
  {
    text: 'Does Not Equal',
    value: 'not_equals'
  },
  {
    text: 'Starts With',
    value: 'starts_with'
  },
  {
    text: 'Ends With',
    value: 'ends_with'
  },
  {
    text: 'Contains',
    value: 'contains'
  },
  {
    text: 'Does Not Contain',
    value: 'does_not_contain'
  },
  {
    text: 'Does Not Start With',
    value: 'does_not_start_with'
  },
  {
    text: 'Does Not End With',
    value: 'does_not_end_with'
  },
  {
    text: 'In List',
    value: 'in_list'
  },
  {
    text: 'Not In List',
    value: 'not_in_list'
  },
  {
    text: 'In List Contains',
    value: 'in_list_contains'
  },
  {
    text: 'Not In List Contains',
    value: 'not_in_list_contains'
  },
  {
    text: 'In List Starts With',
    value: 'in_list_starts_with'
  },
  {
    text: 'In List Not Starts With',
    value: 'in_list_not_starts_with'
  },
  {
    text: 'In List Ends With',
    value: 'in_list_ends_with'
  },
  {
    text: 'In List Not Ends With',
    value: 'in_list_not_ends_with'
  },
];

const NUMBER_OPERATORS = [
  {
    text: 'Equals',
    value: 'equals'
  },
  {
    text: 'Less than',
    value: 'less_than'
  },
  {
    text: 'Less than or equal to',
    value: 'less_than_or_equal_to'
  },
  {
    text: 'Greater than',
    value: 'greater_than'
  },
  {
    text: 'Greater than or equal to',
    value: 'greater_than_or_equal_to'
  },
  {
    text: 'In List',
    value: 'in_list'
  },
  {
    text: 'Not In List',
    value: 'not_in_list'
  }
];

const DATE_OPERATORS = [
  {
    text: 'Equals Current Date',
    value: 'current_date',
    hideInput: true
  },
  {
    text: 'Equals Yesterday',
    value: 'yesterday',
    hideInput: true
  },
  {
    text: 'Within last x Days',
    value: 'last_x_days'
  }
];

const LIST_OPERATORS = [
  {
    text: 'Includes',
    value: 'includes'
  },
  {
    text: 'Does Not Include',
    value: 'does_not_include'
  },
  {
    text: 'Is Empty',
    value: 'is_empty',
    hideInput: true
  },
  {
    text: 'Is Not Empty',
    value: 'is_not_empty',
    hideInput: true
  },
  {
    text: 'In List',
    value: 'in_list'
  },
  {
    text: 'Not In List',
    value: 'not_in_list'
  }
];

const JSON_OPERATORS = [
  {
    text: 'Includes',
    value: 'includes'
  },
  {
    text: 'Does Not Include',
    value: 'does_not_include'
  },
  {
    text: 'Is Empty',
    value: 'is_empty',
    hideInput: true
  },
  {
    text: 'Is Not Empty',
    value: 'is_not_empty',
    hideInput: true
  },
  {
    text: 'In List',
    value: 'in_list'
  },
  {
    text: 'Not In List',
    value: 'not_in_list'
  }
];

const BOOLEAN_OPERATORS = [
  {
    text: 'Equals',
    value: 'equals'
  }
];

const TEMPLATES_UNSORTED = [
  {
    key: 'stallion',
    text: 'stallion'
  },
  {
    key: 'incindia',
    text: 'incindia'
  },
  {
    key: 'shipstation',
    text: 'shipstation'
  },
  {
    key: 'stamps',
    text: 'stamps'
  },
  {
    key: 'esty',
    text: 'esty'
  },
  {
    key: 'shopify',
    text: 'shopify'
  },
  {
    key: 'ebay',
    text: 'ebay'
  },
  {
    key: 'amazon',
    text: 'amazon'
  },
  {
    key: 'shipeasy',
    text: 'shipeasy'
  },
  {
    key: 'custom',
    text: 'custom'
  }
];
// sort by text
const TEMPLATES = TEMPLATES_UNSORTED.sort((a, b) => {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
});

const C = [
  {
    text: 'Stores',
    value: 'user_store_id',
    comparators: LIST_OPERATORS,
    input: {
      name: 'Stores',
      type: 'select',
      key: 'id',
      text: 'identifier',
      options: []
    },
  },
  {
    text: 'Template',
    value: 'template',
    comparators: LIST_OPERATORS,
    input: {
      name: 'Template',
      type: 'select',
      key: 'key',
      text: 'text',
      options: TEMPLATES
    }
  },
  {
    text: 'Postage Type',
    value: 'postage_type',
    comparators: LIST_OPERATORS,
    input: {
      name: 'Postage Type',
      type: 'select',
      key: 'id',
      text: 'description',
      options: []
    }
  },
  {
    text: 'Package Type',
    value: 'package_type',
    comparators: LIST_OPERATORS,
    input: {
      name: 'Package Type',
      type: 'select',
      key: 'id',
      text: 'description',
      options: []
    }
  },
  {
    text: 'Countries',
    value: 'country_code',
    comparators: LIST_OPERATORS,
    input: {
      name: 'Countries',
      type: 'select',
      key: 'code',
      text: 'name',
      options: CONSTANTS.COUNTRIES,
      lists: [
        {
          name: 'EU Countries',
          code: 'eu_countries'
        }
      ]
    }
  },
  {
    text: 'Province Code/State',
    value: 'province_code',
    comparators: STRING_OPERATORS,
    input: {
      name: 'Province Code',
      type: 'text'
    }
  },
  {
    text: 'Postal Code',
    value: 'postal_code',
    comparators: STRING_OPERATORS,
    input: {
      name: 'Postal Code',
      type: 'text'
    }
  },
  {
    text: 'Order Date',
    value: 'order_at',
    comparators: DATE_OPERATORS,
    input: {
      name: 'Days',
      type: 'number'
    }
  },
  {
    text: 'Total Weight (lbs)',
    value: 'weight',
    comparators: NUMBER_OPERATORS,
    input: {
      name: 'Weight_lbs',
      type: 'number'
    }
  },
  {
    text: 'Length (in)',
    value: 'length',
    comparators: NUMBER_OPERATORS,
    input: {
      name: 'Length_in',
      type: 'number'
    }
  },
  {
    text: 'Width (in)',
    value: 'width',
    comparators: NUMBER_OPERATORS,
    input: {
      name: 'Width_in',
      type: 'number'
    }
  },
  {
    text: 'Height (in)',
    value: 'height',
    comparators: NUMBER_OPERATORS,
    input: {
      name: 'Height_in',
      type: 'number'
    }
  },
  {
    text: 'SKU',
    value: 'sku',
    comparators: STRING_OPERATORS,
    input: {
      name: 'SKU',
      type: 'text'
    },
    hint: 'This will only apply to orders with a single line item'
  },

  {
    text: 'Item Name',
    value: 'title',
    comparators: STRING_OPERATORS,
    input: {
      name: 'Item Name',
      type: 'text'
    },
    hint: 'This will only apply to orders with a single line item'
  },

  {
    text: 'Total Value',
    value: 'value',
    comparators: NUMBER_OPERATORS,
    input: {
      name: 'Value',
      type: 'number'
    }
  },
  {
    text: '# Line Items',
    value: 'items_count',
    comparators: NUMBER_OPERATORS,
    input: {
      name: '# Items',
      type: 'number'
    }
  },
  {
    text: 'Order Tags',
    value: 'order_tags',
    comparators: LIST_OPERATORS,
    input: {
      name: 'Tag',
      type: 'select',
      key: 'id',
      text: 'name',
      options: []
    }
  },
  {
    text: 'Total Quantity',
    value: 'total_quantity',
    comparators: NUMBER_OPERATORS,
    input: {
      name: 'Total Quantity',
      type: 'number'
    }
  },
  {
    text: 'Shipping Method',
    value: 'shipping_method',
    comparators: STRING_OPERATORS,
    input: {
      name: 'Shipping Method',
      type: 'text'
    }
  },
  {
    text: 'Shipping Cost',
    value: 'shipping_cost',
    comparators: NUMBER_OPERATORS,
    input: {
      name: 'Shipping Cost',
      type: 'number'
    }
  },
  {
    text: 'Carrier',
    value: 'carrier',
    comparators: STRING_OPERATORS,
    input: {
      name: 'Carrier',
      type: 'text'
    }
  },
  {
    text: 'Is FBA',
    value: 'is_fba',
    comparators: BOOLEAN_OPERATORS,
    input: {
      name: 'Is FBA',
      type: 'boolean'
    }
  },
  {
    text: 'Address1',
    value: 'address1',
    comparators: STRING_OPERATORS,
    input: {
      name: 'Address1',
      type: 'text'
    }
  },
  {
    text: 'Address2',
    value: 'address2',
    comparators: STRING_OPERATORS,
    input: {
      name: 'Address2',
      type: 'text'
    }
  },
  {
    text: 'City',
    value: 'city',
    comparators: STRING_OPERATORS,
    input: {
      name: 'City',
      type: 'text'
    }
  },
  {
    text: 'Assigned Box',
    value: 'box_id',
    comparators: JSON_OPERATORS,
    input: {
      name: 'Box',
      type: 'select',
      key: 'id',
      text: 'description',
      options: []
    }
  },
  {
    text: 'Line Item Names',
    value: 'item_names',
    comparators: JSON_OPERATORS,
    input: {
      name: 'Item Names',
      type: 'text'
    }
  },
  {
    text: 'Line Item SKUs',
    value: 'item_skus',
    comparators: JSON_OPERATORS,
    input: {
      name: 'Item SKUs',
      type: 'text'
    }
  }
];

// sort by criteria text
const CRITERIA = C.sort((a, b) => {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
});

const PRICING_CRITERIA = [
  {
    text: 'Postage Type',
    value: 'postage_type',
    comparators: LIST_OPERATORS,
    input: {
      name: 'Postage Type',
      type: 'select',
      key: 'id',
      text: 'description',
      options: []
    }
  },
  {
    text: 'Package Type',
    value: 'package_type',
    comparators: LIST_OPERATORS,
    input: {
      name: 'Package Type',
      type: 'select',
      key: 'id',
      text: 'description',
      options: []
    }
  },
  {
    text: 'Countries',
    value: 'country_code',
    comparators: LIST_OPERATORS,
    input: {
      name: 'Countries',
      type: 'select',
      key: 'code',
      text: 'name',
      options: CONSTANTS.COUNTRIES,
      lists: [
        {
          name: 'EU Countries',
          value: 'eu_countries'
        }
      ]
    }
  },
  {
    text: 'Province Code/State',
    value: 'province_code',
    comparators: STRING_OPERATORS,
    input: {
      name: 'Province Code',
      type: 'text'
    }
  },
  {
    text: 'Total Weight (lbs)',
    value: 'weight',
    comparators: NUMBER_OPERATORS,
    input: {
      name: 'Weight_lbs',
      type: 'number'
    }
  },
  {
    text: 'Monthly Count',
    value: 'monthly_count',
    comparators: NUMBER_OPERATORS,
    input: {
      name: 'Weight_lbs',
      type: 'number'
    }
  },
  {
    text: 'Total Value',
    value: 'value',
    comparators: NUMBER_OPERATORS,
    input: {
      name: 'Value',
      type: 'number'
    }
  },
  {
    text: 'Combined Dimensions (in)',
    value: 'combined_dimensions',
    comparators: NUMBER_OPERATORS,
    input: {
      name: 'Combined Dimensions',
      type: 'number'
    }
  },
  {
    text: 'Is FBA',
    value: 'is_fba',
    comparators: BOOLEAN_OPERATORS,
    input: {
      name: 'Is FBA',
      type: 'boolean'
    }
  },
  {
    text: 'Needs Postage',
    value: 'needs_postage',
    comparators: BOOLEAN_OPERATORS,
    input: {
      name: 'Needs Postage',
      type: 'boolean'
    }
  }
];

const filters = {
  CRITERIA: CRITERIA,
  PRICING_CRITERIA: PRICING_CRITERIA,
  LIST_OPERATOR_VALUES: LIST_OPERATOR_VALUES,
  defaultFilter() {
    return {
      id: null,
      name: null,
      filter_criteria: [],
      delete: false
    };
  },
  defaultCriterion() {
    return {
      id: null,
      field: null,
      comparator: null,
      value: null
    };
  },
  defaultList() {
    return {
      id: null,
      name: null,
      field: null,
      type: null,
      value: []
    };
  },
  getCriterionName(field) {
    const obj = _find(CRITERIA.concat(PRICING_CRITERIA), { value: field })

    return obj ? obj.text : field
  }
};

export default filters;
