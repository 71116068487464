<template>
  <StallionDialog
    v-model="show"
    :width="500"
    :disabled="disabled"
    title="Print Batch Label"
  >
    <!-- <v-btn
      v-if="button"
      color="primary"
      :disabled="disabled"
      v-on="on"
    >
      <v-icon
        small
        dark
      >
        mdi-printer
      </v-icon>Print
    </v-btn> -->

    <template #content>
      <v-card-text>
        <v-layout column>
          <v-flex
            xs12
            text-center
          >
            <h3>
              {{ labelsCount }} batch
              {{ labelsCount | pluralize(' label') }} ready to be printed
            </h3>
          </v-flex>
        </v-layout>
      </v-card-text>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        text
        download
        :loading="dowload_loading"
        :disabled="dowload_loading"
        @click.prevent="download(batches[0].batch_code)"
      >
        <v-icon
          small
          dark
        >
          mdi-download
        </v-icon>
        Download
      </v-btn>

      <v-btn
        color="primary"
        :loading="print_loading"
        :disabled="print_loading"
        @click="print(batches[0].batch_code)"
      >
        <v-icon
          small
          dark
        >
          mdi-printer
        </v-icon>
        Print
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    batches: {
      type: Array,
      default: () => []
    },
    disabled: {
      default: false,
      type: Boolean
    },
    button: {
      default: true,
      type: Boolean
    },
    show: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      print_loading: false,
      dowload_loading: false
    };
  },
  computed: {
    labelsCount: function() {
      return this.batches.length;
    }
  },
  methods: {
    print: function(batch_code) {
      alert(123);
      this.print_loading = true;

      this.$http
        .get('/batches/' + batch_code + '/label')
        .then(response => {
          this.printFileUrl(response.data);
          this.print_loading = false;
        });
    },
    download: function(batch_code) {
      this.dowload_loading = true;

      this.$http
        .get('/batches/' + batch_code + '/label')
        .then(response => {
          this.downloadFileUrl(response.data, batch_code + '.pdf');
          this.dowload_loading = false;
        });
    }
  }
};
</script>
