<template>
  <div>
    <v-dialog
      v-model="loading"
      persistent
      width="300"
    >
      <v-card dark>
        <v-card-text>
          <v-layout
            justify-center
            class="pt-3"
          >
            <atom-spinner
              :animation-duration="2000"
              :size="100"
              :color="'#f9ac40'"
            />
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          :disabled="disablePrint"
          v-on="on"
        >
          Print
          <v-icon right>
            mdi-chevron-up
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          :disabled="disablePrint"
          @click="buildPrinter('label_only')"
        >
          <v-list-item-icon>
            <v-icon
              small
              dense
              left
            >
              mdi-label
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shipment Label</v-list-item-title>
        </v-list-item>

        <v-list-item
          :disabled="disablePrint"
          @click="buildPrinter('packing_slip')"
        >
          <v-list-item-icon>
            <v-icon
              small
              dense
              left
            >
              mdi-package-variant-closed
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Packing Slip</v-list-item-title>
        </v-list-item>

        <v-list-item
          :disabled="disablePrint"
          @click="buildPrinter('label_with_packing_slip')"
        >
          <v-list-item-icon>
            <v-icon
              small
              dense
              left
            >
              mdi-package-variant
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Packing Slip With Label</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <print-shipments
      v-model="showPrint"
      :shipments="shipments"
      :print-type="printType"
      @update="emitUpdate()"
    />
  </div>
</template>

<script>
import _map from 'lodash/map';
import PERMISSIONS from '@/constants/client-permissions';
import PrintShipments from '@/components/main/shipments/action-bar/PrintShipments.vue';
import { AtomSpinner } from 'epic-spinners';

export default {
  components: {
    PrintShipments,
    AtomSpinner
  },
  props: {
    batches: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      PERMISSIONS: PERMISSIONS,
      showPrint: false,
      printType: 'label_only',
      showPacking: false,
      shipments: [],
      cancelSource: null,
      loading: false
    };
  },
  computed: {
    disablePrint() {
      return this.batches.length === 0 || !this.can(PERMISSIONS.SHIPPING);
    }
  },
  watch: {
    batches: {
      deep: true,
      handler() {
        // reset shipments if selected batches have been changed
        this.shipments = [];
      }
    }
  },

  methods: {
    emitUpdate() {
      this.$emit('update');
    },

    async buildPrinter(printType) {
      // initial load of shipments
      if (this.shipments.length == 0) {
        await this.getShipments();
      }

      // check if there are shipments in selected batches
      if (this.shipments.length == 0) {
        this.errorMessage('No shipments found in selected batches');
        return;
      }

      // build the printer type
      switch (printType) {
        case 'label_only':
        case 'label_with_packing_slip':
          this.printType = printType;
          this.showPrint = true;
          break;

        case 'packing_slip':
          this.showPacking = true;
          break;

        default:
      }
    },

    async getShipments() {
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, stop active search');
      }

      this.cancelSource = axios.CancelToken.source();

      this.shipments = [];
      this.loading = true;
      await this.$http
        .request(`/batches/shipments`, {
          method: 'get',
          params: {
            ids: _map(this.batches, 'id')
          },
          cancelToken: this.cancelSource.token
        })
        .then((response) => {
          this.shipments = response.data;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
