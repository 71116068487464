<template>
  <v-main>
    <v-row
      container--fluid
      fill-height
    >
      <v-col
        cols="12"
        md="4"
        class="image-column"
        style="background-color: #f9ac40; height: 100vh"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="px-10 mb-12"
            >
              <a href="https://stallionexpress.ca">
                <img
                  class="ml-0 img-responsive"
                  :src="asset('images/StallionExpress-logo-white.png')"
                  style="width: 150px"
                >
              </a>
            </v-col>
            <v-col
              cols="12"
              class="px-10 py-0"
              style="text-align: left"
            >
              <h1
                class="font-weight-bold"
                style="color: white; font-size: 28px"
              >
                Ship Faster, Smarter, and Cheaper with Stallion!
              </h1>
            </v-col>
            <v-col
              cols="12"
              class="px-10 py-0"
            >
              <img
                class="mx-auto img-responsive login-image"
                :src="
                  bc_registration
                    ? asset('images/packaging-depot-white.jpg')
                    : asset('images/how-it-works.png')
                "
                style="width: 320px"
              >
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col
        cols="12"
        md="8"
        style="height: 100vh"
      >
        <v-card class="elevation-0 px-3">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-right"
              >
                <div>
                  <p>
                    Need Help?
                    <a
                      style="font-weight: 700; text-decoration: none"
                      target="_blank"
                      @click="toggleBeacon()"
                    >Contact Us</a>
                  </p>
                </div>
              </v-col>
              <v-col
                cols="12"
                class="pb-12 text-right"
              >
                <div class="float-right">
                  <weglot-switcher />
                </div>
              </v-col>

              <v-col cols="12">
                <h2 class="font-weight-bold">
                  Registration
                </h2>
                <p>Create your Stallion Express account today!</p>
              </v-col>
            </v-row>
          </v-container>

          <v-form @submit.prevent="register()">
            <v-container>
              <v-row>
                <v-container class="py-0">
                  <v-alert
                    v-for="(error, key1) in errors"
                    :key="key1"
                    dark
                    color="error"
                    class="login-field"
                    :value="error != ''"
                    icon="warning"
                  >
                    {{ error[0] }}
                  </v-alert>
                </v-container>

                <v-container>
                  <v-text-field
                    v-model="email"
                    v-validate="'required|email'"
                    autofocus
                    label="Email"
                    data-vv-name="email"
                    :error-messages="errs.collect('email')"
                    type="email"
                    name="email"
                    dense
                    data-vv-validate-on="blur"
                    class="login-field"
                  />
                </v-container>

                <v-container>
                  <v-text-field
                    ref="password"
                    v-model="password"
                    v-validate="'required|min:8|verify_password'"
                    label="Password"
                    data-vv-name="password"
                    :error-messages="errs.collect('password')"
                    type="password"
                    name="password"
                    data-vv-validate-on="blur"
                    dense
                    class="login-field"
                  />
                </v-container>

                <v-container>
                  <v-text-field
                    ref="confirm"
                    v-model="password_confirmation"
                    v-validate="'confirmed:password'"
                    label="Confirm Password"
                    data-vv-name="confirm"
                    :error-messages="errs.collect('confirm')"
                    type="password"
                    name="confirm"
                    data-vv-validate-on="blur"
                    dusk="register-confirm"
                    dense
                    class="login-field"
                  />
                </v-container>
                <v-container v-if="hasReference">
                  <h2 class="box-title m-b-20">
                    You have been referred!
                  </h2>
                </v-container>

                <v-container>
                  <v-text-field
                    v-model="myReference"
                    v-validate="''"
                    autofocus
                    label="Referral Code"
                    data-vv-name="myReference"
                    :error-messages="errs.collect('myReference')"
                    type="text"
                    name="myReference"
                    dense
                    data-vv-validate-on="blur"
                    class="login-field"
                  />
                </v-container>

                <v-container>
                  <!-- <vue-recaptcha
                  :sitekey="siteKey"
                  @verify="onVerify"
                  @expired="form.robot = false"
                /> -->
                  <div id="turnstile-container" />
                </v-container>

                <v-container>
                  <v-layout align-left>
                    <v-flex
                      xs12
                      text-left
                    >
                      <v-btn
                        :loading="loading2"
                        :disabled="loading2"
                        color="primary"
                        class="px-12"
                        type="submit"
                        name="register-button"
                      >
                        Get Started
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>

                <v-container>
                  <v-layout
                    align-center
                    class="pt-12"
                  >
                    <v-flex
                      xs12
                      text-left
                    >
                      <p>
                        Already registered?
                        <router-link
                          style="font-weight: 700; text-decoration: none"
                          to="/login"
                          aria-expanded="false"
                        >
                          Return to Login
                        </router-link>
                      </p>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
// import { VueRecaptcha } from 'vue-recaptcha';
export default {
  metaInfo: {
    title: 'Register | Stallion Express'
  },
  // components: { VueRecaptcha },
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      dateFormat: 'yyyy-MM-dd',
      dateInputClass: 'form-control',
      user: {
        gender: 'M',
        position_id: 4
      },
      errors: [],
      password: '',
      username: '',
      email: '',
      password_confirmation: '',
      myReference: '',
      loading2: false,
      hasReference: false,
      secret: btoa('pramod_davison'),
      bc_registration: false,
      form: {
        robot: false
      },
      siteKey: window.Laravel.google_recaptcha,
      turnstile_token: ''
    };
  },
  created() {
    const queryParams = new URLSearchParams(window.location.search);
    const utmSource = queryParams.get('utm_source');
    const utmMedium = queryParams.get('utm_medium');
    const utmCampaign = queryParams.get('utm_campaign');

    if (utmSource) {
      this.$cookies.set('utm_source', utmSource, '7d');
    }
    if (utmMedium) {
      this.$cookies.set('utm_medium', utmMedium, '7d');
    }
    if (utmCampaign) {
      this.$cookies.set('utm_campaign', utmCampaign, '7d');
    }
  },
  mounted() {
    const self = this;
    window.turnstile.ready(function () {
      window.turnstile.render('#turnstile-container', {
        sitekey: '0x4AAAAAAARUQpsH0sRYSrWa',
        theme: 'light',
        callback: function(token) {
          self.turnstile_token = token;
          console.log(`Challenge Success ${token}`);
        },
      });
    });

    if (this.$route.params.id !== undefined) {
      if (this.$route.params.id === this.secret) {
        this.bc_registration = true;
      } else {
        var date = new Date();
        date.setDate(date.getDate() + 30);
        localStorage.reference = this.$route.params.id;
        localStorage.referenceExpires = date.toISOString();
      }
    }

    if (localStorage.reference && localStorage.referenceExpires) {
      if (new Date(localStorage.referenceExpires) > new Date()) {
        this.myReference = localStorage.reference;
        this.hasReference = true;
      }
    }
  },
  methods: {
    onVerify: function (response) {
      if (response) this.form.robot = true;
    },
    register() {
      // if (window.Laravel.app_env == 'production' && !this.form.robot) {
      //   swal({
      //     title: 'Error!',
      //     text: 'Please verify the captcha.',
      //     icon: 'error'
      //   });
      //   return;
      // }
        this.loading2 = true;
        this.$validator.validate().then((result) => {
          if (result) {
            var app = this;
            this.getCsrf().then(() => {
              this.$http
                .post('auth/register', {
                  username: app.email,
                  email: app.email,
                  password: app.password,
                  password_confirmation: app.password_confirmation,
                  ref: app.myReference,
                  utm_source: this.$cookies.get('utm_source'),
                  utm_medium: this.$cookies.get('utm_medium'),
                  utm_campaign: this.$cookies.get('utm_campaign'),
                  turnstile_token: app.turnstile_token
                })
                .then(() => {
                  if (localStorage.reference && localStorage.referenceExpires) {
                    localStorage.removeItem('reference');
                    localStorage.removeItem('referenceExpires');
                  }

                  this.$cookies.remove('utm_source');
                  this.$cookies.remove('utm_medium');
                  this.$cookies.remove('utm_campaign');

                  if (typeof fbq !== 'undefined') {
                    // eslint-disable-next-line no-undef
                    fbq('track', 'CompleteRegistration');
                  }

                  this.login(app.email, app.password);
                })
                .catch((resp) => {
                  console.log(resp.response.data.errors);
                  app.errors = resp.response.data.errors;
                  // app.errors = resp.response.data.errors;
                  if (resp.response.status == 403) {
                    swal({
                      title: resp.response.statusText || 'Error!',
                      text:
                        resp.response.data.message ||
                        'Registration can only be done on production site.',
                      icon: 'error'
                    });
                  }
                })
                .finally(() => {
                  this.loading2 = false;
                });
            });
          } else {
            this.loading2 = false;
          }
        });
      
    }
  }
};
</script>

<style scoped>
.v-application p {
  margin-bottom: 0px !important;
}

@media only screen and (min-width: 1440px) {
  .login-image {
    width: 450px !important;
  }

  .contact-us {
    margin-bottom: 100px !important;
  }

  .stallion-title {
    margin-bottom: 50px !important;
  }
}

.login-field {
  max-width: 400px;
}

@media only screen and (max-width: 960px) {
  .login-image {
    display: none !important;
  }

  .image-column {
    height: 40vh !important;
  }
}
</style>
