<template>
  <!-- <div>open batches</div> -->
  <v-list-group
    v-if="batches.open_batches.length > 0"
    :value="true"
  >
    <template #activator>
      <v-list-item-title>
        Active Batches ({{ batches.open_batches.length }})
      </v-list-item-title>
    </template>
    <v-list-item
      v-for="batch in batches.open_batches"
      :key="batch.id"
      dense
      router
      exact
      :to="'/batches/' + batch.id"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{ batch.id }}
          <span v-if="batch.name"> - {{ batch.name }}</span>
          <span
            class="text-primary font-weight-bold"
          >({{ batch.shipments_count }})</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(['batches']),
    cart: {
      get() {
        return this.$store.state.cart;
      },
      set(value) {
        this.$store.state.cart = value;
      }
    },
    isAdminPanel: function () {
      return this.$router.history.current.meta?.admin;
    }
  },

  mounted() {
    this.$store.dispatch('batches/getOpenBatches');
  },
  methods: {}
};
</script>