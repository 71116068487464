<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <!-- <v-layout align-center class="mb-4 hidden-sm-and-down" v-if="!condensed">
          <v-flex xs12 text-center>
            <v-pagination v-model="pagination.page" :length="numPages" :total-visible="7" circle></v-pagination>
          </v-flex>
        </v-layout>-->

        <v-card>
          <div v-if="condensed">
            <v-card-title>
              <span class="text-h3">Shipments</span>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                @input="submitSearch()"
              />
            </v-card-title>
          </div>
          <v-data-table
            v-model="main.selectedShipments"
            class="table-striped elevation-3"
            :headers="headers"
            :items="shipments"
            item-key="id"
            :server-items-length="totalShipments"
            :loading="loading"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.itemsPerPage"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
            :footer-props="{
              'items-per-page-options': [50, 100, 200, 500]
            }"
            @click:row="rowClick"
          >
            <template #item.label_date="{ item }">
              {{ item.label_date | date_pretty }}
              <!-- <br />
              ({{ item.created_at | moment("from") }})-->
            </template>

            <template #item.action="{ item }">
              <v-layout justify-center>
                <v-btn
                  color="primary"
                  small
                  @click.prevent="printLabel(item.id)"
                >
                  <v-icon
                    small
                    dark
                  >
                    mdi-printer
                  </v-icon>
                </v-btn>&nbsp;&nbsp;&nbsp;
                <v-btn
                  color="primary"
                  download
                  small
                  @click.prevent="downloadLabel(item.id)"
                >
                  <v-icon
                    small
                    dark
                  >
                    mdi-download
                  </v-icon>
                </v-btn>
              </v-layout>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    admin: {
      default: false,
      type: Boolean
    },
    batchId: {
      default: null,
      type: String
    },
    batched: {
      default: null,
      type: Boolean
    },
    currentLocationId: {
      type: String,
      default: null
    },

    closeoutId: {
      type: String,
      default: null
    },
    statusId: {
      type: Number,
      default: null
    },
    filter: {
      type: String,
      default: null
    },
    pending: {
      default: null,
      type: Boolean
    },
    condensed: {
      default: false,
      type: Boolean
    },
    postageTypes: {
      type: Array,
      default: () => []
    },
    statusIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      drawer: null,
      searchID: null,
      searchEmail: null,
      user_id: null,
      email: null,
      userIDs: [],
      userEmails: [],
      users: [],
      pagination: {
        page: 1,
        itemsPerPage: 25,
        sortBy: 'created_at',
        descending: true
      },
      loading: false,
      totalShipments: 0,
      headers: [
        {
          text: 'Label Date',
          value: 'label_date'
        },
        {
          text: 'Count',
          value: 'count'
        },
        {
          text: 'Start',
          value: 'start'
        },
        {
          text: 'End',
          value: 'end'
        },
        {
          text: '',
          value: 'action',
          sortable: false,
          align: 'center'
        }
      ],

      search: '',
      postage_types_d: this.postageTypes,
      postage_list: [],
      start_date: '',
      end_date: '',
      menu1: '',
      menu2: '',
      timer: null,
      numPages: 0,
      shipments: []
    };
  },
  computed: {
    ...mapState(['main'])
  },
  watch: {
    user_id: function(val) {
      if (val) {
        var index = this.users.map(e => e.id).indexOf(val);

        this.email = this.users[index].email;
      } else {
        this.email = null;
      }
    },
    email: function(val) {
      if (val) {
        var index = this.users.map(e => e.email).indexOf(val);

        this.user_id = this.users[index].id;
      } else {
        this.user_id = null;
      }
    },
    filter: function() {
      console.log('filter change');
      this.pagination.page = 1;
      this.updateShipments();
    },
    pagination: {
      handler() {
        console.log('pgnation change');
        this.updateShipments();
      },
      deep: true
    }
  },
  mounted() {
    Event.listen('update-return-labels', () => {
      this.updateShipments();
    });

    this.updateShipments();
  },
  methods: {
    rowClick(item) {
      // if (!this.$vuetify.breakpoint.lgAndUp) {
      //   expanded = !expanded;
      // } else {
      //   this.main.selectedShipments = [item];
      // }
      this.main.selectedShipments = [item];
    },
    printLabel: function(id) {
      this.print_loading = true;

      this.$http
        .get(`/admin/return-labels/downloadlabel/${id}`)
        .then(response => {
          this.printFileUrl(response.data);
          this.print_loading = false;
        })
        .catch(() => {
        })
        .finally(() => {
          this.print_loading = false;
        });
    },
    downloadLabel: function(id) {
      this.dowload_loading = true;

      this.$http
        .get(`/admin/return-labels/downloadlabel/${id}`)
        .then(response => {
          this.downloadFileUrl(response.data, id+'.pdf');
          this.dowload_loading = false;
        })
        .finally(() => {
          this.dowload_loading = false;
        });
    },
    updateShipments() {
      this.shipments = [];
      this.totalShipments = 0;
      this.main.selectedShipments = [];
      this.getShipments().then(data => {
        this.shipments = data.items;
        this.totalShipments = data.total;
        this.numPages = data.pages;
      });
    },
    getShipments() {
      console.log('get shipments');
      this.loading = true;
      return new Promise(resolve => {
        const { sortBy, descending, itemsPerPage } = this.pagination;

        let items = [];
        let total = 0;
        let pages = 0;

        this.$http
          .get(
            `/admin/return-labels?per_page=${itemsPerPage}&sort_by=${sortBy}&desc=${descending}&search=${
              this.search
            }`
          )
          .then(response => response.data)
          .then(response => {
            items = response.data;
            total = response.total;
            pages = response.last_page;

            this.loading = false;
            resolve({
              items,
              total,
              pages
            });
          });
      });
    },
    submitSearch() {
      clearTimeout(this.timer);
      var ms = 500;
      var self = this;
      this.pagination.page = 1;
      this.timer = setTimeout(function() {
        self.updateShipments();
      }, ms);
    }
  }
};
</script>
