<template>
  <v-main>
    <confirm-password-dialog
      v-model="showConfirmPasswordDialog"
      @onSuccess="enable2FA"
    />

    <two-factor-confirm-dialog
      v-model="showConfirm2faDialog"
      @onSuccess="fetchUser"
    />

    <v-row
      container--fluid
      fill-height
      style="overflow-y: scroll"
    >
      <v-col
        cols="12"
        md="4"
        class="image-column"
        style="background-color: #f9ac40; height: 100vh"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="px-10 mb-12"
            >
              <img
                class="ml-0 img-responsive"
                :src="asset('images/StallionExpress-logo-white.png')"
                style="width: 150px"
              >
            </v-col>
            <v-col
              cols="12"
              class="px-10 py-0"
              style="text-align: left"
            >
              <h1
                class="font-weight-bold"
                style="color: white; font-size: 28px"
              >
                Ship Faster, Smarter, and Cheaper with Stallion!
              </h1>
            </v-col>
            <v-col
              cols="12"
              class="px-10 py-0"
            >
              <img
                class="img-responsive login-image"
                :src="asset('images/login-graphic.png')"
                style="width: 310px"
              >
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col
        cols="12"
        md="8"
        class="pa-0"
        style="height: 100vh"
      >
        <v-card class="elevation-0 pa-5 px-12">
          <v-container class="pb-0">
            <v-row>
              <v-col
                cols="12"
                class="text-right"
              >
                <div>
                  <p>
                    Need Help?
                    <a
                      style="font-weight: 700; text-decoration: none"
                      target="_blank"
                      @click="toggleBeacon()"
                    >Contact Us</a>
                  </p>
                </div>
              </v-col>
              <v-col
                cols="12"
                class="pb-12 text-right"
              >
                <div class="float-right">
                  <weglot-switcher />
                </div>
              </v-col>

              <v-col cols="12">
                <h2 class="font-weight-bold">
                  Protect Your Stallion Express Account
                </h2>
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row>
              <v-col cols="12">
                Please enable two-factor authentication for your Stallion
                Express account by using a secondary device to verify your
                identity.
              </v-col>
              <v-col cols="12">
                You can use
                <a
                  href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                  target="_blank"
                >
                  Google Authenticator for Apple
                </a>
                or
                <a
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
                  target="_blank"
                >
                  Google Authenticator for Android.</a>
              </v-col>

              <v-col
                cols="12"
                class="py-0 d-flex flex-row"
              >
                <v-btn
                  color="primary"
                  class="px-6"
                  :loading="loading"
                  @click="enable2FA"
                >
                  Enable 2FA
                </v-btn>
                <div class="py-2 px-6">
                  <p>
                    Or
                    <a
                      style="font-weight: 700; text-decoration: none"
                      @click.prevent="logout()"
                    >Log me out</a>
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import ConfirmPasswordDialog from './confirm-password-dialog.vue';
import TwoFactorConfirmDialog from './two-factor-confirm-dialog.vue';
export default {
  components: {
    ConfirmPasswordDialog,
    TwoFactorConfirmDialog
  },
  data() {
    return {
      showConfirmPasswordDialog: false,
      showConfirm2faDialog: false,
      loading: false
    };
  },
  methods: {
    enable2FA() {
      this.loading = true;
      this.$http
        .post('/auth/user/two-factor-authentication')
        .then(() => {
          this.showConfirm2faDialog = true;
        })
        .catch((err) => {
          if (err.response.status === 423) {
            this.showConfirmPasswordDialog = true;
          } else {
            this.errorMessage('Unknown error occurred!');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchUser() {
      this.loading = true;
      this.getUser()
        .then(() => {
          this.$router.push('/');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
