<template>
  <div>
    <v-form
      class="very-dense"
      @submit.prevent
    >
      <!-- Search -->
      <v-row
        dense
      >
        <!-- <v-col>
          <v-select
            v-model="searchOrderFilters.search_type"
            dense
            outlined
            :items="['Order ID', 'Customer Name', 'Item Name', 'Item SKU']"
            
          />
        </v-col> -->
        <v-col>
          <v-text-field
            v-show="!showAdvanceSearchLocal"
            v-model="searchOrderFilters.search"
            prepend-inner-icon="mdi-magnify"
            :label="`Search`"
            hide-details
            
            clearable
            outlined
            dense
            @keyup.native.enter="applySearch"
          />
        </v-col>
        <!-- <v-col
          v-if="selectionType == 'file'"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            type="submit"
            depressed
            :loading="loading"
            :disabled="loading"
            @click.prevent="applySearch()"
          >
            Search
          </v-btn>
        </v-col>

        <v-col
          cols="3"
          md="3"
        >
          <v-btn
            color="primary"
            type="submit"
            depressed
            :loading="loading"
            :disabled="loading"
            @click.prevent="applySearch()"
          >
            Search
          </v-btn>
          <v-btn
            v-if="userStores.user_stores.length >= 1"
          
            color="primary"
            outlined
            :loading="userStores.updating"
            :disabled="userStores.updating || !can('CLIENT.STORES')"
            @click="updateStores()"
          >
            <v-icon small>
              mdi-reload
            </v-icon>
            Sync
          </v-btn>
        </v-col> -->
        <v-col
          v-if="!hideAdvanceSearchButton"
          cols="auto"
        >
          <v-tooltip
            v-model="show2"
            bottom
          >
            <template #activator="{ on }">
              <v-btn
                outlined
                color="primary"
                v-on="on"
                @click="showAdvanceSearchLocal = !showAdvanceSearchLocal"
              >
                <span v-if="showAdvanceSearchLocal">Advanced Search</span>
                <span v-else>Advanced Search</span>
                <v-icon
                  v-if="showAdvanceSearchLocal"
                  small
                  right
                >
                  mdi-chevron-up
                </v-icon>
                <v-icon
                  v-else
                  small
                  right
                >
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </template>
            <span v-if="showAdvanceSearchLocal">Hide Advanced Filters</span>
            <span v-else>Show Advanced Filters</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <!-- Providers -->
      <v-row
        v-if="showAdvanceSearchLocal && selectionType == 'store'"
        dense
      >
        <v-col cols="6">
          <v-autocomplete
            v-model="searchOrderFilters.filter_id"
            prepend-inner-icon="mdi-filter"
            dense
            clearable
            :items="filters.filters"
            label="Filter"
            item-text="name"
            item-value="id"
            outlined
            multiple
            @keyup.native.enter="applySearch"
          />
        </v-col>
    

        <v-col cols="6">
          <v-autocomplete
            v-model="searchOrderFilters.user_store_id"
            prepend-inner-icon="mdi-store"
            dense
            clearable
            :items="userStores.user_stores"
            label="Stores"
            multiple
            :item-text="getStoreName"
            item-value="id"
            :search-input.sync="search_input"
            outlined
            @keyup.native.enter="applySearch"
          />
        </v-col>
      </v-row>

      <v-row
        v-if="showAdvanceSearchLocal"
        dense
      >
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="searchOrderFilters.order_id"
           
            label="Order ID"
            hide-details
            
            clearable
            outlined
            dense
            @keyup.native.enter="applySearch"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="searchOrderFilters.name"
           
            label="Recipient Name"
            hide-details
            
            clearable
            outlined
            dense
            @keyup.native.enter="applySearch"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="searchOrderFilters.item_sku"
           
            label="Item SKU"
            hide-details
            
            clearable
            outlined
            dense
            @keyup.native.enter="applySearch"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field
            v-model="searchOrderFilters.item_name"
           
            label="Item Name"
            hide-details
            
            clearable
            outlined
            dense
            @keyup.native.enter="applySearch"
          />
        </v-col>
      </v-row>


      <!-- Date -->
      <v-row
        v-if="showAdvanceSearchLocal"
        dense
      >
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="searchOrderFilters.weight_min"
            prepend-inner-icon="mdi-weight-pound"
            label="Min"
            hide-details
            
            clearable
            outlined
            dense
            @keyup.native.enter="applySearch"
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="searchOrderFilters.weight_max"
            prepend-inner-icon="mdi-weight-pound"
            label="Max"
            hide-details
            
            clearable
            outlined
            dense
            @keyup.native.enter="applySearch"
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            v-model="order_date_type_local"
            dense
            outlined
            :items="date_types"
            label="Order Date"
          />
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="start_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="searchOrderFilters.order_date_min"
                prepend-inner-icon="mdi-calendar-search"
                outlined
                dense
                style="padding: 0"
                label="Start Date"
                readonly
                clearable
                hide-details
                @keyup.native.enter="applySearch"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="searchOrderFilters.order_date_min"
              @input="start_date_menu = false"
              @change="applySearch()"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-menu
            v-model="end_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="searchOrderFilters.order_date_max"
                prepend-inner-icon="mdi-calendar-search"
                outlined
                dense
                style="padding: 0"
                label="End Date"
                readonly
                clearable
                hide-details
                @keyup.native.enter="applySearch"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="searchOrderFilters.order_date_max"
              @input="end_date_menu = false"
              @change="applySearch()"
            />
          </v-menu>
        </v-col>
      </v-row>
      

      <!-- Countries -->
      <v-row
        v-if="showAdvanceSearchLocal"
        dense
      >
        <!-- Value -->
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="searchOrderFilters.value_min"
            prepend-inner-icon="mdi-currency-usd"
            label="Min"
            hide-details
            
            clearable
            outlined
            dense
            @keyup.native.enter="applySearch"
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="searchOrderFilters.value_max"
            prepend-inner-icon="mdi-currency-usd"
            label="Max"
            hide-details
            
            clearable
            outlined
            dense
            @keyup.native.enter="applySearch"
          />
        </v-col>
       
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="order_tag_local"
            prepend-inner-icon="mdi-tag"
            clearable
            data-vv-name="order_tag"
            :items="tags.tags"
            label="Tags"
            multiple
            item-text="name"
            item-value="id"
            dense
            outlined
            @keyup.native.enter="applySearch"
          >
            <template #item="{ item, on, attrs }">
              <v-list-item
                :style="attrs.inputValue ? { backgroundColor: '#D3D3D3' } : ''"
                v-on="on"
              >
                <v-list-item-action>
                  <v-simple-checkbox
                    :value="attrs.inputValue"
                    color="primary"
                    :ripple="false"
                    v-on="on"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    :style="{
                      backgroundColor: item.color_code,
                      padding: '10px',
                      borderRadius: '7px'
                    }"
                  >
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <!-- Total Quantity -->
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="searchOrderFilters.quantity_min"
           
            label="Min Quantity"
            hide-details
            
            clearable
            outlined
            dense
            @keyup.native.enter="applySearch"
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="searchOrderFilters.quantity_max"
         
            label="Max Quantity"
            hide-details
            
            clearable
            outlined
            dense
            @keyup.native.enter="applySearch"
          />  
        </v-col>
      </v-row>
      <v-row
        v-if="showAdvanceSearchLocal"
        dense
      >
        <v-col
          cols="12"
          md="2"
        >
          <v-select
            v-model="country_code_type_local"
            dense
            outlined
            :items="['Domestic', 'International', 'US', 'Custom']"
            label="Countries"
          />
        </v-col>
    
        <v-col
          cols="12"
          md="3"
        >
          <v-autocomplete
            v-model="searchOrderFilters.country_code"
            prepend-inner-icon="mdi-earth"
            :disabled="country_code_type_local != 'Custom'"
            clearable
            data-vv-name="country_code"
            :items="countries"
            label="Country"
            multiple
            item-text="name"
            item-value="code"
            :search-input.sync="country_input"
            dense
            outlined
            @keyup.native.enter="applySearch"
          />
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-switch
            v-model="searchOrderFilters.country_code_inclusive"
            dense
            :disabled="country_code_type_local != 'Custom'"
            :value="true"
            :label="
              searchOrderFilters.country_code_inclusive == true
                ? 'Inclusive'
                : 'Exclusive'
            "
          />
        </v-col>
      </v-row>
      <v-row
        v-if="showAdvanceSearchLocal"
        dense
      >
        <v-col
          cols="12"
          md="12"
          class="text-center"
        >
          <v-btn
            
            color="primary"
            
            @click="clear"
          >
            <v-icon left>
              mdi-restart
            </v-icon>Clear
          </v-btn>
      
          <v-btn
            color="primary"
            
            
            @click="applySearch"
          >
            <v-icon left>
              mdi-magnify
            </v-icon> Search
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CONSTANTS from '@/constants/index';
export default {
  props: {
    selectionType: {
      type: String,
      default: 'store'
    },
    searchOrderFilters: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default: () => []
    },
    showAdvanceSearch: {
      type: Boolean,
      default: false
    },
    hideAdvanceSearchButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show2: null,
      show_edit: false,
      search_input: null,
      country_input: null,
      end_date_menu: null,
      start_date_menu: null,
      date_types: [
        { text: 'Today', value: 'Today' },
        { text: 'Yesterday', value: 'Yesterday' },
        { text: '1 Week', value: 7 },
        { text: '2 Weeks', value: 14 },
        { text: '3 Weeks', value: 21 },
        { text: '1 Month', value: 28 },
        { text: '2 Month', value: 48 },
        { text: '3 Month', value: 60 },
        { text: 'Custom Range', value: 'Custom Range' }
      ],
      countries: CONSTANTS.COUNTRIES,
      shiftKeyOn: false,
      options: {
        sortBy: ['row'],
        sortDesc: [false]
      },
      order_tag_local: [],
      showAdvanceSearchLocal: false
    };
  },
  computed: {
    ...mapState(['filters', 'userStores', 'tags']),
    order_date_type_local: {
      get() {
        return this.searchOrderFilters.order_date_type;
      },
      set(order_date_type) {
        this.searchOrderFilters.order_date_type = order_date_type;

        this.searchOrderFilters.order_date_min = null;
        this.searchOrderFilters.order_date_max = null;

        if (order_date_type === 'Today') {
          this.searchOrderFilters.order_date_min =
            moment().format('YYYY-MM-DD');
          this.searchOrderFilters.order_date_max =
            moment().format('YYYY-MM-DD');
        } else if (order_date_type === 'Yesterday') {
          this.searchOrderFilters.order_date_min = moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD');

          this.searchOrderFilters.order_date_max =
            moment().format('YYYY-MM-DD');
          // this.searchOrderFilters.order_date_max = moment()
          //   .subtract(1, 'days')
          //   .format('YYYY-MM-DD');
        } else if (order_date_type > 0) {
          this.searchOrderFilters.order_date_min = moment()
            .subtract(order_date_type, 'days')
            .format('YYYY-MM-DD');
          this.searchOrderFilters.order_date_max =
            moment().format('YYYY-MM-DD');
        }
      }
    },
    country_code_type_local: {
      get() {
        return this.searchOrderFilters.country_code_type;
      },
      set(country_code_type) {
        this.searchOrderFilters.country_code_type = country_code_type;

        if (country_code_type == 'Domestic') {
          this.searchOrderFilters.country_code_inclusive = true;
          this.searchOrderFilters.country_code = ['CA'];
        } else if (country_code_type == 'US') {
          this.searchOrderFilters.country_code_inclusive = true;
          this.searchOrderFilters.country_code = ['US'];
        } else if (country_code_type == 'International') {
          this.searchOrderFilters.country_code_inclusive = false;
          this.searchOrderFilters.country_code = ['US', 'CA'];
        } else {
          this.searchOrderFilters.country_code = [];
        }
      }
    }
  },
  watch: {
    'userStores.updating'(oldVal, newVal) {
      if (oldVal === false && newVal === true) {
        this.$emit('apply-search');
      }
    },
    order_tag_local(newVal) {
      this.searchOrderFilters.order_tags = newVal;
    },
    showAdvanceSearchLocal() {
  
    // this.clear()
      // this.applySearch()
    }
  },
  mounted() {
    this.showAdvanceSearchLocal = this.showAdvanceSearch
  },
  methods: {
    clear(){
      for (const key in this.searchOrderFilters) {
   this.searchOrderFilters[key] = null;
}
    },
    applySearch() {
      console.log('apply')
      // clearTimeout(this.timer);
      // var ms = 500;
      // var self = this;
      // this.options.page = 1;
      // this.timer = setTimeout(function () {
        this.$emit('apply-search');
      // }, ms);
    },
    updateStores(user_store_id = null) {
      this.$store.dispatch('userStores/updateStores', user_store_id);
    },
    getStoreName(item) {
      if(item.nickname == null) {
        return item.identifier;
      } else {
        return item.nickname;
      }
    },
  }
};
</script>
<style>
.very-dense .row.row--dense .v-input .v-input__control{
  height: 45px !important;
}

.very-dense .row.row--dense .v-input.error--text .v-input__control{
  height: auto !important;
}


</style>