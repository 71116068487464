<template>
  <StallionDialog
    v-model="show"
    :width="800"
    persistent
    title="Manage Columns"
  >
    <template #content>
      <v-row dense>
        <draggable
          v-if="localColumns.length > 0"
          v-bind="dragOptions"
          :list="localColumns"
          @start="dragging = true"
          @end="dragging = false"
        >
          <v-col
            v-for="(column, index) in localColumns"
            :key="index"
            cols="4"
          >
            <v-checkbox
              v-model="column.visible"
              dense
            >
              <template #label>
                <div>
                  <strong> {{ index+1 }}</strong> -  {{ column.text }}
                </div>
              </template>
            </v-checkbox>
          </v-col>
        </draggable>
      </v-row>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        :disabled="loading"
        @click.prevent="saveColumn()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import draggable from 'vuedraggable';

export default {
  components: {
    draggable
  },
  props: {
    table: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    },
    updateColumnHandler: {
      default: () => {},
      type: Function
    }
  },

  data() {
    return {
      loading: false,
      localColumns: _cloneDeep(this.columns),
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        class: 'row'
      }
    };
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },

  watch: {
    show: {
      handler() {
        if (this.show) {
          this.localColumns = _cloneDeep(this.columns)
        }
      },
    },
  },

  mounted() {
    //
  },

  methods: {
    saveColumn() {
      this.loading = true

      const columns = this.localColumns.map((column) => {
        return {
          value: column.value,
          visible: column.visible
        }
      })

      this.$http.put(`manage-columns/${this.table}`, {
        columns: columns
      })
      .then(() => {
        this.$notify({
          group: 'main',
          title: 'Successful',
          text: 'Column config has been saved.',
          duration: 5000,
          type: 'success'
        });

        this.show = false
        this.updateColumnHandler(columns)
      })
      .finally(() => {
        this.loading = false
      })

    }
  }
};
</script>
