<template>
  <v-list
    one-line
    subheader
    dense
  >
    <v-list-item
      v-for="(postage_rate, index) in postageRates"
      :key="'A' + index"
      dense
      :class="dense ? 'px-0' : ''"
      ripple
      @click="updateRateHandler(postage_rate)"
    >
      <v-list-item-action :class="dense ? 'mr-2 my-0' : ''">
        <v-radio-group
          v-model="shipment.postage_type_id"
          :dense="dense"
        >
          <v-radio :value="postage_rate.postage_type_id" />
        </v-radio-group>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <v-tooltip top>
            <template #activator="{ on }">
              <span
                v-on="on"
                v-html="$options.filters.postage(postage_rate)"
              /><span v-if="postage_rate.postage_provider_id == 120">
                ¹
              </span>
            </template>
            <span v-html="$options.filters.postage(postage_rate)" />
          </v-tooltip>
          <strong v-if="$vuetify.breakpoint.mdAndUp && !dense">
            ({{ postage_rate.package_type }})
          </strong>
        </v-list-item-title>
        <v-list-item-subtitle> 
          <!-- <div v-if="postage_rate.postage_provider_id == 120">
            USPS
          </div> -->
          <!-- <div v-if="postage_rate.postage_provider_id == 10">
            Stamps
          </div>
          <div v-if="postage_rate.meta?.isCubic">
            Cubic
          </div>
          <div v-if="postage_rate.meta?.zone">
            Zone {{ postage_rate.meta.zone }}
          </div> -->
          <span v-if="postage_rate.details">{{ postage_rate.details }}</span>
          <span v-if="postage_rate.delivery_days">
            {{ postage_rate.delivery_days }} business
            {{ postage_rate.delivery_days == 1 ? 'day' : 'days' }}
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        {{ postage_rate.base_rate | currency }}
      </v-list-item-action>
    </v-list-item>


    <div
      v-if="showTotal && rate"
      cols="12"
    >
      <h3>Summary</h3>
      <v-simple-table
        :dense="dense"
        class="total-table"
      >
        <template #default>
          <tbody>
            <tr v-if="rate.base_rate">
              <td>Rate</td>
              <td>
                {{ (rate.base_rate + rate.handling_fee) | currency }}
              </td>
            </tr>

            <tr
              v-for="(addon, index) in rate.add_ons"
              :key="index"
            >
              <td>{{ addon.name }}</td>
              <td>{{ addon.cost | currency }}</td>
            </tr>

            <tr v-if="rate.tax">
              <td>Tax</td>
              <td>{{ rate.tax | currency }}</td>
            </tr>

            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td v-if="rate.total">
                {{ rate.total | currency }}
              </td>
              <td v-else>
                -
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-list>
</template>

<script>
export default {
  props: {
    dense: {
      type: Boolean,
      default: false
    },
    showTotal: {
      type: Boolean,
      default: false
    },
    shipment: {
      required: true,
      type: Object
    },
    postageRates: {
      default: () => [],
      type: Array
    },
    updateRateHandler: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      rate: null
    };
  },
  watch: {
    'shipment.postage_type_id': {
      immediate: true,
      handler() {
        if (this.shipment.postage_type_id) {
          var filtered = this.postageRates.filter(
            (m) => m.postage_type_id === this.shipment.postage_type_id
          );

          if (filtered.length === 1) {
            this.rate = filtered[0];
          }
          else {
            this.rate = null;
          }
        }
        else {
          this.rate = null;
        }
      }
    },
    postageRates: {
      immediate: true,
      handler() {
        // get rate that matches the shipment postage type
        if (this.shipment.postage_type_id) {
          var filtered = this.postageRates.filter(
            (m) => m.postage_type_id === this.shipment.postage_type_id
          );

          if (filtered.length === 1) {
            this.rate = filtered[0];
          }
          // if no rate is found, set rate to null
          else {
            this.rate = null;
          }
        }
        else {
          this.rate = null;
        }

        // check if lowest postage ids is set for shipment
        // NOTE: assume that this.postageRates is already sorted by lowest in BE
        if (this.rate === null && this.shipment.lowest_postage_ids && Array.isArray(this.shipment.lowest_postage_ids)) {
          const postageIds = this.shipment.lowest_postage_ids.map((m) => parseInt(m, 10));
          for (let i = 0; i < this.postageRates.length; i++) {
            if (postageIds.includes(this.postageRates[i].postage_type_id)) {
              this.rate = this.postageRates[i];
              break;
            }
          }
        }

        this.updateRateHandler(this.rate);
      }
    }
  },
};
</script>
<style scoped>
.total-table td:nth-child(2) {
  text-align: right;
}

.total-table tr:last-child {
  background-color: #eee;
}
</style>
