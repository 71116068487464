<template>
  <div>
    <v-row>
      <v-col>
        <p>Enable direct printing to print directly to your connected printer, bypassing the browser print dialog.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <SetupQz v-if="setup" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch
          v-model="easyPrint"
          label="Enable Direct Printing"
          @change="changeEasyPrint"
        />
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <h4>Supported Printers</h4>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="selectedPrinter"
              :items="printers"
              label="Select Printer"
              :disabled="disableEasy"
              outlined
            />
          </v-col>
          <!-- <v-col cols="4">
            <v-select
              v-model="printerFormat"
              :items="printerFormats"
              label="Select Printer Format"
              :disabled="disableEasy"
              outlined
            />
          </v-col> -->
          <v-col cols="2">
            <v-btn
              outlined
              color="primary"
              large
              :disabled="disableEasy"
              @click="refreshPrinters"
            >
              <v-icon left>
                mdi-refresh
              </v-icon>
              Refresh
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          color="primary"
          :disabled="disableEasy"
          @click="submit"
        >
          Update
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SetupQz from "./SetupQz.vue";

export default {
  name: "PrinterSettings",
  components: { SetupQz },
  data() {
    return {
      printers: [],
      printerFormat: 'PDF',
      printerFormats: ["PDF", "ZPL"],
      selectedPrinter: '',
      easyPrint: false,
      disableEasy: true,
      setup: !this.easyPrint,
    }
  },

  watch: {
    easyPrint() {
      this.setup = !this.easyPrint
    }
  },

  async mounted() {
    await this.initialiseQz();
    if (this.isPrinterInitialised()) {
      await this.refreshPrinters();
      this.easyPrint = true;
      this.disableEasy = false;
      this.selectedPrinter = this.$store.state.qz.currentPrinter;
      this.printerFormat = this.$store.state.qz.printerFormat;
    }
  },
  methods: {
    async refreshPrinters() {
      if (this.isQzInitialised()) {
        this.printers = await this.getConnectedPrinters()
      } else {
        this.setup = true;
        await this.initialiseQz();
        if (this.isQzInitialised()) {
          this.setup = false;
        }
      }

      this.$notify({
        group: 'main',
        title: 'Printers Refreshed',
        text: 'Printer list has been successfully updated',
        duration: 5000,
        type: 'success'
      });
    },
    submit() {
      if (this.selectedPrinter) {
        localStorage.printer = this.selectedPrinter;
        localStorage.printerFormat = this.printerFormat;
        this.$store.state.qz.printerFormat = this.printerFormat;
        this.successMessage("Printer Saved");
      }
    },
    async changeEasyPrint() {
      await this.refreshPrinters();
      this.disableEasy = !this.easyPrint;
      localStorage.removeItem('printer');
      if (this.disableEasy) {
        this.successMessage("Direct Printing Disabled");
      }
    }
  }
}
</script>

<style scoped>

</style>