<template>
  <div>
    <h2 class="text-center">
      Estimated Costs (CAD)
    </h2>
    <hr>
    <ul class="fee_details">
      <li v-if="shipment.postage_type_id">
        <div v-html="$options.filters.postage(shipment)" />
      </li>
      <li>
        <strong>{{ shipment.package_type }}</strong>
      </li>
      <li>
        <strong>Weight:</strong>
        <span class="float-right">
          {{ shipment.weight }} {{ shipment.weight_unit }}
        </span>
      </li>
      <li v-if="shipment.length && shipment.width && shipment.height">
        <strong>Dimensions:</strong>
        <span class="float-right">
          {{ shipment.length }} x {{ shipment.width }} x {{ shipment.height }}
          {{ shipment.size_unit }}
        </span>
      </li>
    
      <li /><li v-if="rate.base_rate">
        <strong> Postage Rate:</strong>
        <span
          class="float-right"
        >
          {{ (rate.base_rate + rate.handling_fee) | currency }}
        </span>
      </li>
      <li
        v-for="(addon, index) in rate.add_ons"
        :key="index"
      >
        <strong>{{ addon.name }} </strong>
        <span class="float-right">{{ addon.cost | currency }}</span>
      </li>
      <li v-if="rate.tax">
        <strong>Tax:</strong>
        <span class="float-right">{{ rate.tax | currency }}</span>
      </li>

      <v-divider />
      <li>
        <strong>Total:</strong>
        <span class="float-right">{{ rate.total | currency }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    rate: {
      type: Object,
      default: () => ({})
    },
    shipment: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
