import axios from 'axios';

function initialState() {
  return {
    tags : []
  };
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    getTags({ commit }) {
      axios.get('/tags').then(response => {
        commit('updateTags', response.data)
      });
    }
  },
  mutations: {
    updateTags(state, tags) {
      state.tags = tags;
    }
  },
  getters: {
    reset(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    filtered_tags: state => {
      return state.tags.map(tag => {
        return {
          id : tag.id,
          name : tag.name
        }
      })
    },
    tags: state => state.tags,
    append_tags: state => (orders) => {
      if (orders) {
        let filtered = [];
        try {
          for (let order of orders) {
            order.tags = state.tags.filter(tag => {
              const obj = order.tag_ids;
              if (obj && obj.includes(tag.id)) {
                return tag;
              }
            });
            filtered.push(order);
          }
        } catch (error) {
          filtered = orders;
        }
        return filtered;
      }
      return orders;
    }
  }
}
