<template>
  <StallionDialog
    v-model="dialog"
    persistent
    :width="800"
    title="Tags"
  >
    <template #content>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              md="12"
              sm="12"
              style="flex: 0 100%"
            >
              <v-text-field
                v-if="editTag.id || adding_tag"
                v-model="editTag.name"
                :v-validate="getValidator"
                data-vv-name="new_tag"
                :error-messages="errs.collect('new_tag')"
                outlined
                dense
                label="Tag Name"
              />
            </v-col>
            <v-col
              md="12"
              cols="12"
              style="flex: 0 100%"
            >
              <v-color-picker
                v-if="editTag.id || adding_tag"
                v-model="editTag.color_code"
                class="ma-2"
                hide-canvas
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
            
              v-for="(tag, index) in items"
              :key="index + 1"
            >
              <v-card
                class="pl-md-1 tag_container"
                :elevation="editTag.name == tag.name ? 23 : null"
              >
                <div
                  :style="{
                    backgroundColor: tag.color_code,
                    borderRadius: '3px',
                    height: 'auto',
                    width: '20px',
                    marginRight: '15px'
                  }"
                />
                <span @click.self="tagClicked(tag)">{{ tag.name }}</span>
                <v-btn
                  :color="'#000000'"
                  icon
                  @click="deleteTag(tag)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>

    <template #actions>
      <v-btn
        v-if="!adding_tag"
        color="primary"
        outlined
        @click="addTagField"
      >
        <v-icon>mdi-plus</v-icon>Add Tag
      </v-btn>
      <v-btn
        color="primary"
        :disabled="!can_save"
        @click="saveTags()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
const defaultTag = {
  name: '',
  color_code: null
};

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      items: [],
      editing: false,
      new_tag: { ...defaultTag },
      dirty: [],
      tags: [],
      editTag: { ...defaultTag },
      deleted: [],
      adding_tag: false
    };
  },
  computed: {
    can_add: function () {
      return (
        this.editTag.name &&
        this.editTag.color_code &&
        !this.items.includes(this.editTag)
      );
    },
    can_save: function () {
      return (
        this.adding_tag || this.dirty.length > 0 || this.deleted.length > 0
      );
    },
    getValidator: function () {
      return this.can_add ? 'required' : '';
    }
  },
  watch: {
    dialog: function () {
      this.editTag = { ...defaultTag };
      this.updateTable();
    },
    editTag: function (newVal, oldVal) {
      this.editing = !oldVal.name == '';
    }
  },
  mounted() {
    this.updateTable();
  },
  methods: {
    addTagField() {
      this.editTag = { ...defaultTag };
      this.adding_tag = true;
    },
    async addTag() {
      return new Promise((resolve) => {
        this.$validator.validate().then((valid) => {
          if (valid) {
            this.editTag.color_code = this.editTag.color_code.hex;
            const names = this.tags.concat(this.items).map((t) => t.name);
            if (names.includes(this.editTag.name)) {
              this.errorMessage('Can not add duplicated tags');
            } else {
              this.tags.push(this.editTag);
              this.items.push(this.editTag);
            }
          }
          resolve();
        });
      });
    },
    updateTable() {
      this.totalItems = 0;
      this.items = [];

      this.$http
        .request(`/tags`, {
          method: 'get'
        })
        .then((response) => response.data)
        .then((response) => {
          this.items = response;
        });
    },
    async saveTags() {
      if (this.adding_tag) {
        await this.addTag();
      }
      console.log(this.tags);
      if (this.deleted.length > 0) {
        await this.trigger('delete', this.deleted);
      }
      if (this.tags.length > 0) {
        await this.trigger('post', this.tags);
      }
      if (this.dirty.length > 0) {
        await this.trigger('put', this.dirty);
      }
    },
    trigger(method, items) {
      this.$http
        .request('/tags', {
          method,
          data: {
            items
          }
        })
        .then((response) => response.data)
        .then((response) => {
          if (response.success) {
            this.$store.dispatch('tags/getTags');
            this.updateTable();
            this.$notify({
              group: 'main',
              title: 'Tags Updated',
              text: 'Tag have been updated successfully',
              duration: 5000,
              type: 'success'
            });

            this.editTag = { ...defaultTag };
            this.adding_tag = false;
            this.tags = [];
            this.dirty = [];
            this.deleted = [];
          } else {
            this.$notify({
              group: 'main',
              title: 'Error',
              text: 'An error occurred when updating the tags',
              duration: 5000,
              type: 'error'
            });
          }
        });
    },
    deleteTag(tag) {
      const index = this.items.indexOf(tag);
      if (index !== -1) {
        this.items.splice(index, 1);
        this.deleted.push(tag);
      }
    },
    tagClicked(tag) {
      if (tag == this.editTag) {
        this.editTag = { ...defaultTag };
      } else {
        this.editTag = tag;
        this.dirty.push(tag);
      }
    },
    close() {
      this.dialog = false;
      this.editTag = { ...defaultTag };
      this.adding_tag = false;
    },
    openDialog() {
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
.tag_container {
  background-color: #dcdcdc;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  padding-left: 0 !important;
  line-height: 35px;
  text-align: center;
}
</style>
