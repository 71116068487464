<template>
  <div>
    <v-row dense>
      <v-col
        cols="12"
        md="12"
      >
        <v-row>
          <v-col>
            <v-card
              outlined
              class="pa-5"
            >
              <h4>Stallion Express API Token</h4>
              <v-text-field
                v-if="$auth.user.api_token"
                v-model="$auth.user.api_token"
                outlined
                readonly
                dense
              />
            </v-card>
          </v-col>
          <v-col cols="auto" />
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          v-if="$auth.user.api_token"
          v-clipboard:copy="$auth.user.api_token"
          v-clipboard:success="onCopy"
          outlined
          color="primary"
        >
          {{ btnText }}
        </v-btn>
        <v-btn
          v-if="$auth.user.api_token"
          color="primary"
          :loading="processing"
          @click="regenerateApiToken"
        >
          Regenerate
        </v-btn>
        <v-btn
          v-else
          color="primary"
          :loading="processing"
          @click="generateApiToken"
        >
          Generate
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      dense
      class="mt-10"
    >
      <v-col cols="12">
        <user-settings-option
          v-for="(list, index) in newSettings.api"
          :key="index"
          :list="list"
        />
      </v-col>
      <v-col cols="12">
        <v-btn
          color="primary"
          :loading="processing"
          @click="submit"
        >
          Update
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      btnText: 'Copy',
      processing: false,
      newSettings: {
        api: _.cloneDeep(this.$auth.user.user_config_settings.api)
      },
    };
  },
  mounted() {},
  methods: {
    onCopy: function () {
      this.btnText = 'Copied!';
      this.$notify({
        group: 'main',
        title: 'Copied!',
        text: 'API token has been copied to the clipbaord',
        duration: 2000,
        type: 'info'
      });
    },
    submit() {
      this.$validator.validate().then((result) => {
        if (result) {
          this.processing = true;
          this.$http
            .post('/users/updateUserConfigSettings', this.newSettings)
            .then((res) => {
              // console.log(res);
              if (Object.prototype.hasOwnProperty.call(res.data, 'message')) {
                if (res.data.success) {
                  this.successMessage('Your account changes have been saved');
                } else {
                  this.errorMessage('Your account changes have not been saved');
                }
              }
            })
            .finally(() => {
              this.processing = false;
            });
        }
      });
    },
    generateApiToken() {
      this.processing = true;
      this.$http
        .post('/users/generateApiToken')
        .then((response) => {
          swal({
            title: 'Success',
            text: 'An API token has been generated',
            icon: 'success'
          });
          this.$auth.user.api_token = response.data.token;
        })
        .catch(() => {
          swal({
            title: 'Error!',
            text: 'An error occurred',
            icon: 'error'
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },

    regenerateApiToken() {
      var self = this;
      swal({
        title: 'Confirm',
        text: 'Are you sure you want to regenerate your API token? This will invalidate your current token.',
        icon: 'warning',
        buttons: ['Cancel', 'Confirm']
      }).then(function (isConfirm) {
        if (isConfirm) {
          self.processing = true;
          self.$http
            .post('/users/generateApiToken')
            .then((response) => {
              swal({
                title: 'Success',
                text: 'A new API token has been generated',
                icon: 'success'
              });
              self.$auth.user.api_token = response.data.token;
              self.btnText = 'Copy';
            })
            .catch(() => {
              swal({
                title: 'Error!',
                text: 'An error occurred',
                icon: 'error'
              });
            })
            .finally(() => {
              self.processing = false;
            });
        }
      });
    }
  }
};
</script>
