<template>
  <div style="padding: 15px;">
    <v-row
      align="center"
      justify="space-between"
    >
      <v-btn
        class="align-items-center justify-center"
        depressed
        style="cursor: pointer;"
        @click="updateSelected"
      >
        <span style="padding: 10px; display: inline-flex; align-items: center;">
          <span v-if="selectedOrdersCount > 0">
            <v-icon color="red">remove</v-icon>
            <span style="margin-left: 5px;">{{ selectedOrdersCount }} selected</span>
          </span>
          <span v-else>
            <v-icon color="primary">add</v-icon>
            <span style="margin-left: 5px;">{{ selectedOrdersCount }} selected</span>
          </span>
        </span>
      </v-btn>
      <v-col>
        <v-btn
          :loading="loading"
          :disabled="shouldDisableMergeButton"
          color="primary"
          depressed
          @click="mergeOrders"
        >
          <v-icon>merge</v-icon>Merge
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th />
                <th>Order Number</th>
                <th>Total</th>
                <th># of items</th>
                <th>Total Quantity</th>
                <th>Ready to ship</th>
                <th>Order Date</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(orders, address1) in totalOrders"
              >
                <tr :key="`${address1}-group`">
                  <td
                    colspan="7"
                    style="padding: 15px;"
                  >
                    <strong>{{ orders.length }} orders mergeable for {{ orders[0].name }}, {{ address1 }}, {{ orders[0].city }}, {{ orders[0].postal_code }}, {{ orders[0].province_code }}</strong>
                  </td>
                </tr>
                <tr
                  v-for="(order) in orders"
                  :key="order.id"
                >
                  <td>
                    <v-checkbox
                      v-model="checkBoxes[order.id]"
                      dense
                      color="primary"
                      @change="updateSelectedOrders(address1, order)"
                    />
                  </td>
                  <td>#{{ order.display_order_id }}</td>
                  <td>{{ order.currency == "CAD" ? 'C$' : '$' }} {{ order.value }}</td>
                  <td>{{ order.items_count }}</td>
                  <td>{{ order.total_quantity }}</td>
                  <td>
                    <v-icon color="success">
                      mdi-check
                    </v-icon>
                  </td>
                  <td>{{ order.order_at }}</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
    data() {
        return {
            totalOrders: [],
            selectedOrders: {},
            checkBoxes: {},
            loading: false
        };
    },

    computed: {
        shouldDisableMergeButton() {
            for (let address in this.selectedOrders) {
                if (this.selectedOrders[address].length >= 2) {
                return false;
                }
            }
            return true;
        },

        selectedOrdersCount() {
            return Object.keys(this.selectedOrders).reduce((acc, address) => {
                return acc + this.selectedOrders[address].length;
            }, 0);
        }
    },

    mounted() {
        this.fetchOrders();
    },

    methods: {
        fetchOrders() {
            this.loading = true;
            this.selectedOrders = {};
            this.checkBoxes = {};
            
            this.$http
                .get('/orders/mergeable')
                .then(response => {
                    this.totalOrders = response.data.data;
                    this.initializeSelectedOrders();
                    this.loading = false;
                })
                .catch(() => {
                    this.errorMessage('Could not fetch orders. Please try again.');
                });
        },
        
        mergeOrders() {
            //Filter selected orders and remove addresses with less than 2 selected orders
            const selectedOrders = Object.keys(this.selectedOrders).reduce((acc, address) => {
                if (this.selectedOrders[address].length >= 2) {
                    acc[address] = this.selectedOrders[address];
                }
                return acc;
            }, {});
            
            this.loading = true;
            this.$http
                .post('/orders/merge', { orders: selectedOrders })
                .then(() => {
                    this.successMessage('Orders merged successfully');
                    this.fetchOrders();
                })
                .catch(() => {
                    this.errorMessage('Could not merge orders. Please try again.');
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        updateSelectedOrders(address, order) {
            // Ensure selectedOrders[address] is initialized before pushing orderId
            if (!this.selectedOrders[address]) {
                this.$set(this.selectedOrders, address, []);
            }
            
            let index = this.selectedOrders[address].indexOf(order);
            if (index === -1) {
                this.selectedOrders[address].push(order);
            } else {
                this.selectedOrders[address].splice(index, 1);
            }
        },

        initializeSelectedOrders() {
            // Initialize selectedOrders based on totalOrders
            for (let address in this.totalOrders) {
                this.$set(this.selectedOrders, address, []);
            }
        },

        updateSelected() {
            if (this.selectedOrdersCount > 0) {
                // Clear selected orders and checkboxes
                this.initializeSelectedOrders();
                this.checkBoxes = {};
            }
            else {
                this.selectedOrders = {};

                // Loop through each address and select all orders
                Object.keys(this.totalOrders).forEach(address => {
                    // Copy all orders to selectedOrders[address]
                    this.$set(this.selectedOrders, address, this.totalOrders[address].slice());

                    // Check all checkboxes for each order
                    this.totalOrders[address].forEach(order => {
                        this.$set(this.checkBoxes, order.id, true);
                    });
                });
            }
        }
    }
};
</script>