<template>
  <v-container
    grid-list-md
    mb-12
  >
    <StallionDialog
      v-model="show_dispose_warning"
      :width="600"
      title="Auto Dispose"
    >
      <template #content>
        <v-layout column>
          <v-flex
            xs12
            text-center
            mt-6
          >
            <h4>
              This user has not signed the POA agreement. This return status
              has been set to auto disposal.
            </h4>
          </v-flex>
        </v-layout>
      </template>
    </StallionDialog>

    <v-layout wrap>
      <v-flex xs12>
        <h1>New Return</h1>
      </v-flex>
      <v-flex xs12>
        <v-card outlined>
          <v-flex xs12>
            <v-form @submit.prevent="saveReturn">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <h2>Identification</h2>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <user-search
                      v-model="user_id"
                      v-validate="''"
                      data-vv-name="user_id"
                      :error-messages="errs.collect('user_id')"
                      @change="selectUser('id')"
                    />
                    <!-- <v-text-field
                        v-model="user_id"
                        v-validate="'required|userIdExists'"
                        outlined
                        data-vv-name="user_id"
                        :error-messages="errs.collect('user_id')"
                        dense
                        prepend-inner-icon="mdi-pound-box"
                        hide-selected
                        label="User ID"
                        clearable
                        @change="selectUser('id')"
                      /> -->

                    <!-- <v-autocomplete
                        v-model="user_id"
                        v-validate="'required'"
                        outlined
                        data-vv-name="user_id"
                        :error-messages="errs.collect('user_id')"
                        dense
                        prepend-inner-icon="mdi-pound-box"
                        table
                        :items="users.map(u => u.id)"
                        hide-selected
                        label="User ID"
                        clearable
                        @change="selectUser('id')"
                      /> -->
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                  >
                    <!-- <v-autocomplete
                        v-model="user_name"
                        v-validate="'required'"
                        data-vv-name="user_name"
                        :error-messages="errs.collect('user_name')"
                        :items="users.map(u => u.name)"
                        outlined
                        dense
                        prepend-inner-icon="email"
                        :search-input.sync="searchEmail"
                        hide-selected
                        label="User Name"
                        clearable
                        @change="selectUser('name')"
                      />-->
                  </v-col>
                </v-row>
                <v-row v-if="show_dropdown">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-autocomplete
                      v-model="return_name"
                      v-validate="'required'"
                      data-vv-name="return_company"
                      :error-messages="errs.collect('return_company')"
                      :items="return_names"
                      outlined
                      dense
                      item-text="return_name"
                      label="Company"
                      clearable
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <h2>Package Details</h2>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      ref="tracking"
                      v-model="tracking_number"
                      v-validate="'required|min:6'"
                      outlined
                      dense
                      data-vv-name="tracking_number"
                      :error-messages="errs.collect('tracking_number')"
                      label="Tracking Number"
                      clearable
                    />
                  </v-col>
                </v-row>

                <v-layout wrap>
                  <v-flex
                    xs12
                    text-center
                  >
                    <v-btn
                      color="primary"
                      :disabled="loading"
                      outlined
                      @click="clearData"
                    >
                      Reset
                    </v-btn>
                    <v-btn
                      color="primary"
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                    >
                      Save
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-flex>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      /*users: {
        ids: [],
        names: [],
        types: [],
        id_selected: null,
        name_selected: null,
        type_selected: null
      },*/
      user_id: null,
      user_name: null,
      users: [],
      loading: false,
      tracking_number: '',
      scanned: false,
      show_dispose_warning: false,
      show_dropdown: false,
      return_names: [],
      return_name: null,
      auto_dispose_returns: false,
    };
  },
  watch: {},
  created() {
    const eventBus = this.$barcodeScanner.init(this.onBarcodeScanned, {
      eventBus: true
    });

    if (eventBus) {
      // eventBus.$on('start', () => {
      //   this.loading = true;
      // });
      eventBus.$on('finish', () => {
        this.loading = false;
      });
    }

    this.$barcodeScanner.setSensitivity(100);
  },
  async mounted() {
    this.users = await this.getUsers();
  },
  methods: {
    getUsers() {
      return new Promise((resolve) => {
        this.$http
          .get('/admin/returns/users')
          .then((response) => response.data)
          .then((response) => {
            resolve(response);
          });
      });
    },
    selectUser() {
      const user = this.users.find((u) => u.id == this.user_id);

      console.log(user);
      if (typeof user !== 'undefined') {
        this.show_dropdown = user.type_id === 3;
        this.user_name = user.name;
        this.auto_dispose_returns = user.auto_dispose_returns;
        this.$refs.tracking.focus();
        this.getCompanyReturnNames();
      } else {
        this.show_dropdown = false;
        this.user_name = null;
      }
    },
    destroyed() {
      // Remove listener when component is destroyed
      this.$barcodeScanner.destroy();
    },
    getCompanyReturnNames() {
      this.$http
        .get(`/admin/users/${this.user_id}/return-names`)
        .then((response) => {
          this.return_names = response.data;
        });
    },
    getOutboundTracking(barcode) {
      let tracking = barcode;
      if (barcode.length === 34) {
        // FedEx
        tracking = barcode.slice(22);
      } else if (barcode.length >= 22) {
        // USPS
        barcode = barcode.slice(-22);
        if (barcode.charAt(0) === '9') {
          tracking = barcode;
        }
      }
      return tracking;
    },
    onBarcodeScanned(barcode) {
      this.loading = true;
      this.tracking_number = this.getOutboundTracking(barcode);
      this.$notify({
        group: 'main',
        title: 'Item Scanned',
        text: this.tracking_number,
        duration: 3000
      });
      // this.saveReturn();
    },
    async saveReturn() {
      console.log('saveReturn');
      if(this.auto_dispose_returns){
        this.errorMessage('Auto Dispose Returns is enabled for this user.', 'Dispose Return');
        return;
      }
      const valid = await this.$validator.validate();

      console.log(valid);
      if (valid && !this.loading) {
        const params = {
          user_id: this.user_id,
          tracking_number: this.tracking_number,
          return_name: this.return_name
        };

        this.loading = true;
        this.$http
          .post('/admin/returns/create', params)
          .then((response) =>  {
            if(response.data?.success === false){
              this.errorMessage(response.data.message, 'Error');
              return;
            }
            this.printFileUrl(response.data);
            Event.fire('update-return-labels');
            this.clearData();
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    clearData() {
      console.log('clearData');
      this.$validator.reset();

      this.id_selected = null;
      this.name_selected = null;
      this.return_name = null;
      this.user_id = null;

      this.tracking_number = null;
    }
  }
};
</script>
