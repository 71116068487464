<template>
  <v-flex
    v-if="batch"
    xs12
  >
    <v-row>
      <v-col
        cols="col-fixed"
        style="border: 1px solid #e0e0e0; border-radius: 5px; padding: 10px; margin: 10px"
        q
      >
        <v-progress-linear
          v-model="progressPercentage"
          height="30"
          class="mb-3"
          :indeterminate="progressPercentage === 0"
        >
          <template #default="{ value }">
            <strong>{{ Math.floor(value) }}%</strong>
          </template>
        </v-progress-linear>

        <div class="d-flex flex-row justify-space-between">
          <div class="shipment-title">
            Batch #
          </div>
          {{ batch.id }}
        </div>

        <div class="d-flex flex-row justify-space-between">
          <div class="shipment-title">
            Status
          </div>
          <div v-if="batch.processed_count > 0">
            Processed
            <span>
              {{ batch.processed_count }} of
              {{ batch.count }}
            </span>
          </div>
          <div v-else-if="batch.processing == 0 && batch.validated > 0">
            Validated
            <span>
              {{ batch.validated }} of
              {{ batch.count }}
            </span>
          </div>
          <div v-else>
            <span>
              Submitting batch for processing
            </span>
          </div>
        </div>

        <div class="d-flex flex-row justify-space-between">
          <div class="shipment-title">
            Processed At
          </div>
          <span v-if="batch.process_start_at">
            {{ batch.process_start_at | datetime_pretty }}
          </span>
        </div>

        <div class="d-flex flex-row justify-space-between">
          <div class="shipment-title">
            Validated
          </div>
          {{ batch.validated }}
        </div>

        <div class="d-flex flex-row justify-space-between">
          <div class="shipment-title">
            Errors
          </div>
          {{ batch.errors }}
        </div>

        <div class="d-flex flex-row justify-space-between">
          <div class="shipment-title">
            Complete
          </div>
          {{ batch.complete }}
        </div>

        <div class="d-flex flex-row justify-space-between">
          <div class="shipment-title">
            Total Items
          </div>
          {{ batch.total_count }}
        </div>
      </v-col>

      <v-col
        v-if="isBatchCompleted"
        cols="5"
        style="border: 1px solid #e0e0e0; border-radius: 5px; padding: 10px; margin: 10px"
      >
        <v-col class="text-center pa-0">
          <h3>
            <v-icon
              color="black"
              left
            >
              mdi-printer
            </v-icon>Print
          </h3>
        </v-col>
        <v-col>
          <v-btn
            block
            color="primary"
            :disabled="printDisabled"
            :loading="loadingPrint"
            @click="buildPrinter('label_only')"
          >
            Labels
          </v-btn>
        </v-col>

        <v-col>
          <v-btn
            block
            color="primary"
            :disabled="printDisabled"
            :loading="loadingPackingSlip"
            @click="buildPrinter('packing_slip')"
          >
            Packing Slips
          </v-btn>
        </v-col>

        <v-col>
          <v-btn
            block
            color="primary"
            :disabled="printDisabled"
            :loading="loadingPackingSlipWithLabel"
            @click="buildPrinter('label_with_packing_slip')"
          >
            Label + Packing Slip
          </v-btn>
        </v-col>
      </v-col>
    </v-row>

    <print-shipments
      ref="printShipmentLabels"
      :shipments="shipmentsForPrint"
      :print-type="printType"
    />
  </v-flex>
</template>

<script>

import PrintShipments from '../PrintShipments.vue';
export default {
  components: {
    PrintShipments,
  },
  props: {
    progressPercentage: {
      type: Number,
      default: 0
    },
    batch: {
      type: Object,
      default: null
    },
    shipments: {
      type: Array,
      default: () => []
    },
    labelType: {
      default: 'shipments',
      type: String
    },
    isBatchValidated: {
      type: Boolean,
      default: false
    },
    isBatchCompleted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: [],
      printType: '',
      printDisabled: false,
      shipmentsForPrint: [],
      loadingPrint: false,
      loadingPackingSlip: false,
      loadingPackingSlipWithLabel: false
    };
  },
  methods: {
    printCallBack() {
      this.printDisabled = false
    },
    async buildPrinter(type) {

      try {
        this.printDisabled = true

        if (type === 'packing_slip') {
          this.loadingPackingSlip = true
        } else if (type === 'label_with_packing_slip') {
          this.loadingPackingSlipWithLabel = true
        } else {
          this.loadingPrint = true
        }
        await this.getShipments()

        if (this.shipments.length == 0) {
          this.errorMessage('Nothing to print')
          return
        }

        this.printType = type

        this.$nextTick(() => {
          switch (type) {
            case 'packing_slip':
              this.$refs.printPackingSlips.getPackingSlips('print', this.printCallBack)
              break
            case 'label_with_packing_slip':
            case 'label_only':
              this.$refs.printShipmentLabels.getLabel('print', this.printCallBack)
              break
            default:
          }
        })
      } catch (e) {
        console.log(e)
        this.printCallBack()
      } finally {
        this.printDisabled = false
        this.loadingPrint = false
        this.loadingPackingSlip = false
        this.loadingPackingSlipWithLabel = false
      }
    },
    async getShipments() {
      if (!this.batch.id) {
        return;
      }

      try {
        const response = await this.$http.get(`/batches/shipments`, {
          params: {
            ids: [this.batch.id]
          }
        })

        this.shipmentsForPrint = response.data;
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>
