import CONSTANTS from '@/constants';
import TABLE_COLUMNS from '@/constants/table-columns';
import {ParseData} from "../helpers/scales";
import _find from 'lodash/find'
import _differenceBy from 'lodash/differenceBy'
import Vapor from 'laravel-vapor';
import Vue from "vue";
const url = import.meta.env.BASE_URL
if(url !== "/"){
    const newUrl = url.replace("/build/",'');
    Vapor.withBaseAssetUrl(newUrl)
}
else{
    Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)
}

window.Vapor = Vapor;
export default {
    data() {
        return {
            rawPrinters: [],
            rawScales: [],
            selectedPrinterDevice: '',
            selectedScaleDevice: {},
        }
        },
    methods: {
        async getStatementSummary(id) {
            this.summaryLoading = true;

            // const params = this.getParams();

            this.$http
                .request(`/statements/${id}/summary`, {
                method: 'get',
                })
                .then((response) => {
                    const data = response.data;
                    this.downloadFileUrl(data.url, data.filename);
                })
                .finally(() => {
                this.summaryLoading = false;
                });
        },
        async getStatementDetails(id) {
            this.summaryLoading = true;

            // const params = this.getParams();

            this.$http
                .request(`/statements/${id}/details`, {
                method: 'get',
                })
                .then((response) => {
                    const data = response.data;
                    this.downloadFileUrl(data.url, data.filename, 'text/csv');
                })
                .finally(() => {
                this.summaryLoading = false;
                });
        },
        toggleBeacon: function () {
        Beacon('toggle');
        },
        creditCheck: function (amount) {
            if (!this.$auth.authenticated) {
                console.log('Not logged in');
                return false;
            } else if (
                this.$auth.user.billing_type_id == 1 &&
                Number(amount) > Number(this.$auth.user.credits)
            ) {
                if (this.$auth.user.default_card) {
                console.log('Need to load credits');
                var required_amount = Number(amount) - this.$auth.user.credits;

                const payment_data = { card: this.$auth.user.default_card, amount: required_amount.toFixed(2), disabled: false }
                Event.fire('add-credits', payment_data);
                } else {
                console.log('Insufficient Funds');
                swal(
                    'Insufficient Funds',
                    'You do not have enough credits, you need ' +
                    this.$options.filters.currency(amount) +
                    ' and have ' +
                    this.$options.filters.currency(this.$auth.user.credits) +
                    '.'
                )
                }
                return false;
            } else {
                return true;
            }
        },


        errorMessage: function (
            message = 'An unknown error occurred',
            title = 'Error'
        ) {
            swal({
                title: title,
                text: message,
                icon: 'error'
            });
        },
        successMessage: function (message, title = 'Success') {
            swal({
                title: title,
                text: message,
                icon: 'success'
            });
        },
        successNotify: function (message, title = 'Success') {
            Vue.notify({
                group: 'main',
                title: title,
                text: message,
                duration: 3000,
                type: 'success'
            });
        },
        errorNotify: function (message, title = 'Error') {
            Vue.notify({
                group: 'main',
                title: title,
                text: message,
                duration: 3000,
                type: 'error'
            });
        },

        hasRole: function (role) {
            if (!this.$auth.authenticated) return false;

            if (role === null || role === '') {
                return false;
            }

            if (this.$auth.user.enabled_roles.includes(role)) {
                return true;
            } else {
                return false;
            }
        },
        can: function (permission) {
            if (!this.$auth.authenticated) {
                return false;
            }

            if (permission === null || permission === '') {
                return false;
            }

            if (this.$auth.user.enabled_permissions.includes(permission)) {
                return true;
            } else {
                return false;
            }
        },
        is_printable(shipment) {
            if (
                this.can('access.admin') &&
                (shipment.needs_postage || shipment.is_fba) &&
                [2, 4, 5, 6, 8, 10, 11, 12, 13].includes(shipment.status_id)
            ) {
                return true;
            } else if (
                (shipment.needs_postage || shipment.is_fba) &&
                [2, 4, 5, 6, 8, 10, 13].includes(shipment.status_id)
            ) {
                return true;
            }else if (
                (shipment.return_label)
            ) {
                return true;
            } else {
                return false;
            }
        },
        printable(shipments) {
            if (
                shipments.length != 0 &&
                this.can('access.admin') &&
                shipments.every(
                    (r) =>
                        (r.needs_postage || r.is_fba) &&
                        [2, 4, 5, 6, 8, 10, 11, 12, 13].includes(r.status_id)
                )
            ) {
                return true;
            } else if (
                shipments.length != 0 &&
                shipments.every(
                    (r) =>
                        (r.needs_postage || r.is_fba) &&
                        [2, 4, 5, 6, 8, 10, 11, 13].includes(r.status_id)
                )
            ) {
                return true;
            } else {
                return false;
            }
        },
        isStoreOrders(shipments) {
            if (
                shipments.length != 0 &&
                shipments.every(
                    (r) =>
                        r.order_id != null
                )
            ) {
                return true;
            } else {
                return false;
            }
        },
        canCreateLabelsFromShipments(shipments) {
            if (shipments.length > 0 && shipments.every(
                    (r) =>
                        // r.status_id == 3 && [...new Set(shipments.map((item) => item.batch_id))].length == 1
                        r.status_id == 3
                )
            ) {
                return true;
            } else {
                return false;
            }
        },
        canCreateLabelsFromOrders(orders) {
            // only if order is not yet linked to a shipment
            // or orders shipment status is incomplete and on the same batch
            if (orders.length > 0 && orders.every(
                    (r) =>
                        // r.shipment_hash == null ||
                        // (r.shipment?.status_id == 3 && [...new Set(orders.map((item) => item.shipment?.batch_id || null))].length == 1)
                        r.shipment_hash == null ||
                        r.shipment?.status_id == 3
                )
            ) {
                return true;
            } else {
                return false;
            }
        },
        archiveable(shipments) {
            if (
                shipments.length != 0 &&
                shipments.every(
                    (r) =>
                        r.status_id == 1 ||
                        r.status_id == 3 ||
                        r.status_id == 10 ||
                        r.status_id >= 12
                )
            ) {
                return true;
            } else {
                return false;
            }
        },
        editShipment: function (shipment, step) {
            this.$store.state.singleFlow.single_dialog = true;

            Event.fire('edit-shipment', {
                shipment: shipment,
                step: step
            });
        },

        /**
         * initialize a given inputId as google places autocomplete and triggers the callback function when specified
         * @param {String} inputId
         * @param {Function} callback
         */
        initializeGooglePlacesAutoComplete(inputId, callback = null) {
            var returnData = {
                street_number: '',
                route: '',
                locality: '',
                administrative_area_level_1: '',
                country: '',
                postal_code: ''
            };
            var element = document.getElementById(inputId);
            if (!element) {
                console.error(
                    'Cannot Initialize Google Places. Input Element not found'
                );
                return;
            }
            var autocomplete = new google.maps.places.Autocomplete(element, {
                types: ['geocode']
            });
            element.placeholder = '';
            element.addEventListener('keydown', (e) => {
                if (e.keyCode === 13) {
                    e.preventDefault();
                }
            });
            element.addEventListener('blur', (e) => {
                returnData.route = e.target.value;
                returnData.street_number = '';
                //this.$emit('placechanged', returnData);
            });
            autocomplete.addListener('place_changed', () => {
                returnData = {
                    street_number: '',
                    route: '',
                    locality: '',
                    administrative_area_level_1: '',
                    country: '',
                    postal_code: ''
                };

                let place = autocomplete.getPlace();
                if (!place.geometry) {
                    return;
                }
                let addressComponents = {
                    street_number: 'short_name',
                    route: 'long_name',
                    locality: 'long_name',
                    administrative_area_level_1: 'short_name',
                    country: 'short_name',
                    postal_code: 'short_name'
                };
                if (place.address_components !== undefined) {
                    for (let i = 0; i < place.address_components.length; i++) {
                        let addressType = place.address_components[i].types[0];
                        if (addressComponents[addressType]) {
                            let val =
                                place.address_components[i][addressComponents[addressType]];
                            returnData[addressType] = val;
                        }
                    }
                    returnData['latitude'] = place.geometry.location.lat();
                    returnData['longitude'] = place.geometry.location.lng();
                }
                if (callback && typeof callback === 'function') {
                    callback(returnData, place);
                }
            });
        },
        /*
         * Check if user setting is enabled by default
         * @param {string} key
         * returns true|false
         */
        isUserSettingEnabled(key) {
            if (!this.$auth.user) {
                return false
            }

            var settings =
                Object.values(this.$auth.user?.user_config_settings) || [];

            var value = false;
            settings.forEach(function (block) {
                block.forEach(function (setting) {
                    if (setting.key == key && setting.value == 1) {
                        console.log('yeppp');
                        value = true;
                    }
                });
            });

            return value;
        },
        humanize(str) {
            try {
                var i,
                    frags = str.split('_');
                for (i = 0; i < frags.length; i++) {
                    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
                }
                return frags.join(' ');
            } catch (err) {
                return str;
            }
        },
        canAccessRoute(path) {
            return this.can(this.getRoutePermissions(path));
        },
        getRoutePermissions(to) {
            const routes = this.$router.options.routes;
            const index = routes.findIndex((x) => x.name === to);

            if (index >= 0) {
                return routes[index].meta.permission;
            } else {
                return null;
            }
        },

        /**
         * Validate all components (parent to child)
         */
        async $validateAll() {
            // validate parent
            const validations = [this.$validator.validate()];

            // validate children
            this.$children.forEach((child) => {
                validations.push(child.$validator.validateAll());
            });

            const result = [];
            await Promise.all(validations)
                .then((validate) => {
                    validate.forEach((res) => {
                        result.push(res);
                    });
                })
                .catch(() => {
                    result.push(false);
                });

            return !result.includes(false);
        },

        $hotkeyType() {
            return window.navigator.userAgent.match('Mac') ? 'cmd' : 'ctrl';
        },
        
        $commissionRateTypes() {
            return [
                {
                  key: 'Fixed',
                  label: 'Fixed $'
                },
                {
                  key: 'Percent',
                  label: 'Percent %'
                }
              ]
        },

        $commissionGroup() {
            return [
                {
                  key: 'Employee',
                  label: 'Sales'
                },
                {
                  key: 'Client',
                  label: 'Referral'
                }
              ]
        },

        $currencyAsFlatArray(pickBy = 'key') {
            return CONSTANTS.CURRENCIES.map((currency) => currency[pickBy]);
        },

        $sizeUnits() {
            return ['in', 'cm'];
        },

        $weightUnits() {
            return ['lbs', 'kg', 'oz', 'g'];
        },

        // qz functions
        isPrinterInitialised() {
            let printer = localStorage.printer;
            let printerFormat = localStorage.printerFormat;
            this.$store.dispatch('qz/putCurrentPrinterState',[printer,printerFormat]);
            return typeof printer !== 'undefined';
        },

        isQzInitialised() {
            let status = this.$qz.websocket.isActive();
            if(status){
                this.$store.dispatch('qz/makeQzActive');
            } else {
                this.$store.dispatch('qz/makeQzInActive');
            }
            return status;
        },

        async signInQz() {
            let certificate = '';

            if(this.$store.state.qz.certificate === ''){
                await this.$http
                    .get(
                        '/qz/cert'
                    )
                    .then(response => {
                        certificate = response.data;
                        this.$store.dispatch('qz/putCertificateState', certificate);
                    }).finally(() => {
                        this.loading = false;
                    });
            } else {
                certificate = this.$store.state.qz.certificate;
            }

            await this.$qz.security.setCertificatePromise(function (resolve) {
                resolve(certificate);
            });

            this.$qz.security.setSignatureAlgorithm("SHA512");

            this.$qz.security.setSignaturePromise((toSign) => {
                return async (resolve, reject) => {
                    try {
                        let keys = await this.getSignedKeys(toSign);
                        resolve(keys);
                    } catch (e) {
                        reject(e)
                    }
                };
            });

            this.$qz.websocket.setErrorCallbacks(function (error) {
                console.log(error);
            });
        },

        async getSignedKeys(data) {
            await this.$http
                .post(
                    '/qz/cert',
                    {
                        data: data
                    },
                )
                .then(response => {
                    this.signedCertificate = response.data;
                });
            return this.signedCertificate
        },

        async initialiseQz() {
            if (!this.isQzInitialised()) {
                await this.signInQz().then(()=>{
                    this.$qz.websocket.connect().then(async () => {
                        let version = await this.$qz.api.getVersion();
                        console.log('QZ Version:', version);
                        // if(version !== '2.2.2'){
                        //     Vue.notify({
                        //         group: 'main',
                        //         title: 'QZ Tray',
                        //         text: 'Please install QZ Tray v2.2.2 and start it. Some function might not work properly.',
                        //         duration: 3000,
                        //         type: 'error'
                        //     });
                        // }
                        await this.$store.dispatch('qz/makeQzActive');
                        if(this.isScaleInitialised()) { await this.getConnectedScales(); }
                        if(this.isPrinterInitialised()) { await this.getConnectedPrinters(); }
                        console.log('QZ Initialised');
                        await this.$store.dispatch('qz/putNotified', false);
                    }).catch((err) => {
                        if(err.message === 'Unable to establish connection with QZ') {
                            if (!this.isQzInitialised() && !this.$store.state.qz.notified) {
                                this.$store.dispatch('qz/putNotified', true);
                                window.location.assign("qz:launch");
                                let openApp = setInterval(() => {
                                    this.initialiseQz();
                                    clearInterval(openApp);
                                },5000);
                            } else {
                                this.initialiseQz();
                            }
                            console.log(err);
                        } else {
                            console.log(err);
                        }
                    });
                });
            }

            this.$qz.websocket.setClosedCallbacks(()=>{
                this.$store.dispatch('qz/makeQzInActive');
                console.log('i am disconnected');
                if(this.isScaleInitialised() || this.isPrinterInitialised()) {
                    let openApp = setInterval(() => {
                        this.initialiseQz();
                        clearInterval(openApp);
                    }, 5000);
                }
            })
        },

        getPagination(response) {
            delete response.data
            return response
        },

        async getManageColumnSettings(table) {
            try {
                const response = await this.$http.get(`manage-columns/${table}`)

                return response.data || []
            } catch (ex) {
                return []
            }
        },

        getSortedColumnHeaders(headers, columnSettings) {
            let sortedHeaders = headers
            // if settings is empty then use default headers
            if (columnSettings.length == 0) {
                return sortedHeaders
            }

            // order based on saved settings
            sortedHeaders = []
            columnSettings.forEach((column) => {
                const header = _find(headers, { value: column.value })
                if (header) {
                    sortedHeaders.push(header)
                }
            })

            // add columns not stored in user settings
            _differenceBy(headers, sortedHeaders, 'value').forEach((header) => {
                sortedHeaders.push(header)
            })

            return sortedHeaders
        },

        buildManageColumnHeaders(headers, columnSettings, tableKey) {
            // if settings is empty then use default headers
            if (columnSettings.length == 0) {
                columnSettings = TABLE_COLUMNS.DEFAULT_TABLE_COLUMNS[tableKey] || []
            }

            return this.getSortedColumnHeaders(headers, columnSettings).map((header) => {
                header.visible = Boolean(_find(columnSettings, { value: header.value })?.visible || false)

                return header
            })
        },

        filteredColumnHeaders(headers, columnSettings, tableKey) {
            // if settings is empty then use default headers
            if (columnSettings.length == 0) {
                columnSettings = TABLE_COLUMNS.DEFAULT_TABLE_COLUMNS[tableKey] || []
            }

            return this.getSortedColumnHeaders(headers, columnSettings).filter((header) => {
              return Boolean(_find(columnSettings, { value: header.value })?.visible || false)
            })
        },

        async isPrinterPhysicallyConnected() {
            if (this.isPrinterInitialised()) {
                if (this.isQzInitialised()) {
                    let printers = await this.getConnectedPrinters()
                    return printers.includes(this.$store.state.qz.currentPrinter);
                } else {
                    this.initialiseQz();
                    if (this.isQzInitialised()) {
                        let printers = await this.getConnectedPrinters()
                        return printers.includes(this.$store.state.qz.currentPrinter);
                    } else {
                        return false;
                    }
                }
            } else {
                console.log("Set printer in settings first")
                return false;
            }
        },

        async getConnectedPrinters() {
            if (!this.isQzInitialised()) {
                await this.initialiseQz();
            }
            try {
                this.rawPrinters = await this.$qz.printers.find();
                return this.rawPrinters;
            } catch (Err) {
                if(Err.message === "Cannot read properties of null"){
                    console.log("Cannot read properties of null", Err)
                } else if (Err.message === 'Unable to establish connection with QZ'){
                    console.log("Error finding printers", Err.message)
                } else {
                    console.log("Error finding printers", Err)
                }
            }
            console.log("Unknown Error")
        },

        async printUrlFromQz(url) {
            var instance = axios.create();
            const response = await instance.get(url, {
                responseType: 'arraybuffer',
            });
            let unitArrayData = new Uint8Array(response.data);
            let printData = this.arrayBufferToBase64(unitArrayData);
            let data = [];
            if (await this.isPrinterPhysicallyConnected()) {
                let foundPrinter = await this.$qz.printers.find(this.$store.state.qz.currentPrinter);
                let config = this.$qz.configs.create(foundPrinter);
                if(this.$store.state.qz.printerFormat === 'PDF') {
                    data = [{
                        type: 'pixel',
                        format: 'pdf',
                        flavor: 'base64',
                        data: printData
                    }];
                } else if (this.$store.state.qz.printerFormat === 'ZPL') {
                    printData = this.arrayBufferToStream(unitArrayData);
                    console.log("ZPL format not supported yet", printData)
                    data = [{
                        type: 'raw',
                        format: 'file',
                        data: url,
                    }];
                }
                try {
                    await this.$qz.print(config, data);
                } catch (e) {
                    console.log(e);
                }
            } else {
                console.log("Please connect printer first");
            }
        },

        async printFromQz(printData) {
            if (await this.isPrinterPhysicallyConnected()) {
                let foundPrinter = await this.$qz.printers.find(this.$store.state.qz.currentPrinter);
                let config = this.$qz.configs.create(foundPrinter);
                let data = [{
                    type: 'pixel',
                    format: 'pdf',
                    flavor: 'base64',
                    data: printData
                }];
                try {
                    await this.$qz.print(config, data);
                } catch (e) {
                    console.log(e);
                }
            } else {
                console.log("Please connect printer first");
            }
        },

        arrayBufferToBase64(buffer) {
            var binary = '';
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        },

        arrayBufferToStream(buffer) {
            var binary = '';
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return binary;
        },

        isScaleInitialised() {
            let scale = localStorage.scale;
            if (scale !== null && typeof scale !== 'undefined') {
                if (typeof scale === 'string' || scale instanceof String) {
                    scale = JSON.parse(scale);
                }
                scale.responseSize = 8;
                this.$store.dispatch('qz/putEnabledAutoWeightFetch',scale.enabledAutoWeightFetch);
                delete scale.enabledAutoWeightFetch;
                this.$store.dispatch('qz/putCurrentScale',scale);
            }

            return typeof scale !== 'undefined'
        },

        async getConnectedScales() {
            if (!this.isQzInitialised()) {
                await this.initialiseQz();
            }
            try {
                let hidDevice = await this.$qz.hid.listDevices();
                if(hidDevice.length === 0 || hidDevice === undefined){
                    return [];
                }
                this.rawScales = this.getScalesOnly(hidDevice);
                return this.rawScales;
            } catch (Err) {
                if(this.$store.state.qz.notified === false){
                    this.$store.dispatch('qz/putNotified', true);
                    Vue.notify({
                        group: 'main',
                        title: 'Error connecting to QZ Tray',
                        text: 'Please install QZ Tray and run it.',
                        duration: 3000,
                        type: 'error'
                    });
                }
                console.log("Please install QZ Tray and run it");
            }
        },

        getScalesOnly(scales) {
            let validScales = [];
            const supportedScaleVendors = [
                '6096', // SANFORD
                '0922', // Dymo
                '1446', // WeighMax
                '0eb8', // Mettler Toledo
                '6096', // Pelouze
                '2474', // Stamps.com
                '0b67', // Fairbanks
            ];
            scales.forEach((singleDevice, index) => {
                let vendorId = singleDevice.vendorId.toString().substring(singleDevice.vendorId.toString().length - 4);
                if (supportedScaleVendors.includes(vendorId)) {
                    if (singleDevice.product === undefined) {
                        singleDevice.product = "Unknown Scale " + "#" + index + "(vendorId:" + singleDevice.vendorId + " productId:" + singleDevice.productId + ")";
                    }
                    validScales.push(singleDevice);
                }
            });
            return validScales;
        },

        async getWeightFromScale() {
            // Byte 0 == Report ID?
            // Byte 1 == Scale Status (1 == Fault, 2 == Stable @ 0, 3 == In Motion, 4 == Stable, 5 == Under 0, 6 == Over Weight, 7 == Requires Calibration, 8 == Requires Re-Zeroing)
            // Byte 2 == Weight Unit
            // Byte 3 == Data Scaling (decimal placement)
            // Byte 4 == Weight LSB
            // Byte 5 == Weight MSB
            if(this.$store.state.qz.currentScaleStream){
                await this.closeScaleStream();
            }
            if (await this.isScalePhysicallyConnected()) {
                try {
                    let isClaimed = await this.$qz.hid.isClaimed(this.$store.state.qz.currentScale);
                    if (!isClaimed) {
                        await this.$qz.hid.claimDevice(this.$store.state.qz.currentScale);
                    }
                } catch (e) {
                    console.log(e);
                    Vue.notify({
                        group: 'main',
                        title: 'Device Error',
                        text: 'Cannot claim Scale',
                        duration: 3000,
                        type: 'error'
                    });
                }

                let data = {};
                let parsedData = {
                    status: "UNSTABLE"
                };
                let count = 0;
                do {
                    count ++;
                    data = await this.$qz.hid.readData(this.$store.state.qz.currentScale);
                    parsedData = ParseData(data);
                    if(parsedData.status === "ZERO"){
                        Vue.notify({
                            group: 'main',
                            title: 'Error getting scale weight',
                            text: 'Please put weight on Scale',
                            duration: 3000,
                            type: 'error'
                        });
                        break;
                    }
                    if(parsedData.status === "UNDER_ZERO"){
                        Vue.notify({
                            group: 'main',
                            title: 'Invalid Scale Weight',
                            text: 'Please reset Scale',
                            duration: 3000,
                            type: 'error'
                        });
                        break;
                    }
                    if(count === 20) break;

                } while (!(parsedData.status === "STABLE"));

                await this.$qz.hid.releaseDevice(this.$store.state.qz.currentScale);
                if(parsedData.status === "STABLE"){
                    parsedData.weight = Number(Math.round(parsedData.weight + 'e' + 2) + 'e-' + 2);
                    return {status : true, data: parsedData};
                } else {
                    return {status : false, data: ""}
                }
            } else {
                console.log(" Please connect Usb Scale first");
                Vue.notify({
                    group: 'main',
                    title: 'Error getting scale weight',
                    text: 'Please connect USB Scale first!',
                    duration: 3000,
                    type: 'error'
                });
                return {status : false, data: ""}
            }
        },

        async startScaleStream(){
            if (await this.isScalePhysicallyConnected()) {
                try {
                    let isClaimed = await this.$qz.hid.isClaimed(this.$store.state.qz.currentScale);
                    if (!isClaimed) {
                        this.$qz.hid.claimDevice(this.$store.state.qz.currentScale).then( async ()=>{
                            await this.$store.dispatch('qz/putCurrentScaleStream', true);
                            let currentScale = this.$store.state.qz.currentScale;
                            currentScale.interval = 100; //weight read interval for streaming in ms
                            console.log('streaming started');
                            await this.$qz.hid.openStream(currentScale);
                        });
                    } else {
                        await this.$qz.hid.releaseDevice(this.$store.state.qz.currentScale);
                        this.$qz.hid.claimDevice(this.$store.state.qz.currentScale).then( async ()=>{
                                await this.$store.dispatch('qz/putCurrentScaleStream', true);
                                let currentScale = this.$store.state.qz.currentScale;
                                currentScale.interval = 100; //weight read interval for streaming in ms
                                console.log('streaming started');
                                await this.$qz.hid.openStream(currentScale);
                            });
                    }
                } catch (e) {
                    console.log(e);
                    Vue.notify({
                        group: 'main',
                        title: 'Device Error',
                        text: 'Cannot claim Scale',
                        duration: 3000,
                        type: 'error'
                    });
                }
            } else {
                console.log('scale not available for auto weight fetch');
                Vue.notify({
                    group: 'main',
                    title: 'Error getting scale weight',
                    text: 'Selected scale not available. Please connect USB Scale first!',
                    duration: 3000,
                    type: 'error'
                });
            }
        },

        async closeScaleStream(){
            await this.$store.dispatch('qz/putCurrentScaleStream', false);
            await this.$qz.hid.closeStream(this.$store.state.qz.currentScale);
            await this.$qz.hid.releaseDevice(this.$store.state.qz.currentScale);
        },

        async parseStreamData(streamData){
            let parsedData = ParseData(streamData);
            if(parsedData.status === "ZERO"){
                console.log("Please put weight on Scale");
                return {status : true, data: parsedData};
            }
            if(parsedData.status === "UNDER_ZERO"){
                console.log("Please reset Scale");
            }
            if(parsedData.status === "STABLE" && parsedData.weight !== 0){
                parsedData.weight = Number(Math.round(parsedData.weight + 'e' + 2) + 'e-' + 2);
                return {status : true, data: parsedData};
            } else {
                return {status : false, data: {}};
            }
        },

        async compareScales() {
            let connected = false;
            let scales = [];
            scales = await this.getConnectedScales();
            if(scales === undefined){
                return false;
            }
            scales.forEach((singleDevice) => {
                let singleVendorId = singleDevice.vendorId.toString().substring(singleDevice.vendorId.toString().length - 4);
                let selectedVendorId = this.$store.state.qz.currentScale.vendorId.toString().substring(this.$store.state.qz.currentScale.vendorId.toString().length - 4);
                if (singleVendorId === selectedVendorId) {
                    connected = true;
                }
            });
            return connected;
        },

        async isScalePhysicallyConnected() {
            if (this.isScaleInitialised()) {
                if (this.isQzInitialised()) {
                    return await this.compareScales();
                } else {
                    await this.initialiseQz();
                    if (this.isQzInitialised()) {
                        return await this.compareScales();
                    } else {
                        return false;
                    }
                }
            } else {
                console.log(("Set Scale in settings first"))
                return false;
            }
        },
        getCombinedValue(items){
            if(!Array.isArray(items) || items.length === 0) {
                return 0;
            }

            const firstCurrency = items[0].currency;
            const sameCurrency = items.every(item => item.currency === firstCurrency);

            if(!sameCurrency) {
                return 'All currencies must be the same.'
            }

            const total = items.reduce((total, item) => {
                const value = parseFloat(item.value) * parseInt(item.quantity) || 0;
                return total + value;
            }, 0);

            return this.$options.filters.currency(total)
        },

        getCombinedContents(items) {
            // Check if the input is an array and if it is empty
            if (!Array.isArray(items) || items.length === 0) {
                return '';
            }

            // Map each item to the desired format: "{quantity} x {description} @ {value}"
            const descriptions = items.map(item => {
                // Use conditional checks and fallback values to handle missing or falsy properties
                const quantity = item.quantity || 1; // If quantity is missing, default to 1
                const description = item.description || 'No description'; // If description is missing, use a default value
                const value = item.value || 0; // If value is missing, default to 0

                return `${quantity} x ${description} @ $${value}`;
            });

            // Join the formatted descriptions into a comma-separated string using join()
            const combinedContents = descriptions.join(', ');

            return combinedContents;
        },
        async downloadFileUrl(url, fileName, type = 'application/pdf'){
            try {
                var instance = axios.create();
                const response = await instance.get(url, {
                    responseType: 'arraybuffer',
                });
                    const blob = new Blob([response.data], { type: type });
                    // Fallback method for downloading in a web browser
                    const downloadUrl = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
            } catch (error) {
                console.error('Download failed:', error);
            }
        },
        async printFileUrl(url, type = 'application/pdf'){
            try {
                var instance = axios.create();
                const response = await instance.get(url, {
                    responseType: 'arraybuffer',
                });
                        const blob = new Blob([response.data], {
                            type: type
                        });
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);

                        printJS(link.href);
            } catch (error) {
                console.error('Print failed:', error);
            }
        },
        asset: window.Vapor.asset,
        updateShipDetails(shipment, authUser) {
            let ship = { ...shipment };
          
            // Set return address details
            ship.return_name = ship.name;
            ship.return_address1 = ship.address1;
            ship.return_address2 = ship.address2;
            ship.return_city = ship.city;
            ship.return_province_code = ship.province_code;
            ship.return_postal_code = ship.postal_code;
            ship.return_country_code = ship.country_code;
            ship.return_phone = ship.phone;
            ship.return_email = ship.email;
          
            // Update address based on the country code
            if (ship.return_country_code === 'CA') {
              ship.name = authUser.business_name || authUser.name;
              ship.address1 = authUser.address1;
              ship.address2 = authUser.address2;
              ship.city = authUser.city;
              ship.province_code = authUser.province_code;
              ship.postal_code = authUser.postal_code;
              ship.country_code = authUser.country_code;
              ship.phone = authUser.phone;
              ship.email = authUser.email;
            } else {
              ship.name = shipment.return_name;
              ship.address1 = shipment.return_address1;
              ship.address2 = shipment.return_address2;
              ship.city = shipment.return_city;
              ship.province_code = shipment.return_province_code;
              ship.postal_code = shipment.return_postal_code;
              ship.country_code = shipment.return_country_code;
              ship.phone = shipment.return_phone;
              ship.email = shipment.return_email;
            }
          
            return ship;
        },
        playSuccessSound() {
            const play_audio = this.isUserSettingEnabled('enable_scanner_audio')

            if (play_audio) {
            var audio = new Audio('/sounds/beep-02.wav');
                  audio.play();
            }
        },
        playErrorSound() {
            const play_audio = this.isUserSettingEnabled('enable_scanner_audio')

            if (play_audio) {
            var audio = new Audio('/sounds/beep-05.wav');
                  audio.play();
            }
        },
        getUserSetting(key, defaultValue = null) {
            const setting = this.$auth.user.settings.find(
                (s) => s.key == key
              );

            return setting ? setting.value : defaultValue;
        }
    },
    
};
