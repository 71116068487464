<template>
  <v-layout wrap>
    <v-flex xs12>
      <v-card>
        <div>
          <v-card-title>
            <span class="text-h3">Return Labels</span>
            <v-spacer />
            <!-- <v-text-field
                @input="submitSearch()"
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field> -->
          </v-card-title>
        </div>
        <v-data-table
          class="table-striped elevation-3"
          :headers="headers"
          :items="labels"
          hide-default-footer
          item-key="id"
          :loading="loading"
        >
          <template #item.action="{ item }">
            <v-layout justify-center>
              <v-btn
                color="primary"
                :loading="print_loading"
                :disabled="print_loading"
                small
                @click.prevent="printLabel(item.sequence_no)"
              >
                Print
              </v-btn>
              &nbsp;&nbsp;&nbsp;
              <v-btn
                color="primary"
                download
                :loading="download_loading"
                :disabled="download_loading"
                small
                @click.prevent="downloadLabel(item.sequence_no)"
              >
                Download
              </v-btn>
            </v-layout>
          </template>

          <template #item.sequence_no="{ item }">
            {{ item.sequence_no }}
          </template>
          <template #item.created_at="{ item }">
            {{ item.created_at }}
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    shipmentReturnId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      drawer: null,
      labels: [],
      loading: false,
      totalShipments: 0,
      headers: [
        {
          text: 'Sequence No.',
          value: 'sequence_no',
          align: 'center'
        },
        {
          text: 'Created',
          value: 'created_at',
          align: 'center'
        },
        {
          text: '',
          value: 'action',
          sortable: false,
          align: 'center'
        }
      ],
      print_loading: false,
      download_loading: false
    };
  },
  computed: {
    ...mapState(['main'])
  },
  mounted() {
    this.getShipments().then(data => {
      this.labels = data.items;
    });
  },
  methods: {
    printLabel: function(id) {
      this.print_loading = true;

      this.$http
        .get(`/admin/return-labels/getLabel/${id}`)
        .then((response => {
          this.printFileUrl(response.data);
          // printJS(`/return-labels/printlabel/${id}`);
        }))
        .catch(() => {
        })
        .finally(() => {
          this.print_loading = false;
        });
    },
    downloadLabel: function(id) {
      this.dowload_loading = true;

      this.$http
        .get(`/admin/return-labels/downloadlabel/${id}`)
        .then(response => {
          this.downloadFileUrl(response.data, id+'.pdf');

          this.dowload_loading = false;
        })
        .finally(() => {
          this.dowload_loading = false;
        });
    },
    getShipments() {
      console.log('get shipments');
      this.loading = true;
      return new Promise(resolve => {
        let items = [];

        this.$http
          .get(`/admin/return-labels/${this.shipmentReturnId}`)
          .then(response => {
            console.log(response);
            items = response.data;

            this.loading = false;
            resolve({
              items
            });
          });
      });
    }
  }
};
</script>
