<template>
  <v-container
    px-1
    py-0
  >
    <v-row v-if="loading">
      <div class="loading-overlay">
        <div class="loading-content">
          <atom-spinner
            style="margin: auto"
            :animation-duration="1000"
            :size="100"
            :color="'#f9ac40'"
          />
          <div style="text-align: center; color: #9d9da6">
            Loading
          </div>
        </div>
      </div>
    </v-row>

    <v-row
      v-else-if="shipment !== null"
      dense
    >
      <v-col cols="12">
        <div class="d-flex flex-row justify-space-between">
          <div>
            <h4>Shipment ID</h4>
          </div>

          <v-tooltip top>
            <template #activator="{ on }">
              <a
                v-clipboard:copy="shipment.ship_code"
                v-clipboard:success="oncopy"
              
                href="javascript:void(0);"
                v-on="on"
              ><strong>{{ shipment.ship_code }}</strong></a>
            </template>
            <span>Click to copy</span>
          </v-tooltip>
        </div>
      </v-col>

      <v-col cols="12">
        <v-card
          outlined
        >
          <v-tabs
            v-model="tabSelected"
            grow
            hide-slider
            :height="35"
          >
            <v-tab href="#tab-view-shipment">
              <v-icon
                
                left
              >
                mdi-cube
              </v-icon>
              Details
            </v-tab>

            <v-tab
              href="#tab-edit-shipment"
              :disabled="!canEdit"
            >
              <v-icon
                
                left
              >
                mdi-pencil
              </v-icon>
              Edit
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-tabs-items v-model="tabSelected">
          <v-tab-item
            :transition="false"
            :value="TAB_VIEW_SHIPMENT"
          >
            <view-shipment-details-tab
              ref="refViewShipmentDetailsTab"
              :shipment="shipment"
              :tax-identifiers="$store.getters['main/tax_identifiers']"
              :update-shipment-handler="updateShipment"
              @getShipment="getShipment"
            />
          </v-tab-item>

          <v-tab-item
            :transition="false"
            :value="TAB_EDIT_SHIPMENT"
          >
            <edit-shipment-details-tab
              v-if="canEdit"
              ref="refEditShipmentDetailsTab"
              :can-edit="canEdit"
              :shipment="shipment"
              :tax-identifiers="$store.getters['main/tax_identifiers']"
              :update-shipment-handler="updateShipment"
              :refresh-data-table-on-error="true"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row
      v-else
      wrap
      pa-3
    >
      <v-flex xs12>
        <bulk-apply-preset
          v-if="shipments.length > 1 && canCreateLabelsFromShipments(shipments)"
          :shipments="shipments"
          :selected="shipments"
          @update-shipments="updateShipments"
        />
        <div
          v-else
          class="text-center"
          style="padding: 100px 0 100px 0"
        >
          <h2>{{ shipments.length }} shipments selected</h2>
        </div>
      </v-flex>
    </v-row>

    <print
      v-if="autoPrintEnabled"
      ref="print"
      v-model="showPrint"
      :shipments="[shipment]"
    />
    <quick-ship-complete-dialog
      v-else
      v-model="showQuickShipCompleteDialog"
      :shipment.sync="shipment"
    />
  </v-container>
</template>

<script>
import { AtomSpinner } from 'epic-spinners';
import BulkApplyPreset from './../../../main/shipments/BulkApplyPreset.vue'
import ViewShipmentDetailsTab from './tabs/view-shipment-details.vue';
import EditShipmentDetailsTab from './tabs/edit-shipment-details.vue';
import QuickShipCompleteDialog from '../quick-ship/QuickShipCompleteDialog.vue';

const TAB_VIEW_SHIPMENT = 'tab-view-shipment';
const TAB_EDIT_SHIPMENT = 'tab-edit-shipment';

export default {
  components: {
    AtomSpinner,
    BulkApplyPreset,
    ViewShipmentDetailsTab,
    EditShipmentDetailsTab,
    QuickShipCompleteDialog
  },
  props: {
    shipments: {
      type: Array,
      default: () => []
    },
    shipmentId: {
      type: [Number, String],
      default: null
    }
  },

  data() {
    return {
      shipment: null,
      loading: false,
      tabSelected: TAB_VIEW_SHIPMENT,
      showPrint: false,
      showQuickShipCompleteDialog: false
    };
  },
  computed: {
    TAB_VIEW_SHIPMENT() {
      return TAB_VIEW_SHIPMENT;
    },
    TAB_EDIT_SHIPMENT() {
      return TAB_EDIT_SHIPMENT;
    },
    canEdit() {
      return this.shipment && this.shipment.status_id == 3 && this.can('CLIENT.SHIPPING')
    },

    isPrintable() {
      return this.shipment && this.is_printable(this.shipment)
    },
    autoPrintEnabled() {
      return this.isPrintable && this.isUserSettingEnabled('print_after_single_flow')
    }
  },
  watch: {
    shipments: {
      immediate: true,
      handler(value) {
        if (value.length == 1) {
          this.getShipment(value[0].id);
        } else {
          this.shipment = null;
        }
      }
    },
    shipmentId: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getShipment(value);
        }
      }
    }
  },

  mounted() {
  },

  methods: {
    setEditTab() {
      this.tabSelected = this.TAB_EDIT_SHIPMENT
    },
    setViewTab() {
      this.tabSelected = this.TAB_VIEW_SHIPMENT
    },
    openQuickShipCompleteDialog() {
      if (this.autoPrintEnabled) {
        this.$nextTick(function() {
          this.showPrint = true
          this.$refs.print.getLabel('print', () => {
            this.showPrint = false
          });
        })
      } else if (this.isPrintable) {
        this.showQuickShipCompleteDialog = true;
      }
    },

    getShipment(id) {
      this.cancelSearch();
      this.cancelSource = axios.CancelToken.source();
      this.shipment = null;
      this.loading = true;
      this.$http
        .get(`/shipments/${id}`, {
          cancelToken: this.cancelSource.token
        })
        .then((response) => response.data)
        .then((response) => {
          this.shipment = response;

          // this.tabSelected = this.canEdit ? 'tab-edit-shipment' : 'tab-view-shipment'
          this.tabSelected = 'tab-view-shipment'
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelSearch() {
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, stop active search');
      }
    },
    updateShipment(shipment) {
      this.shipment = { ...shipment };
      // event registered @shipment-table.vue
      Event.fire('update-single', this.shipment);

      if (!this.canEdit) {
        this.tabSelected = 'tab-view-shipment';
      }

      this.openQuickShipCompleteDialog()
    },
    updateShipments(shipments) {
      // event registered @shipment-table.vue
      Event.fire('update-multiple', shipments);
    },
    oncopy() {
      this.$notify({
        group: 'main',
        title: 'Copied!',
        text: 'Shipment ID has been copied to the clipboard',
        duration: 2000,
        type: 'info'
      });
    },
  }
};
</script>

<style scoped>
.settings-tab {
  font-size: 13px;
  font-weight: 700;
}

.settings-card {
  padding-top: 20px !important;
  border-radius: 0 !important;
}

h4 {
  margin-bottom: 0px !important;
}

.v-application p {
  margin-bottom: 0px !important;
}

.v-application ul {
  padding-left: 0px !important;
}

input,
select {
  width: 100%;
}
</style>
