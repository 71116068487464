<template>
  <StallionDialog
    v-model="show"
    :width="500"
    title="Print Pickup"
  >
    <template #content>
      <v-layout column>
        <v-flex
          xs12
          text-center
          mt-4
          mb-12
        >
          <h4>
            Print your order form and bring it with you to pickup your
            shipments.
          </h4>
        </v-flex>
      </v-layout>
    </template>

    <template #actions>
      <v-btn
        color="primary"
        text
        download
        :loading="dowload_loading"
        :disabled="dowload_loading"
        @click.prevent="download()"
      >
        <v-icon
          small
          dark
        >
          mdi-download
        </v-icon>Download
      </v-btn>

      <v-btn
        color="primary"
        :loading="print_loading"
        :disabled="print_loading"
        @click="print()"
      >
        <v-icon
          small
          dark
        >
          mdi-printer
        </v-icon>Print
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
export default {
  props: {
    pickups: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      print_loading: false,
      dowload_loading: false
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    labelsCount: function() {
      return this.batches.length;
    }
  },
  mounted() {},

  methods: {
    close() {
      this.$emit('update:show', false);
    },
    print: function() {
      this.print_loading = true;

      var ids = [];
      this.pickups.forEach(function(order) {
        ids.push(order.id);
      });

      this.$http({
        method: 'post',
        url: '/pickups/label',
        data: { ids: ids }
      })
        .then(response => {
          this.printFileUrl(response.data);
          this.print_loading = false;
        })
        .catch(() => {
          this.errorMessage('File not found');
        })
        .finally(() => {
          this.print_loading = false;
        });
    },
    download: function() {
      this.dowload_loading = true;
      var ids = [];
      this.pickups.forEach(function(order) {
        ids.push(order.id);
      });

      this.$http({
        method: 'post',
        url: '/pickups/download',
        data: { ids: ids }
      })
        .then(response => {
          this.downloadFileUrl(response.data,this.id + '.pdf');
        })
        .catch(() => {
          this.errorMessage('File not found');
        })
        .finally(() => {
          this.dowload_loading = false;
        });
    }
  }
};
</script>
