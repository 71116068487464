<template>
  <StallionDialog
    v-model="show"
    :width="500"
    persistent
    title="Add To Batch"
  >
    <template #content>
      <v-form>
        <v-container fluid>
          <v-row dense>
            <v-col cols="12">
              <div class="dialog-info">
                {{ shipments.length }}
                {{ shipments.length | pluralize(' shipment') }} ready to add
                to batch
              </div>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="batch_id"
                outlined
                :items="batches"
                label="Batches"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="name"
                label="Name (Optional)"
                clearable
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>

    <template #actions>
      <v-btn
        :loading="loading"
        :disabled="loading"
        color="primary"
        class="px-7"
        @click="moveToBatch()"
      >
        Save
      </v-btn>
    </template>
  </StallionDialog>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    shipments: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      start_validation: true,
      batch_id: null,
      name: null,
      loading: false
    };
  },
  computed: {
    ...mapState(['batches']),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    batches() {
      return this.$store.getters['batches/openBatchesOptions'];
    }
  },
  watch: {
    batch_id: function(val) {
      this.name = null;
      if (val) {
        const index = this.batches.findIndex(x => x.value === val);
        if (index) {
          this.name = this.batches[index].name;
        }
      }
    }
  },
  mounted() {
    this.$store.dispatch('batches/getOpenBatches');
  },
  methods: {
    moveToBatch() {
      this.loading = true;

      const ids = this.shipments.map(a => a.id);
      this.$http
        .post(`/shipments/batch-add`, {
          ids,
          batch_id: this.batch_id,
          name: this.name
        })
        .then(response => response.data)
        .then(() => {
          // const payload = {
          //   batch: response.batch,
          //   shipments: response.shipments
          // };
          this.show = false;

          this.$emit('update');
          this.$store.dispatch('batches/getOpenBatches');
        })
        .catch(() => {
          this.errorMessage('Could move the selected shipment to the batch');
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
