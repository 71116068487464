<template>
  <div class="mt-4">
    <!-- v-bind.sync="imp.selection.filters" -->
    <order-search
      :search-order-filters.sync="imp.selection.filters"
      :loading.sync="loading"
      show-advance-search
      @apply-search="getOrders()"
    />
    <v-row dense>
      <v-col>
        <v-form>
          <!-- <v-container class="grey lighten-5"> -->
          <v-row dense>
            <v-col>
              <v-btn
                color="primary"
                @click="selectAll"
              >
                Select All
              </v-btn>

              <span class="ml-3">{{ selected.length }} selected</span>
            </v-col>
            <v-col />
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <v-flex
      xs12
      mb-12
      mt-4
    >
      <div>
        <v-data-table
          v-model="selected"
          :loading="loading"
          :options.sync="options"
          dusk="validation_table"
          class="table-striped"
          :headers="headers_validation"
          :items="shipments"
          show-select
          item-key="order_id"
          :footer-props="{
            'items-per-page-options': [
              50, 100, 200, 500
            ]
          }"
          @item-selected="bulkSelectSelect"
          @current-items="currentSelect = $event"
        >
          <template #item.order_id="{ item }">
            <div v-html="$options.filters.orderLink(item)" />
          </template>

          <template #item.postage_type_id="{ item }">
            <div v-html="$options.filters.postage(item)" />
          </template>

          <template #item.package_type_id="{ item }">
            <div
              v-html="$options.filters.package_type(item.package_type_id)"
            />
          </template>

          <template #item.weight="{ item }">
            {{ item.weight }} {{ item.weight_unit }}
          </template>

          <template #item.dims="{ item }">
            <span v-if="item.length && item.width && item.height">
            
              {{ item.length }}x{{ item.width }}x{{ item.height }} {{ item.size_unit }}
            </span>
          </template>
        </v-data-table>
      </div>
    </v-flex>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import OrderSearch from '../../orders/OrderSearch.vue';

export default {
  components: {
    OrderSearch
  },
  data() {
    return {
      showSearch: true,
      loading: false,
      shiftKeyOn: false,
      options: {
        sortBy: ['row'],
        sortDesc: [false],
        itemsPerPage: 100
      },
      headers_validation: [
        {
          text: '',
          value: 'action',
          sortable: true
        },
        {
          text: 'Row',
          value: 'row',
          sortable: true
        },
        {
          text: 'Order ID',
          value: 'order_id',
          sortable: true
        },
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Country Code',
          align: 'left',
          sortable: true,
          value: 'country_code'
        },
       
        // {
        //   text: 'Alt Order ID',
        //   value: 'display_order_id',
        // },
        {
          text: 'Package Type',
          value: 'package_type_id'
        },
        // {
        //   text: 'Need Postage',
        //   value: 'needs_postage'
        // },
        {
          text: 'Postage Type',
          value: 'postage_type_id'
        },
        // {
        //   text: 'Tags',
        //   value: 'tags'
        // },
        {
          text: 'Shipping Cost',
          value: 'shipping_cost'
        },
        {
          text: 'Value',
          value: 'value'
        },
        {
          text: 'Weight',
          value: 'weight'
        },
        // {
        //   text: 'Unit',
        //   value: 'weight_unit'
        // },
        {
          text: 'Dims',
          value: 'dims'
        },
        // {
        //   text: 'W',
        //   value: 'width'
        // },
        // {
        //   text: 'H',
        //   value: 'height'
        // },
        // {
        //   text: 'Unit',
        //   value: 'size_unit'
        // },
        {
          text: 'Description',
          value: 'package_contents'
        },
        {
          text: 'Order At',
          value: 'order_at'
        },
        // {
        //   text: '',
        //   value: 'errors'
        // }
        {
          text: 'Sub Batch',
          value: 'sub_batch'
        }
      ],
      providers: []
    };
  },
  computed: {
    ...mapState(['imp', 'tags']),
    ...mapGetters({ append_tags: 'tags/append_tags'}),
    filters: {
      get() {
        return this.imp.selection.filters;
      }
    },
    shipments: function() {
     return this.append_tags(this.imp.selection.shipments);
    },
    selected: {
      get() {
        return this.imp.selection.selected;
      },
      set(value) {
        this.$store.commit('imp/updateSelectionSelected', value);
      }
    }
  },
  watch: {
    'options.itemsPerPage' : function(newVal, oldVal) {
      if (oldVal && newVal) {
        const preference = {
          table_rows: {
            import: newVal
          }
        }
        this.$store.dispatch('main/setPreferences', preference);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('keyup', this.keyUpHandler);
  },
  created() {
    const self = this;
    self.keyDownHandler = function ({ key }) {
      if (key == 'Shift') self.shiftKeyOn = true;
    };
    self.keyUpHandler = function ({ key }) {
      if (key == 'Shift') self.shiftKeyOn = false;
    };
    window.addEventListener('keydown', this.keyDownHandler);
    window.addEventListener('keyup', this.keyUpHandler);
  },
  mounted(){
    const table_rows = this.$store.getters['main/table_rows'];
    this.options.itemsPerPage = table_rows?.import ?? 200;
  },
  methods: {
    getOrders() {
      this.$store.commit('imp/filtersSet', this.filters);
      this.loading = true;
      this.imp.selection.shipments = []
      return new Promise(() => {
        this.$http
          .request(`/orders/filter`, {
            method: 'post',
            data: this.filters
          })
          .then(response => response.data)
          .then(response => {
            response.data = this.append_tags(response.data);
            return response;
          })
          .then(response => {
            this.imp.selection.shipments = response;
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    selectAll() {
      if (this.selected.length != this.imp.selection.shipments.length) {
        this.$notify({
          group: 'main',
          title: 'All Selected',
          text: 'All shipments have been selected',
          duration: 5000,
          type: 'success'
        });
        this.selected = this.imp.selection.shipments;
      } else {
        this.selected = [];
      }
    },
    bulkSelectSelect({ item: b, value }) {
      const { currentSelect, shiftKeyOn } = this;

      if (this.selected && this.selected.length == 1 && value == true && shiftKeyOn) {
        let [a] = this.selected;

        if (this.lastSelect !== null) {
          a = this.lastSelect;
        }

        let start = currentSelect.findIndex((item) => item == a);
        let end = currentSelect.findIndex((item) => item == b);

        if (start - end > 0) {
          let temp = start;
          start = end;
          end = temp;
        }
        for (let i = start; i <= end; i++) {
          this.selected.push(currentSelect[i]);
        }
      }

      this.lastSelect = b;
    }
  }
};
</script>
